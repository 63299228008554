import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavoritosComponent } from './favoritos.component';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from '../layout/main-layout/main-layout.component';


const routes: Routes = [
  { 
    path: 'favoritos', component: MainLayoutComponent, 
    pathMatch: 'full',
    children: [{ 
      path: '', component: FavoritosComponent
    }] 
  }
];

@NgModule({
  declarations: [FavoritosComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ],
  exports:[FavoritosComponent]
})
export class FavoritosModule { }
