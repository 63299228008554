import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MainService } from './main.service'

@Injectable({
  providedIn: 'root'
})
export class RodadaNegocioService extends MainService {
  _url = `${super.getUrl()}rodada-negocio/`

  rodada: any

  constructor(private http: HttpClient) {
    super()
  }

  disponivel() {
    const url = `${this._url}disponivel`;
    return this.http.get<any>(url, this.getOptions())
  }

  disponivelPessoa(id_pessoa: string) {
    const url = `${this._url}disponivelPessoa/${id_pessoa}`;
    return this.http.get<any>(url, this.getOptions())
  }

  list() {
    const url = `${this._url}list`;
    return this.http.get<any>(url, this.getOptions())
  }

  salvar(rodadaNegocio: any) {
    const url = `${this._url}create`;
    return this.http.post<any>(url, rodadaNegocio, this.getOptions())
  }

  atualizar(rodadaNegocio: any) {
    const url = `${this._url}update`;
    return this.http.post<any>(url, rodadaNegocio, this.getOptions())
  }

  listCount(filtro: any) {
    const url = `${this._url}listCount`;
    return this.http.post<any>(url, filtro, this.getOptions())
  }

  mudarStatus(entity: any) {
    const url = `${this._url}mudarStatus`;
    return this.http.post<any>(url, entity, this.getOptions())
  }

  obterRodada(id: string) {
    const url = `${this._url}obter/${id}`;
    return this.http.get<any>(url, this.getOptions())
  }

}
