import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RelatorioComponent } from './relatorio/relatorio.component';
import { RouterModule, Routes } from '@angular/router';
import { LayoutInternoComponent } from '../layout/layout-interno/layout-interno.component';
import { FormsModule } from "@angular/forms";
import { NgxMaskModule } from 'ngx-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  {
    path: 'relatorio',
    component: LayoutInternoComponent, children: [{ path: '', component: RelatorioComponent }]
  }
]

@NgModule({
  declarations: [RelatorioComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot(),
    FormsModule,
    NgbModule
  ]
})
export class RelatorioModule { }
