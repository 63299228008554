<app-slide-topo></app-slide-topo>
<!-- <section class="banner-gg">
    <div>
        <img class="w-100" src="assets/images/outra.jpg" alt="">
    </div>
</section> -->
<app-destaque></app-destaque>
<!-- <section class="banner-gg">
    <div>
        <img class="w-100" src="assets/images/outra.jpg" alt="">
    </div>
</section> -->
<app-destaque-servicos tipo="Produto">
</app-destaque-servicos>
<app-destaque-servicos tipo="Servico">
</app-destaque-servicos>
<!-- <app-mapa></app-mapa> -->
<!-- <app-enquete></app-enquete> -->

<app-banner-rodada></app-banner-rodada>

<!-- <section class="noticias-eventos lista-cards">
  <div class="center">
    <app-noticia class="area-noticias">
    </app-noticia>
    <app-evento class="area-eventos">
    </app-evento>
  </div>
</section> -->