import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends MainService {

  _url = `${super.getUrl()}categoria/`;
  destaquesGerenciador: any = [];

  constructor(
    private http: HttpClient
  ) {
    super();
    this.destaquesGerenciador = "";
  }

  Menu(idioma: string = 'BR'): Observable<any[]> {
    const url = `${this._url}menu`;
    return this.http.post<any[]>(url, { idioma: idioma })
  }

  BlocoPesquisa(filter: any): Observable<any[]> {
    const url = `${this._url}blocoPesquisa`;
    return this.http.post<any[]>(url, filter)
  }

  Destaques(idioma: string = 'BR'): Observable<any[]> {
    const url = `${this._url}destaques`;
    return this.http.post<any[]>(url, { idioma: idioma })
  }

  RetornarDestaqueGerenciador(observacao: string) {
    if (this.destaquesGerenciador && this.destaquesGerenciador.length > 0) {
      return this.destaquesGerenciador.find((x: { Observacao: string; }) => x.Observacao == observacao);
    }
    return "";
  }
}
