import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AvisoDeslogadoComponent } from 'src/app/aviso-deslogado/aviso-deslogado.component';
import { AuthService } from 'src/app/service/auth.service';
import { AvaliarService } from 'src/app/service/avaliar.service';
import { isTemplateExpression } from 'typescript';
import { AvaliacaoComponent } from '../avaliacao/avaliacao.component';

@Component({
  selector: 'app-comentario',
  templateUrl: './comentario.component.html',
  styleUrls: ['./comentario.component.css']
})
export class ComentarioComponent implements OnInit {

  pessoa: any;
  list: any;
  avaliarModal: any;

  tamanhosFonts = ['f-10', 'f-12', 'f-14', 'f-16', 'f-18', 'f-20', 'f-24']

  indicetamanhoAtual = 3;
  
  acessibilidade = this.tamanhosFonts[this.indicetamanhoAtual]

  constructor(
    public activeModal: NgbActiveModal,
    private avaliarService: AvaliarService,
    private authService: AuthService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {

    this.getAvaliacao()
    this.getAvaliacaoUsuario()
  }

  getAvaliacao() {
    this.avaliarService.list(this.pessoa.id).subscribe(next => {
      this.list = next.map((item: any) => {
        return {
          ...item,
          //templateFoto: (item.Usuario.foto ? `${this.avaliarService.getUrl()}images/${item.Usuario.foto}` : "assets/images/produto.svg"),
          templateFoto: (item.Usuario.foto || "assets/images/produto.svg"),
          qtdDias: Math.round((new Date().getTime() - new Date(item.created_at).getTime()) / 1000 / 60 / 60 / 24)
        }
      })
    })
  }

  clickAvisoDeslogado() {
    this.modalService.open(AvisoDeslogadoComponent);
  }

  avaliarClick() {
    this.modalService.dismissAll()

    if (this.authService.isLoggedIn()) {
      const modalRef = this.modalService.open(AvaliacaoComponent)
      modalRef.componentInstance.pessoa = this.pessoa
      modalRef.componentInstance.avaliarModal = this.avaliarModal;
      modalRef.componentInstance.avaliador = this.authService.getUser()
      modalRef.componentInstance.pesId = this.pessoa.id
    }
    else {
      //this.open(avisoDeslogado);
      this.clickAvisoDeslogado();
    }
  }

  getAvaliacaoUsuario() {
    if (this.authService.isLoggedIn()) {
      let filter = {
        id_usuario: this.authService.getUser().id,
        id_pessoa: this.pessoa.id
      }

      this.avaliarService.get(filter).subscribe(next => {
        if (next) {
          this.avaliarModal = next
        }
      })
    }
  }

  sizeUpFont() {
    this.indicetamanhoAtual = this.indicetamanhoAtual < 6 ?  (++this.indicetamanhoAtual) : this.indicetamanhoAtual
    this.acessibilidade = this.tamanhosFonts[this.indicetamanhoAtual]
  }

  sizeDownFont() {
    this.indicetamanhoAtual = this.indicetamanhoAtual > 0 ?  (--this.indicetamanhoAtual) : this.indicetamanhoAtual
    this.acessibilidade = this.tamanhosFonts[this.indicetamanhoAtual]
  }

}
