import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth.service';
import { RodadaNegocioService } from 'src/app/service/rodada-negocio.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-rodada-negocio-lista',
  templateUrl: './rodada-negocio-lista.component.html',
  styleUrls: ['./rodada-negocio-lista.component.css']
})
export class RodadaNegocioListaComponent implements OnInit {

  filtro: any = {
    titulo: ''
  }

  paginaAtual = 1
  qtdItensPorPagina = 2

  rodadasNegocio: any = { rows: [] }
  qtdPaginas: any = []

  constructor(
    private router: Router,
    private rodadaNegocioService: RodadaNegocioService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    // Só entra na tela se for perfil Administrador
    if (!this.authService.isLoggedIn() || this.authService.getUser().nome_perfil.toUpperCase() != 'ADMINISTRADOR') {
      this.router.navigate(['home'])
      return
    }

    this.filtrar()
  }

  cadastrar() {
    this.router.navigate(["rodada/cadastro"]);
  }

  filtrar() {
    this.obterRodadas(1)
  }

  obterRodadas(pagina: number) {

    this.paginaAtual = pagina;

    this.filtro.offset = (pagina - 1) * this.qtdItensPorPagina;
    this.filtro.limit = this.qtdItensPorPagina;

    this.rodadaNegocioService.listCount(this.filtro).subscribe(next => {
      this.rodadasNegocio = next
      const qtdTotalPaginas = Math.ceil(this.rodadasNegocio.count / this.qtdItensPorPagina)
      this.rodadasNegocio = { ...this.rodadasNegocio, qtdTotalPaginas }

      this.qtdPaginas = []
      this.qtdPaginas.push(pagina)
      let lastcount = 0
      while (this.qtdPaginas.length != lastcount && this.qtdPaginas.length < 5) {
        lastcount = this.qtdPaginas.length
        this.qtdPaginas.unshift(this.qtdPaginas[0] - 1)
        this.qtdPaginas.push(this.qtdPaginas[this.qtdPaginas.length - 1] + 1)
        this.qtdPaginas = this.qtdPaginas.filter(function (el: any) {
          return el > 0
        })
        this.qtdPaginas = this.qtdPaginas.filter(function (el: any) {
          return el <= qtdTotalPaginas
        })
      }

    })
  }

  criarRodada() {
    this.rodadaNegocioService.rodada = null
    this.router.navigate(['rodada-de-negocio/cadastro'])
  }

  editar(rodada: any) {
    this.rodadaNegocioService.rodada = rodada
    this.router.navigate(['rodada-de-negocio/cadastro'])
  }

  mudarStatus(rodada: any, status: string) {
    let frase = `Tem certeza que deseja ${status} a Rodada de Negócio "${rodada.titulo}"?`

    if (status.toLowerCase() == 'ativar') {
      frase = `Ao ativar esta Rodada de Negócio, as demais rodadas ativas serão desativadas automaticamente.
      Tem certeza que deseja ativar a "${rodada.titulo}"?`
    }

    Swal.fire({
      title: "",
      text: frase,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        this.rodadaNegocioService.mudarStatus(rodada).subscribe(next => {
          this.obterRodadas(1)
          this.toastr.success("Status atualizado com sucesso", "Sucesso")
        })
      }
    })
  }

}
