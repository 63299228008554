import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '.env';

@Injectable({
  providedIn: 'root'
})
export class GeocodeService {

  constructor(private http: HttpClient) { }

  getCoordinate(filter: any) {    
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${filter}&key=${environment.API_KEY}`);
  }
}
