import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from '../layout/main-layout/main-layout.component';
import { NoticiaDetalheComponent } from './noticia-detalhe/noticia-detalhe.component';
import { NoticiaListaComponent } from './noticia-lista/noticia-lista.component';

const routes: Routes = [
  { 
    path: 'detalhe-noticia', component: MainLayoutComponent, 
    pathMatch: 'full',
    children: [{ 
      path: '', component: NoticiaDetalheComponent
    }] 
  },
  { 
    path: 'lista-noticia', component: MainLayoutComponent, 
    pathMatch: 'full',
    children: [{ 
      path: '', component: NoticiaListaComponent
    }] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NoticiaRoutingModule { }
