<div class="modal-content modal-newsletter">
    <div class="modal-header">
        <button type="button" class="close"><i class="fas fa-times" (click)="closeModal()"></i></button>
       
    </div>

    <div class="modal-body">
        <h3>Newsletter Afrobiz</h3>
        <p>Assine nossa Newsletter e receba as novidades do Afrobiz Salvador em primeira mão.</p>
        <div role="main" id="newsletter-afrobiz-f563abe16f2226d0637f"></div>
    </div>
</div>