import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrecadastroComponent } from 'src/app/precadastro/precadastro.component';
import { AuthService } from 'src/app/service/auth.service';
import { GerenciadorConteudoService } from 'src/app/service/gerenciador-conteudo.service';

@Component({
  selector: 'app-slogan',
  templateUrl: './slogan.component.html',
  styleUrls: ['./slogan.component.css']
})
export class SloganComponent implements OnInit {

  pessoa: any;
  logado = false;
  sloganPt1: any = '';
  sloganPt2: any = '';
  sloganPt3: any = '';
  botao: any = '';
  classBotao: any = '';
  pt: any = true;

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private gerenciadorConteudo: GerenciadorConteudoService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.logado = this.authService.isLoggedIn();

    if (this.authService.isLoggedIn()) {
      this.logado = true;
      this.pessoa = this.authService.getUser();
    }

    if (this.authService.getLanguage() === 'us-en') {
      this.pt = false;
    }

    this.gerenciadorConteudo.getHighlights(
      'header_prodetur',
      this.authService.getLanguage()
    ).subscribe((result: any) => {
      if (result.error) {
        console.log('header-prodetur ', result.error);
        return;
      }
      for (const res of result) {
        if (res.Observacao === 'slogan') {
          if (res.Resumo === 'pt1') {
            this.sloganPt1 = res.Titulo;
          } else if (res.Resumo === 'pt2') {
            this.sloganPt2 = res.Titulo;
          } else {
            this.sloganPt3 = res.Titulo;
          }
        } else {
          this.botao = res.Titulo;
          this.classBotao = res.CssClass;
        }
      }
    });
  }

  openLink(link: any) {
    this.router.navigate([link]);
  }

  clickPreCadastro() {
    this.modalService.open(PrecadastroComponent);
  }

}
