import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class NumeroFuncionarioService extends MainService {

  constructor(private http: HttpClient) {
    super();
  }

  getList() {
    const url = `${super.getUrl()}numero-funcionario/list`;
    return this.http.post<any[]>(url, {}, this.getOptions());
  }
}
