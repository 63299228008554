<div class="barra-busca" (click)="limparLista()">
    <div>
        <label for="">
            O que procura?
        </label>
    </div>
    <div class="campos">
        <div class="col">
            <div class="form-group form-autocomplete">
                <input #searchBoxPessoa [(ngModel)]="filter.pessoa" id="search-box-pessoa" class="form-control "
                    (input)="searchPessoa(searchBoxPessoa.value)" placeholder="pessoa/entidade" />
                <ul *ngIf="filter.pessoa != ''">
                    <li *ngFor="let pessoa of pessoas | async">
                        <a [href]="" (click)='selecionaPessoa(pessoa.nome)' style="cursor: pointer;">
                            {{pessoa.nome}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col">
            <div class="form-group form-autocomplete">
                <input #searchBoxServico [(ngModel)]="filter.servico" id="search-box-servico" class="form-control "
                    (input)="searchServico(searchBoxServico.value)" placeholder="produto/serviço" />
                <ul *ngIf="filter.servico != ''">
                    <li *ngFor="let servico of servicos | async">
                        <a [href]="" (click)='selecionaServico(servico.nome)' style="cursor: pointer;">
                            {{servico.nome}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <button class="btn" for="search" data-toggle="collapse" data-target="#collapse-search" aria-expanded="false"
            aria-controls="collapse-search" (click)="Filtrar()">
            <i class="fas fa-search"></i>
            <span>pesquisar</span>
        </button>

    </div>
</div>
