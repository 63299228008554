import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/service/auth.service';
import { AvaliarService } from 'src/app/service/avaliar.service';
import { FavoritoService } from 'src/app/service/favorito.service';
import { PessoaService } from 'src/app/service/pessoa.service';
import { ServicoService } from 'src/app/service/servico.service';
import { ToastrService } from 'ngx-toastr';
import { AvisoDeslogadoComponent } from '../../aviso-deslogado/aviso-deslogado.component';
import { InteresseComponent } from '../interesse/interesse.component';
import { AvaliacaoComponent } from '../avaliacao/avaliacao.component';
import { HistoricoService } from 'src/app/service/historico.service';
import { forkJoin } from 'rxjs';
import { ComentarioComponent } from '../comentario/comentario.component';
import { DetalheComponent } from '../detalhe/detalhe.component';
import { environment } from '.env';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';

@Component({
  selector: 'app-pessoa-externo-detalhe',
  templateUrl: './pessoa-externo-detalhe.component.html',
  styleUrls: ['./pessoa-externo-detalhe.component.css'],
})
export class PessoaExternoDetalheComponent implements OnInit {
  pessoa: any;
  servicos: any;
  pesId: string | any;
  urlMapa: string | any;
  avaliador = this.authService.getUser();

  paginaAtual = 1;
  qtdPaginas: any;
  qtdItensPorPagina = 12;

  filter = {
    id_usuario_f: null,
    id_pessoa: null,
    order: '',
    offset: 0,
    limit: 0,
  };

  filtroTipo: any = 0;

  qtdServicos = {
    ate9: 0,
    de9ate19: 0,
    de19: 0,
  };

  servicoModal: any;
  avaliarModal = {
    id_usuario: '',
    id_pessoa: '',
    rating: 0,
    comentario: '',
  };

  avaliacaoMedia = {
    resultado: 0,
    total: 0,
  };

  valor: any = '';

  exibeFavoritos = false;

  listaDividida = false;

  catId: any = null;

  servicosSelecionados: any = [];
  servicoId: string | null = '';
  nomeCategoria = '';

  qtdProdutos = 0;

  constructor(
    private route: ActivatedRoute,
    private pessoaService: PessoaService,
    private servicoService: ServicoService,
    private authService: AuthService,
    private modalService: NgbModal,
    private favoritoService: FavoritoService,
    private avaliarService: AvaliarService,
    private toastr: ToastrService,
    private historicoService: HistoricoService,
    private router: Router
  ) {
    this.pessoa = {
      templateFoto: '',
    };

    this.servicos = {
      result: {
        rows: [],
      },
    };
  }

  ngOnInit(): void {
    this.catId = this.route.snapshot.queryParamMap.get('catId');

    if (this.catId) {
      this.listaDividida = true;
      this.servicoId = this.route.snapshot.queryParamMap.get('servicoId');
      this.qtdItensPorPagina = 1000;
    }

    this.pesId = this.route.snapshot.queryParamMap.get('pes');
    this.valor = this.route.snapshot.queryParamMap.get('valor');

    if (!this.pesId) {
      this.router.navigate(['home']);
      return;
    }

    this.getPessoa();
    this.getFiltro(1);
    this.getQuantidade();

    this.getAvaliacao();

    //somente obter se estiver logado
    if (this.authService.isLoggedIn()) {
      this.exibeFavoritos = true;
      this.getAvaliacaoUsuario();
      this.getFavoritosUsuario();

      if (this.pesId) {
        this.addVistoRecentemente();
      }
    }
  }

  getPessoa() {
    this.pessoaService.getPessoa(this.pesId).subscribe(
      (next) => {
        this.pessoa = next;
        this.pessoa.templateFoto = this.pessoa.foto || 'assets/images/user.svg';
        this.pessoa.urlMapa = this.pessoa.urlMapa.replace(
          'API_KEY',
          environment.API_KEY
        );
      },
      (error) => {
        this.router.navigate(['home']);
        return;
      }
    );
  }

  getFavoritoPessoa() {
    //this.favoritoService.list
  }

  getFiltro(pagina: number = 1) {
    switch (Number(this.filtroTipo)) {
      case 0:
        this.filter.order = 'ASC';
        break;
      case 1:
        this.filter.order = 'DESC';
        break;
    }
    this.getServicos(pagina);
  }

  getServicos(pagina: number, catId: any = '') {
    this.filter.id_pessoa = this.pesId;

    if (this.authService.isLoggedIn()) {
      this.filter.id_usuario_f = this.authService.getUser().id;
    }

    this.paginaAtual = pagina;

    this.filter.offset = (pagina - 1) * this.qtdItensPorPagina;
    this.filter.limit = this.qtdItensPorPagina;

    this.servicoService.getServicoList(this.filter).subscribe((next) => {
      next.result.rows = next.result.rows.map((pes: any) => {
        let fotoCat;
        if (pes['Subcategorium.Categorium.nome'] == 'Afroempreendedores') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-afroempreendedores.svg';
        } else if (pes['Subcategorium.Categorium.nome'] == 'Baiana') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-baiana.svg';
        } else if (pes['Subcategorium.Categorium.nome'] == 'Capoeira') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-capoeira.svg';
        } else if (pes['Subcategorium.Categorium.nome'] == 'Arte & Cultura') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-cultura.svg';
        } else if (pes['Subcategorium.Categorium.nome'] == 'Flora') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-flora.svg';
        } else if (pes['Subcategorium.Categorium.nome'] == 'Gastronomia') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-gastronomia.svg';
        } else if (pes['Subcategorium.Categorium.nome'] == 'Moda & Beleza') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-moda_beleza.svg';
        } else if (pes['Subcategorium.Categorium.nome'] == 'Religião') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-religiao.svg';
        } else if (pes['Subcategorium.Categorium.nome'] == 'Turismo') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-turismo.svg';
        } else {
          fotoCat = 'assets/images/user.svg';
        }

        //pes.templateFoto = pes.foto ? `${this.servicoService.getUrl()}images/${pes.foto}` : "assets/images/user.svg";
        pes.templateFoto = pes.foto || fotoCat;
        pes.foto = pes.templateFoto;
        pes.youtube = pes.youtube
          ? `https://www.youtube.com/embed/${pes.youtube}`
          : '';
        pes.favorito = false;
        return pes;
      });

      this.qtdProdutos = next.result.count;
      let listTemp: any = [];
      if (this.listaDividida) {
        next.result.rows.forEach((serv: any) => {
          if (serv.id_subcategoria == this.catId) {
            this.servicosSelecionados.push(serv);
          } else {
            listTemp.push(serv);
          }
        });

        this.nomeCategoria = this.servicosSelecionados[0]['Subcategorium.nome'];

        next.result.rows = listTemp;

        next.result.rows.splice(6);
      }

      this.servicos = next;

      const qtdTotalPaginas = Math.ceil(
        this.servicos.result.count / this.qtdItensPorPagina
      );
      this.servicos = { ...this.servicos, qtdTotalPaginas };
      this.qtdPaginas = [];
      this.qtdPaginas.push(pagina);
      let lastcount = 0;
      while (
        this.qtdPaginas.length != lastcount &&
        this.qtdPaginas.length < 5
      ) {
        lastcount = this.qtdPaginas.length;
        this.qtdPaginas.unshift(this.qtdPaginas[0] - 1);
        this.qtdPaginas.push(this.qtdPaginas[this.qtdPaginas.length - 1] + 1);
        this.qtdPaginas = this.qtdPaginas.filter(function (el: any) {
          return el > 0;
        });
        this.qtdPaginas = this.qtdPaginas.filter(function (el: any) {
          return el <= qtdTotalPaginas;
        });
      }
    });
  }

  AddFavorite(id: any, event: any) {
    if (this.authService.isLoggedIn()) {
      this.servicos.result.rows.map((ser: any) => {
        if (ser.id == id) {
          ser.favorito = !ser.favorito;
          if (ser.favorito) {
            this.favoritoService
              .add({
                id_usuario: this.authService.getUser().id,
                id_servico: ser.id,
              })
              .subscribe();
          } else {
            this.favoritoService
              .delete({
                id_usuario: this.authService.getUser().id,
                id_servico: ser.id,
              })
              .subscribe();
          }
        }
      });
    } else {
      event.preventDefault();
      this.clickAvisoDeslogado();
    }
  }

  getQuantidade() {
    let filter1 = {
      id_pessoa: this.pesId,
      qtd: { ate: 9.99 },
    };
    this.servicoService
      .searchReturnList(filter1)
      .subscribe((next) => (this.qtdServicos.ate9 = next));

    let filter2 = {
      id_pessoa: this.pesId,
      qtd: { de: 9.99, ate: 19.99 },
    };
    this.servicoService
      .searchReturnList(filter2)
      .subscribe((next) => (this.qtdServicos.de9ate19 = next));

    let filter3 = {
      id_pessoa: this.pesId,
      qtd: { de: 19.99 },
    };
    this.servicoService
      .searchReturnList(filter3)
      .subscribe((next) => (this.qtdServicos.de19 = next));
  }
  getAvaliacaoUsuario() {
    let filter = {
      id_usuario: this.authService.getUser().id,
      id_pessoa: this.pesId,
    };

    this.avaliarService.get(filter).subscribe((next) => {
      if (next) {
        this.avaliarModal = next;
      }
    });
  }

  getFavoritosUsuario() {
    let filter = {
      id_usuario: this.authService.getUser().id,
    };

    this.favoritoService.list(filter).subscribe((next) => {
      this.servicos.result.rows.map((servico: any) => {
        next.map((favorito: any) => {
          if (
            favorito.id_pessoa == servico.id ||
            favorito.id_servico == servico.id
          ) {
            servico.favorito = true;
          }
        });
      });
    });
  }

  getAvaliacao() {
    let filter = {
      id_pessoa: this.pesId,
    };
    this.avaliarService.getMedia(filter).subscribe((next) => {
      this.avaliacaoMedia = next[0];
    });
  }

  tenhoInteresse(servico: any) {
    if (this.authService.isLoggedIn()) {
      //this.servicoModal = servico;
      const modalRef = this.modalService.open(InteresseComponent);
      modalRef.componentInstance.servicoModal = servico;
    } else {
      //this.open(avisoDeslogado);
      this.clickAvisoDeslogado();
    }
  }

  _avaliarClick(content: any, avisoDeslogado: any) {
    if (this.authService.isLoggedIn()) {
      this.open(content);
    } else {
      //this.open(avisoDeslogado);
      this.clickAvisoDeslogado();
    }
  }

  avaliarClick() {
    if (this.authService.isLoggedIn()) {
      const modalRef = this.modalService.open(AvaliacaoComponent);
      modalRef.componentInstance.pessoa = this.pessoa;
      modalRef.componentInstance.avaliarModal = this.avaliarModal;
      modalRef.componentInstance.avaliador = this.avaliador;
      modalRef.componentInstance.pesId = this.pesId;
    } else {
      //this.open(avisoDeslogado);
      this.clickAvisoDeslogado();
    }
  }

  PostarAvaliacao() {
    if (this.avaliarModal.rating <= 0) {
      this.toastr.warning(
        `Quantas estrelas ${this.pessoa.nome} merece?`,
        'AVALIAÇÃO'
      );
      return;
    }
    this.avaliarModal.id_usuario = this.authService.getUser().id;
    this.avaliarModal.id_pessoa = this.pessoa.id;

    this.avaliarService.add(this.avaliarModal).subscribe(
      (next) => {
        if (next == 1 || next.id) {
          this.toastr.success(
            `Obrigado por avaliar ${this.pessoa.nome}!`,
            'SUCESSO'
          );
          this.modalService.dismissAll();
        }
      },
      (error) => {
        this.toastr.error('Algo deu errado.', 'ERRO');
      }
    );
  }

  open(content: any) {
    this.modalService.open(content).result.then(
      (result: any) => {
        //this.closeResult = `Closed with: ${result}`;
        console.log('modal abriu', result);
      },
      (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        console.log('modal erro', reason);
      }
    );
  }

  clickAvisoDeslogado() {
    this.modalService.open(AvisoDeslogadoComponent);
  }

  clickInteresse(servico: any) {
    // if (this.authService.isLoggedIn()) {
    forkJoin(
      this.pessoaService.getPessoa(this.pessoa.id),
      this.avaliarService.getMedia({ id_pessoa: this.pessoa.id })
    ).subscribe((res) => {
      const modalRef = this.modalService.open(InteresseComponent);
      modalRef.componentInstance.servicoModal = servico;
      let pessoa = res[0];
      //pessoa.templateFoto = pessoa.foto ? `${this.pessoaService.getUrl()}images/${pessoa.foto}` : "assets/images/produto.svg";
      pessoa.templateFoto = pessoa.foto || 'assets/images/produto.svg';
      pessoa.id = this.pessoa.id;
      modalRef.componentInstance.pessoa = pessoa;
      modalRef.componentInstance.avaliacaoMedia = res[1][0];
    });

    // const modalRef = this.modalService.open(InteresseComponent);
    // modalRef.componentInstance.pessoa = this.pessoa;
    // modalRef.componentInstance.servicoModal = servico;
    // modalRef.componentInstance.avaliacaoMedia = this.avaliacaoMedia;
    // //modalRef.componentInstance.servicos = this.servicos;
    // //modalRef.componentInstance.id = servico;
    // //modalRef.componentInstance.favoritoService = this.favoritoService;
    // }
    // else {
    //   this.clickAvisoDeslogado();
    // }
  }

  addVistoRecentemente() {
    if (this.authService.isLoggedIn()) {
      let historico = {
        id_usuario: this.authService.getUser().id,
        id_pessoa: this.pesId,
      };
      this.historicoService.add(historico).subscribe();
    }
  }

  clickWhatsapp(celular: any) {
    //window.open(`https://web.whatsapp.com/send?phone=+55 ${celular}`, '_blak')
    window.open(
      `${environment.URL_WHATSAPP}`.replace('<numero>', celular),
      '_blak'
    );
  }

  comentarioClick() {
    // if (this.authService.isLoggedIn()) {
    const modalRef = this.modalService.open(ComentarioComponent);
    let pessoa = this.pessoa;
    pessoa.qtdProduto = this.servicos.result.rows.length;
    modalRef.componentInstance.pessoa = pessoa;
    // modalRef.componentInstance.avaliarModal = this.avaliarModal;
    // modalRef.componentInstance.avaliador = this.avaliador;
    // modalRef.componentInstance.pesId = this.pesId;
    // }
    // else {
    //   //this.open(avisoDeslogado);
    //   this.clickAvisoDeslogado();
    // }
  }
  detalheClick() {
    const modalRef = this.modalService.open(DetalheComponent);
    this.pessoa.qtdProdutos = this.servicos.result.rows.length;
    modalRef.componentInstance.pessoa = this.pessoa;
  }

  irParaPessoa() {
    this.router.navigate(['detalhe-pessoa'], {
      queryParams: { pes: this.pesId },
    });
  }
}
