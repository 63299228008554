import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService extends MainService {

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  upload(file: any): Observable<any> {
    const url = `${super.getUrl()}upload`;
    return this.http.post(url, { file: file }, this.getOptions());
  }
}
