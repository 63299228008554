<div class="conteudo">
  <div class="col">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Página inicial</li>
        <li class="breadcrumb-item active" aria-current="page">Favoritos</li>
      </ol>
    </nav>

    <!-- =============LISTA FAVORITOS=================== -->
    <div class="lista-favoritos">
      <div class="title-header">
        <h2>Lista de Favoritos</h2>
        <button type="button" class="btn btn-padrao btn-sm btn-danger" (click)="delSelecteds()" [disabled]="disabled">
          <i class="fas fa-trash"></i>
          Excluir selecionados
        </button>
      </div>

      <ul class="list lista-cards">
        <!-- ==============ITEM LISTA=============== -->
        <li *ngFor="let item of favoritoList">
          <div class="item" *ngIf="item.alias == 'servico'">
            <div class="custom-control custom-checkbox custom-control-inline">
              <input type="checkbox" class="custom-control-input" (change)="onCheckBoxChange(item.id, item.alias)"
                id="{{item.id}}">
              <label class="custom-control-label" for="{{item.id}}">
              </label>
            </div>

            <div class="image">
              <img src="{{item.foto}}" alt="">
            </div>
            <div class="texto">
              <h5>{{item.nome}}</h5>
              <span>{{item.valor | currency: 'BRL'}}</span>
              <div class="card-footer">
                <button type="button" (click)="clickInteresse(item)" class="btn btn-padrao">
                  <i class="fas fa-smile"></i>
                  Tenho interesse!
                </button>
                <button type="button" (click)="delete(item.id, item.alias)" class="btn btn-padrao btn-danger">
                  <i class="fas fa-trash"></i>
                  Excluir
                </button>
              </div>
            </div>
          </div>
          <div class="item" *ngIf="item.alias == 'pessoa'">
            <div class="custom-control custom-checkbox custom-control-inline">
              <input type="checkbox" class="custom-control-input" (change)="onCheckBoxChange(item.id, item.alias)"
                id="{{item.id}}">
              <label class="custom-control-label" for="{{item.id}}">
              </label>
            </div>

            <div class="image">
              <img src="{{item.foto}}" alt="">
            </div>
            <div class="texto">
              <h5>{{item.nome}}</h5>
              <span>{{item.valor}}</span>
              <div class="card-footer">
                <button type="button" (click)="delete(item.id, item.alias)" class="btn btn-padrao btn-danger">
                  <i class="fas fa-trash"></i>
                  Excluir
                </button>
              </div>
            </div>
          </div>
        </li>
        <!-- ==============FIM ITEM LISTA=============== -->
      </ul>
      <div class="d-flex justify-content-center" *ngIf="qtdPaginas == 0">
        <p>Você não Possui Favoritos</p>
      </div>
      <div class="d-flex justify-content-center" *ngIf="qtdPaginas > 1">
        <nav>
          <ul class="pagination">
            <li class="page-item ">
              <a class="page-link" *ngIf="paginaAtual == 0" style="cursor: pointer;"><i
                  class="fas fa-chevron-left"></i></a>
              <button class="page-link" (click)="getFavoritosList(paginaAtual - 1)" *ngIf="paginaAtual != 0">
                <i class="fas fa-chevron-left"></i>
              </button>
            </li>
            <li class="page-item " *ngFor="let opt of [].constructor(qtdPaginas); let i = index">
              <a class="page-link active" *ngIf="paginaAtual == i" style="cursor: pointer;">{{ i + 1 }}</a>
              <button class="page-link" (click)="getFavoritosList(i)" *ngIf="paginaAtual != i">
                {{ i + 1 }}
              </button>
            </li>
            <li class="page-item">
              <a class="page-link" *ngIf="paginaAtual == (qtdPaginas-1)" style="cursor: pointer;"><i
                  class="fas fa-chevron-right"></i></a>
              <button class="page-link" (click)="getFavoritosList(paginaAtual + 1)"
                *ngIf="paginaAtual != (qtdPaginas-1)">
                <i class="fas fa-chevron-right"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <!-- =============FIM LISTA FAVORITOS=================== -->
  </div>
</div>
