import { Component, OnInit } from '@angular/core';
import { environment } from '.env';
import { ToastrService } from 'ngx-toastr';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCropperComponent } from 'src/app/image-cropper/modal/modal-cropper.component';
import { UploadService } from 'src/app/service/upload.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CustomAdapter, CustomDateParserFormatter } from 'src/app/pessoa/pessoa.component';
import { RodadaNegocioService } from 'src/app/service/rodada-negocio.service';
import { AuthService } from 'src/app/service/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-rodada-negocio-cadastro',
  templateUrl: './rodada-negocio-cadastro.component.html',
  styleUrls: ['./rodada-negocio-cadastro.component.css'],
  providers: [DatePipe,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})
export class RodadaNegocioCadastroComponent implements OnInit {

  currentDate = {
    date: new Date().getDate(),
    month: new Date().getMonth(),
    year: new Date().getFullYear()
  }

  fileToUpload: any
  tamanhoMaximoImagem = environment.MAX_SIZE_UPLOAD / 1000000
  spinFoto = false
  templateModal: any = 'assets/images/produto.svg'
  templateBanner: any = 'assets/images/produto.svg'
  rodadaNegocio: any = {
    id: '',
    titulo: '',
    descricao: '',
    dataInicio: '',
    dataFim: '',
    ativo: false
  }
  SalvarClicado = false
  isSubmited = false
  ehAtiva = false

  constructor(
    private toastr: ToastrService,
    private modalService: NgbModal,
    private upload: UploadService,
    private router: Router,
    private rodadaNegocioService: RodadaNegocioService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    // Só entra na tela se for perfil Administrador
    if (!this.authService.isLoggedIn() || this.authService.getUser().nome_perfil.toUpperCase() != 'ADMINISTRADOR') {
      this.router.navigate(['home'])
      return
    }

    this.obterRodada()
  }

  obterRodada() {
    if (this.rodadaNegocioService.rodada) {
      this.rodadaNegocioService.obterRodada(this.rodadaNegocioService.rodada.id).subscribe(next => {
        this.rodadaNegocio = next
        this.rodadaNegocio.dataInicio = next.data_inicio.split('T')[0].split('-').reverse().join('-')
        this.rodadaNegocio.dataFim = next.data_fim.split('T')[0].split('-').reverse().join('-')

        this.templateBanner = this.rodadaNegocio.imagem_banner || 'assets/images/produto.svg'
        this.templateModal = this.rodadaNegocio.imagem_modal || 'assets/images/produto.svg'

        this.ehAtiva = this.rodadaNegocio.ativo
      })
    }
  }

  handleFileInput(event: any, tipo: string) {
    this.fileToUpload = event.target.files.item(0)

    if (!this.fileToUpload) {
      return
    }

    if (this.fileToUpload.size > environment.MAX_SIZE_UPLOAD) {
      this.toastr.error(`Tamanho máximo de imagem permitido é ${this.tamanhoMaximoImagem}Mb`)
      return
    }

    var reader = new FileReader();
    reader.onload = () => {
      this.upload.upload(reader.result).subscribe(x => {

        if (tipo == 'modal') {
          this.rodadaNegocio.imagem_modal = x.persistentUrl
          this.templateModal = reader.result
          this.spinFoto = false
        }

        if (tipo == 'banner') {
          this.rodadaNegocio.imagem_banner = x.persistentUrl
          this.templateBanner = reader.result
          this.spinFoto = false
        }
      });
    }
    reader.readAsDataURL(this.fileToUpload);

  }

  alterarBanner() {

  }

  excluirBanner() {
    this.rodadaNegocio.imagem_banner = null
    this.templateBanner = 'assets/images/produto.svg'
  }

  alterarModal() {

  }

  excluirModal() {
    this.rodadaNegocio.imagem_modal = null
    this.templateModal = 'assets/images/produto.svg'
  }

  Salvar() {

    this.isSubmited = true
    if (!this.validateField()) {
      return
    }

    if (this.rodadaNegocio.dataInicio) {
      this.rodadaNegocio.data_inicio = (new Date(this.rodadaNegocio.dataInicio.split('-').reverse().join('/'))).toJSON()
    }

    if (this.rodadaNegocio.dataFim) {
      this.rodadaNegocio.data_fim = (new Date(`${this.rodadaNegocio.dataFim.split('-').reverse().join('/')} 23:59`)).toJSON()
    }

    let { dataInicio, dataFim, ...entity } = this.rodadaNegocio

    if (this.rodadaNegocio.ativo && this.ehAtiva) {

      let frase = `Ao ativar esta Rodada de Negócio, as demais rodadas ativas serão desativadas automaticamente.
    Tem certeza que deseja ativar a "${this.rodadaNegocio.titulo}"?`

      Swal.fire({
        title: "",
        text: frase,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.isConfirmed) {
          this.SalvarClicado = true

          if (!this.rodadaNegocio.id) {
            this.criar(entity)
          }

          if (this.rodadaNegocio.id) {
            this.atualizar(entity)
          }
        }
      })
      return
    }

    this.SalvarClicado = true

    if (!this.rodadaNegocio.id) {
      this.criar(entity)
    }

    if (this.rodadaNegocio.id) {
      this.atualizar(entity)
    }
  }

  criar(entity: any) {
    this.rodadaNegocioService.salvar(entity).subscribe(next => {
      this.SalvarClicado = false
      this.toastr.success('Rodada adicionada com sucesso', 'Sucesso')
      this.irParaLista()
    }, error => {
      this.SalvarClicado = false
      this.toastr.error('Não foi possível concluir', 'Erro')
    })
  }

  atualizar(entity: any) {
    this.rodadaNegocioService.atualizar(entity).subscribe(next => {
      this.SalvarClicado = false
      this.toastr.success('Rodada atualizada com sucesso', 'Sucesso')
      this.irParaLista()
    }, error => {
      this.SalvarClicado = false
      this.toastr.error('Não foi possível concluir', 'Erro')
    })
  }

  irParaLista() {
    this.router.navigate(["rodada-de-negocio"])
  }

  validateField() {
    let fields = [
      'titulo',
      'dataInicio',
      'dataFim'
    ]

    for (const iterator of fields) {
      if (!this.rodadaNegocio[iterator]) {
        return false
      }
    }

    return true
  }

  isFieldValid(field: any) {
    return this.isSubmited && !field
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
}
