import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PessoaService } from 'src/app/service/pessoa.service';
import { ServicoService } from 'src/app/service/servico.service';

@Component({
  selector: 'app-barra-busca',
  templateUrl: './barra-busca.component.html',
  styleUrls: ['./barra-busca.component.css']
})
export class BarraBuscaComponent implements OnInit {

  filter = {
    pessoa: "",
    servico: ""
  }

  pessoas: any;
  produtos: any;
  servicos: any;
  private searchTermsPessoa = new Subject<string>();
  private searchTermsServico = new Subject<string>();

  constructor(
    private pessoaService: PessoaService,
    private servicoService: ServicoService,
    private router: Router) {

  }

  ngOnInit(): void {
    this.pessoas = this.searchTermsPessoa.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => this.pessoaService.searchPessoa(term))
    )

    this.servicos = this.searchTermsServico.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => this.servicoService.searchServico(term))
    )
  }

  Filtrar() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(["resultado-da-busca2"], { queryParams: { pes: this.filter.pessoa, serv: this.filter.servico } });
  }

  searchPessoa(term: string) {
    if (term.length > 2 || term.length == 0) {
      this.searchTermsPessoa.next(term);
    }
  }
  searchServico(term: string) {
    if (term.length > 2 || term.length == 0) {
      this.searchTermsServico.next(term);
    }
  }

  selecionaPessoa(term: string) {
    this.searchTermsPessoa.next('');
    this.filter.pessoa = term;
  }
  selecionaServico(term: string) {
    this.searchTermsServico.next('');
    this.filter.servico = term;
  }

  limparLista() {
    this.searchTermsPessoa.next('');
    this.searchTermsServico.next('');
  }
}
