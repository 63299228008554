<div class="topo">
  <div class="center navbar my-lg-4 px-lg-3">


    <div class="navbar-nav bd-navbar-nav flex-row">
      <!-- button mobile== -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menu-sidebar"
        aria-controls="menu-sidebar" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars"></i>
      </button>
      <a class="navbar-brand logo" routerLink="/home" style="cursor: pointer;">
        <!-- <img src="assets/images/marca.svg" alt=""> -->
      </a>
    </div>
    <!-- ===============BARRA BUSCA=========================== -->
    <div class="busca">
      <div class="col">
        <div class="form-group">
          <input #searchBoxServico [(ngModel)]="filter.servico" (input)="searchServico(searchBoxServico.value)"
            type="text" class="form-control superplaceholder" (keypress)="Filtrar($event)">
          <ul *ngIf="filter.servico != ''">
            <li *ngFor="let term of arrTerms">
              <a [href]="" (click)='selecionaServico(term.nome)' style="cursor: pointer;">
                {{term.nome}}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <button class="btn" for="search" data-toggle="collapse" data-target="#collapse-search" aria-expanded="false"
        (click)="Filtrar($event)" (keypress)="Filtrar($event)" aria-controls="collapse-search"
        [disabled]="!(filter.servico.length > 2)">
        <i class="fas fa-search"></i>
      </button>

    </div>
    <!-- ==============FIM==BARRA BUSCA=========================== -->
    <div class="area-botoes">

      <div class="nav-item dropdown linguas">
        <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" style="cursor: pointer;">
          <img src="{{idioma.imagem}}" alt="">
          <span id="lang">{{idioma.texto}}</span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" [routerLink]="" (click)="trocaIdioma('pt-br')" style="cursor: pointer;">
              <img src="assets/images/icon-br.svg" alt="">
              <span>BR</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="" (click)="trocaIdioma('us-en')" style="cursor: pointer;">
              <img src="assets/images/icon-en.svg" alt="">
              <span>EN</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="d-flex" *ngIf="!logado && pt">
        <a [href]="" (click)="openLink(info)" *ngFor="let info of infosDeslogado" style="cursor: pointer;">
          <i *ngIf="info" class="{{info.CssClass}}"></i>
          <span *ngIf="info.Titulo != 'O que é'">{{info.Titulo}}</span>
        </a>
      </div>
      <div class="d-flex" *ngIf="!logado && !pt">
        <a [href]="" (click)="openLink(info)" *ngFor="let info of infosDeslogado" style="cursor: pointer;">
          <i *ngIf="info" class="{{info.CssClass}}"></i>
          <span *ngIf="info.Titulo != 'O que é'">Map</span>
        </a>
      </div>
      <div class="d-flex" *ngIf="logado">
        <a (click)="openLink(info)" *ngFor="let info of infosLogado" style="cursor: pointer;">
          <i *ngIf="verificarPermissaoClasse(info)" class="{{info.CssClass}}"></i>
          <span *ngIf="verificarPermissaoTitulo(info)">{{info.Titulo}}</span>
        </a>
      </div>
      <div class="user-login" *ngIf="!logado" (click)="clickLogin()">
        <div>
          <i *ngIf="login" class="{{login.CssClass}}"></i>
          <span>
            <a style="cursor: pointer;">{{login ? login.Titulo : ''}}</a>
          </span>
        </div>
      </div>
      <div *ngIf="logado">
        <a class="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor: pointer;">
          <div class="user menor">
            <img src='{{pessoa.templateFoto}}' alt="">
          </div>
          <i class="fas fa-ellipsis-v"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right" *ngIf="pessoa.nome_perfil == 'Pessoa'">
          <h5>
            Olá, <strong>{{pessoa.nome}}</strong>
          </h5>
          <a class="dropdown-item" routerLink="/editar-dados-pessoais" *ngIf="pessoa.existePessoa"
            style="cursor: pointer;">
            <i class="fas fa-user"></i>
            Perfil
          </a>
          <a class="dropdown-item" routerLink="/editar-dados-usuario" *ngIf="!pessoa.existePessoa"
            style="cursor: pointer;">
            <i class="fas fa-user"></i>
            Perfil
          </a>
          <div class="dropdown-divider"></div>

          <h6 class="dropdown-header">Produtos / Serviços</h6>

          <a routerLink="/meus-produtos-servicos" style="cursor: pointer;">Meus Produtos e Serviços</a>

          <a *permission="['add-product-or-service']" routerLink="/servico" class="dropdown-item"
            style="cursor: pointer;">
            <i class="fas fa-plus"></i>
            Produto ou Serviço
          </a>

          <div class="dropdown-divider"></div>

          <a class="dropdown-item" routerLink="/favoritos" style="cursor: pointer;">
            <i class="fas fa-heart"></i>
            Favoritos
          </a>
          <a class="dropdown-item" routerLink="/suporte" style="cursor: pointer;">
            <i class="fas fa-mug-hot"></i>
            Suporte
          </a>
          <a class="dropdown-item sair" (click)="Sair()" style="cursor: pointer;">
            <i class="fas fa-times"></i>
            Sair
          </a>
        </div>
        <div class="dropdown-menu dropdown-menu-right" *ngIf="pessoa.nome_perfil == 'Administrador'">
          <h5>
            Olá, <strong>{{pessoa.nome}}</strong>
          </h5>
          <a class="dropdown-item" routerLink="/editar-dados-usuario" style="cursor: pointer;">
            <i class="fas fa-user"></i>
            Perfil
          </a>
          <a routerLink="/lista-gerenciar-contas" class="dropdown-item" style="cursor: pointer;">
            <i class="fas fa-user-cog"></i>
            Gerenciar contas
          </a>
          <a routerLink="/adm-listagem-pessoas" class="dropdown-item" style="cursor: pointer;">
            <i class="fas fa-user-friends"></i>
            Pessoas/Entidades
          </a>
          <div *permission="['add-people']">
            <a class="dropdown-item" (click)="acessarAdministracao()" style="cursor: pointer;">
              <i class="fas fa-cog"></i>
              Administração
            </a>
            <div *permission="['add-people']">
              <div class="dropdown-divider"></div>

              <a class="dropdown-item" (click)="criarPessoa()" style="cursor: pointer;">
                <i class="fas fa-plus"></i>
                Pessoas/Entidades
              </a>

              <div class="dropdown-divider"></div>
            </div>
          </div>

          <a routerLink="/dashboard" class="dropdown-item" style="cursor: pointer;">
            <i class="fas fa-tachometer-alt"></i>
            Dashboard
          </a>
          <a routerLink="/relatorio" class="dropdown-item" style="cursor: pointer;">
            <i class="fas fa-file-alt"></i>
            Relatórios
          </a>
          <a routerLink="/rodada-de-negocio" class="dropdown-item" style="cursor: pointer;">
            <i class="fas fa-handshake"></i>
            Rodada de Negócio
          </a>
          <!-- <a routerLink="/home" class="dropdown-item" style="cursor: pointer;">
                        <i class="fas fa-list"></i>
                        Logs de acesso
                    </a> -->
          <a class="dropdown-item sair" (click)="Sair()" style="cursor: pointer;">
            <i class="fas fa-times"></i>
            Sair
          </a>
          <small class="mt-4 d-block" style="font-size: 10px; color: #888;">
            <strong>VERSÃO: </strong><span style="display: block">{{versao}}</span>
          </small>
        </div>
        <div class="dropdown-menu dropdown-menu-right" *ngIf="pessoa.nome_perfil == 'Agente'">
          <a class="dropdown-item" routerLink="/editar-dados-usuario" *ngIf="!pessoa.existePessoa"
            style="cursor: pointer;">
            <i class="fas fa-user"></i>
            Perfil
          </a>
          <a routerLink="/adm-listagem-pessoas" class="dropdown-item" style="cursor: pointer;">
            <i class="fas fa-user-friends"></i>
            Pessoas/Entidades
          </a>
          <div *permission="['add-people']">
            <div class="dropdown-divider"></div>

            <a class="dropdown-item" (click)="criarPessoa()" style="cursor: pointer;">
              <i class="fas fa-plus"></i>
              Pessoas/Entidades
            </a>

            <div class="dropdown-divider"></div>
          </div>
          <a class="dropdown-item sair" (click)="Sair()" style="cursor: pointer;">
            <i class="fas fa-times"></i>
            Sair
          </a>
        </div>
      </div>
    </div>
  </div>

  <nav class="navbar navbar-expand-lg menu center">
    <div class="container-fluid">

      <div class="collapse navbar-collapse" id="menu-sidebar">
        <div class="close-menu" data-bs-toggle="collapse" data-bs-target="#menu-sidebar" aria-controls="menu-sidebar"
          aria-expanded="false" aria-label="Toggle navigation"></div>
        <ul class="navbar-nav">
          <li class="nav-item dropdown" *ngFor="let categoria of menu">
            <a class="nav-link dropdown-toggle" [routerLink]="" data-bs-toggle="dropdown" style="cursor: pointer;">
              {{categoria.nome}}
            </a>
            <ul class="dropdown-menu {{categoria.Categorium.Subcategoria.length > 9 ? 'colunas' : ''}}"
              aria-labelledby="navbarDropdown">
              <li *ngFor="let sub of categoria.Categorium.Subcategoria">
                <a [routerLink]="" class="dropdown-item"
                  (click)="resultadoBusca(sub.id, categoria.nome, sub.SubcategoriaIdiomas[0].nome)"
                  style="cursor: pointer;">{{sub.SubcategoriaIdiomas[0].nome}}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
