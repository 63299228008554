import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { RelatorioService } from 'src/app/service/relatorio.service';
import { ServicoService } from 'src/app/service/servico.service';
import { DatePipe } from '@angular/common';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from 'src/app/service/usuario.service';
import { RodadaNegocioService } from 'src/app/service/rodada-negocio.service';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-relatorio',
  templateUrl: './relatorio.component.html',
  styleUrls: ['./relatorio.component.css'],
  providers: [DatePipe,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})
export class RelatorioComponent implements OnInit {

  currentDate = {
    date: new Date().getDate(),
    month: new Date().getMonth(),
    year: new Date().getFullYear()
  }

  filtro = {
    nome: '',
    cpf: '',
    dataInicial: '',
    dataFinal: '',
    dataInicialTela: '',
    dataFinalTela: '',
    tipo: '',
    afroestima: '',
    id_categoria: '',
    id_subcategoria: '',
    possui_produto: '',
    tipoRelatorio: '',
    id_cadastrante: '',
    simplificado: false,
    id_rodada: ''
  }

  subcategoriaDisabledToggle = true
  naoPossuiServico = false
  botaoClicado = false

  listTipo = [{ id: '', nome: 'Ambos' }, { id: 'P', nome: 'Produto' }, { id: 'S', nome: 'Serviço' }]
  listSIMNAO = [{ id: '', nome: 'Ambos' }, { id: true, nome: 'SIM' }, { id: false, nome: 'NÃO' }]
  listCategoria: any = []
  listSubCategoria: any = []
  listCadastrante: any = []
  listRodadaNegocio: any = []

  constructor(
    private relatorioService: RelatorioService,
    private authService: AuthService,
    private router: Router,
    private servicoService: ServicoService,
    private usuarioService: UsuarioService,
    private rodadaNegocioService: RodadaNegocioService) {
  }

  ngOnInit(): void {

    // Só entra na tela se for perfil Administrador
    if (!this.authService.isLoggedIn() || this.authService.getUser().nome_perfil.toUpperCase() != 'ADMINISTRADOR') {
      this.router.navigate(['home'])
      return
    }

    this.getCategoriaList()
    this.getCadastranteList()
    this.getRodadaNegocioList()
  }

  getCategoriaList() {
    this.servicoService.getCategoriaList().subscribe(next => {
      this.listCategoria = next
      this.listCategoria.unshift({
        id: '',
        nome: 'Selecione...'
      })
    })
  }

  getCadastranteList() {
    this.usuarioService.cadastrantes().subscribe(next => {
      this.listCadastrante = next
      this.listCadastrante.unshift({
        id: '0',
        nome: 'Autocadastro'
      })
      this.listCadastrante.unshift({
        id: '',
        nome: 'Todos'
      })
    })
  }

  getSubcategoriaList() {
    this.listSubCategoria = []
    this.subcategoriaDisabledToggle = true
    this.filtro.id_subcategoria = ''
    if (this.filtro && this.filtro.id_categoria) {
      this.servicoService.getSubcategoriaList(this.filtro.id_categoria).subscribe(next => {
        this.listSubCategoria = next
        if (this.listSubCategoria.length > 0) {
          this.subcategoriaDisabledToggle = false
          this.listSubCategoria.unshift({
            id: '',
            nome: 'Selecione...'
          })
        }
      })
    }
  }

  getRodadaNegocioList() {
    this.rodadaNegocioService.list().subscribe(next => {
      this.listRodadaNegocio = next
      this.listRodadaNegocio.unshift({
        id: '',
        titulo: 'Selecione...'
      })
    })
  }

  filtroCategoria() {
    this.naoPossuiServico = false
    if (this.filtro.possui_produto == 'false') {
      this.listSubCategoria = []
      this.filtro.id_categoria = ''
      this.filtro.id_subcategoria = ''
      this.filtro.tipo = ''
      this.subcategoriaDisabledToggle = true
      this.naoPossuiServico = true
    }
  }

  RelatorioPessoas(tipo: string) {

    this.botaoClicado = true

    this.filtro.dataInicial = ''

    if (this.filtro.dataInicialTela) {
      this.filtro.dataInicial = (new Date(this.filtro.dataInicialTela.split('-').reverse().join('/'))).toJSON()
    }

    this.filtro.dataFinal = ''
    if (this.filtro.dataFinalTela) {
      this.filtro.dataFinal = (new Date(`${this.filtro.dataFinalTela.split('-').reverse().join('/')} 23:59`)).toJSON()
    }

    this.filtro.tipoRelatorio = tipo

    let { dataFinalTela, dataInicialTela, ...filtroEnxuto } = this.filtro

    this.relatorioService.pessoas(filtroEnxuto).subscribe(next => {
      this.downloadFile(next, tipo, 'Pessoas')
    }, (error) => {
      console.log(error)
      this.botaoClicado = false
    }, () => {
      this.botaoClicado = false
    })
  }

  RelatorioServicos(tipo: string, simplificado = false) {

    this.filtro.simplificado = simplificado

    this.botaoClicado = true

    this.filtro.dataInicial = ''

    if (this.filtro.dataInicialTela) {
      this.filtro.dataInicial = (new Date(this.filtro.dataInicialTela.split('-').reverse().join('/'))).toJSON()
    }

    this.filtro.dataFinal = ''
    if (this.filtro.dataFinalTela) {
      this.filtro.dataFinal = (new Date(`${this.filtro.dataFinalTela.split('-').reverse().join('/')} 23:59`)).toJSON()
    }

    this.filtro.tipoRelatorio = tipo

    let { dataFinalTela, dataInicialTela, ...filtroEnxuto } = this.filtro

    this.relatorioService.servicos(filtroEnxuto).subscribe(next => {
      this.downloadFile(next, tipo, 'Produtos')
    }, (error) => {
      console.log(error)
      this.botaoClicado = false
    }, () => {
      this.botaoClicado = false
    })
  }

  downloadFile(data: any, type: string, nome: string) {
    let responseType = type
    if (type == 'xls') {
      responseType = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
    const blob = new Blob([data], { type: `application/${responseType}` })
    var link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `Afrobiz${nome}${(new Date()).toLocaleDateString().replace(/\//g, '')}` //nome do arquivo
    link.click()
  }
}
