import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { AvisoDeslogadoComponent } from 'src/app/aviso-deslogado/aviso-deslogado.component';
import { InteresseComponent } from 'src/app/pessoa-externo/interesse/interesse.component';
import { AuthService } from 'src/app/service/auth.service';
import { AvaliarService } from 'src/app/service/avaliar.service';
import { FavoritoService } from 'src/app/service/favorito.service';
import { PessoaService } from 'src/app/service/pessoa.service';
import { ServicoService } from 'src/app/service/servico.service';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-destaque-servicos',
  templateUrl: './destaque-servicos.component.html',
  styleUrls: ['./destaque-servicos.component.css'],
})
export class DestaqueServicosComponent implements OnInit {
  @Input() tipo: any = 'Serviços';
  servicos: any = [];
  exibeFavoritos = true;

  templateAleatorio = parseInt(Math.random().toString().slice(2, 3)) > 1;

  estaLogado = false;
  favoritosList = [];

  constructor(
    private servicoService: ServicoService,
    private authService: AuthService,
    private favoritoService: FavoritoService,
    private modalService: NgbModal,
    private pessoaService: PessoaService,
    private avaliarService: AvaliarService,
    private router: Router,
    public menuService: MenuService
  ) {}

  ngOnInit(): void {
    this.estaLogado = this.authService.isLoggedIn();

    if (this.estaLogado) {
      this.exibeFavoritos = this.authService.getRole() == 'Pessoa';
      if (this.exibeFavoritos) {
        this.getFavoritosUsuario();
      }
    }

    let filter = {
      tipo: this.tipo[0].toLowerCase(),
    };

    this.servicoService.destaqueServicos(filter).subscribe((next) => {
      this.servicos = next.map((servico: any) => {
        let _favorito = false;
        this.favoritosList.find((fav: any) => {
          if (fav.id_servico == servico.id) {
            _favorito = true;
          }
        });

        let fotoCat;
        if (servico.Subcategorium.Categorium.nome == 'Afroempreendedores') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-afroempreendedores.svg';
        } else if (servico.Subcategorium.Categorium.nome == 'Baiana') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-baiana.svg';
        } else if (servico.Subcategorium.Categorium.nome == 'Capoeira') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-capoeira.svg';
        } else if (servico.Subcategorium.Categorium.nome == 'Arte & Cultura') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-cultura.svg';
        } else if (servico.Subcategorium.Categorium.nome == 'Flora') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-flora.svg';
        } else if (servico.Subcategorium.Categorium.nome == 'Gastronomia') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-gastronomia.svg';
        } else if (servico.Subcategorium.Categorium.nome == 'Moda & Beleza') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-moda_beleza.svg';
        } else if (servico.Subcategorium.Categorium.nome == 'Religião') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-religiao.svg';
        } else if (servico.Subcategorium.Categorium.nome == 'Turismo') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-turismo.svg';
        } else {
          fotoCat = 'assets/images/user.svg';
        }

        return {
          ...servico,
          //foto: servico.foto ? `${this.servicoService.getUrl()}images/${servico.foto}` : "assets/images/user.svg",
          foto: servico.foto || fotoCat,
          //foto_pessoa: servico.Pessoa.foto ? `${this.servicoService.getUrl()}images/${servico.Pessoa.foto}` : "assets/images/user.svg",
          foto_pessoa: servico.Pessoa.foto || 'assets/images/user.svg',
          youtube: servico.youtube
            ? `https://www.youtube.com/embed/${servico.youtube}`
            : '',
          favorito: _favorito,
        };
      });
    });
  }

  AddFavorite(id: any, event: any) {
    event.preventDefault();
    this.modalService.dismissAll();
    if (this.authService.isLoggedIn()) {
      this.servicos.map((ser: any) => {
        if (ser.id == id) {
          ser.favorito = !ser.favorito;
          if (ser.favorito) {
            this.favoritoService
              .add({
                id_usuario: this.authService.getUser().id,
                id_servico: id,
              })
              .subscribe();
          } else {
            this.favoritoService
              .delete({
                id_usuario: this.authService.getUser().id,
                id_servico: id,
              })
              .subscribe();
          }
        }
      });
    } else {
      this.clickAvisoDeslogado();
    }
  }

  clickAvisoDeslogado() {
    this.modalService.open(AvisoDeslogadoComponent);
  }

  verTodos() {
    console.log('implementar');
  }

  clickInteresse(servico: any, event: any) {
    forkJoin(
      this.pessoaService.getPessoa(servico.id_pessoa),
      this.avaliarService.getMedia({ id_pessoa: servico.id_pessoa })
    ).subscribe((res) => {
      const modalRef = this.modalService.open(InteresseComponent);
      modalRef.componentInstance.servicoModal = servico;
      let pessoa = res[0];
      //pessoa.templateFoto = pessoa.foto ? `${this.pessoaService.getUrl()}images/${pessoa.foto}` : "assets/images/produto.svg";
      pessoa.templateFoto = pessoa.foto || 'assets/images/produto.svg';
      modalRef.componentInstance.pessoa = pessoa;
      modalRef.componentInstance.id_pessoa = servico.id_pessoa;
      modalRef.componentInstance.avaliacaoMedia = res[1][0];
    });
  }

  getFavoritosUsuario() {
    let filter = {
      id_usuario: this.authService.getUser().id,
    };

    this.favoritoService.list(filter).subscribe((next) => {
      this.favoritosList = next;
    });

    // this.favoritoService.list(filter).subscribe(next => {
    //   this.servicos.map((destaque: any) => {
    //     next.map((favorito: any) => {
    //       if (favorito.id_servico == destaque.id) {
    //         destaque.favorito = true;
    //       }
    //     });
    //   })

    // })
  }

  irParaPessoa(idPessoa: any) {
    this.router.navigate(['detalhe-pessoa'], {
      queryParams: { pes: idPessoa },
    });
  }

  irParaTodos(titulo: string) {
    var filter = this.tipo[0];
    this.router.navigate(['sp'], { queryParams: { tp: filter, titulo } });
  }
}
