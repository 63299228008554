import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from 'src/app/login/login.component';
import { PrecadastroComponent } from 'src/app/precadastro/precadastro.component';

@Component({
  selector: 'app-aviso-deslogado',
  template: `

<div class="modal-acesse">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content text-center">

            <div class="modal-login">
                <div class="modal-header">
                    <div class="text-center w-100">                        
                        <div class="logo"></div>
                    </div>
                    <button type="button" class="close" aria-label="Close"
                        (click)="activeModal.dismiss('Cross click')">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="text-center">

                        <h4>Para esta ação é necessário estar logado.</h4>

                        <button type="button" class="btn btn-padrao btn-lg" (click)="IrParaLogin()">
                            <i class="fas fa-check"></i>
                            Acessar
                        </button>

                    </div>
                </div>
                <div class="modal-footer">
                    <p>Ainda não faz parte do TEA? <a [routerLink]="" (click)="IrParaCadastro()" style="cursor: pointer;"><strong>Cadastre-se!
                            </strong></a></p>
                </div>
            </div>
        </div>
    </div>
</div>
  `
})
export class AvisoDeslogadoComponent {
  @Input() modalRef: any;
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) { }

  closeModal() {
    this.modalRef.close();
  }

  IrParaCadastro() {
    this.activeModal.close();
    this.modalService.open(PrecadastroComponent);
  }

  IrParaLogin() {
    this.activeModal.close();
    this.modalService.open(LoginComponent);
  }

}
