<div class="modal-content modal-dicas-cadastro">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Como se cadastrar no Afrobiz Salvador</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal('fecha')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <iframe width="" height="" src="https://www.youtube.com/embed/FC3XU3f3s0s?controls=0"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
    </div>
</div>