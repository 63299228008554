<div class="conteudo">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" routerLink="/home">Página inicial </li>
      <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
    </ol>
  </nav>
  <h2>Dashboard</h2>

  <div class="dashboard pagina">
    <h3>Filtros</h3>
    <div class="row flex-wrap mb-5 border-bottom  pb-2">
      <div class="col-sm-3 col-xs-12">
        <div class="form-group">
          <label for="">Data inicial:</label>
          <input [(ngModel)]="filtro.dataInicialTela" class="form-control" ngbDatepicker #d="ngbDatepicker"
            [minDate]="{year: 1900, month: 1, day: 1}" placeholder="dd/mm/aaa"
            [maxDate]="{year: currentDate.year, month: 12, day: 31}" (click)="d.toggle()">
        </div>
      </div>

      <div class="col-sm-3 col-xs-12">
        <div class="form-group">
          <label for="">Data final:</label>
          <input [(ngModel)]="filtro.dataFinalTela" class="form-control" ngbDatepicker #d2="ngbDatepicker"
            [minDate]="{year: 1900, month: 1, day: 1}" placeholder="dd/mm/aaa"
            [maxDate]="{year: currentDate.year, month: 12, day: 31}" (click)="d2.toggle()">
        </div>
      </div>
      <div class="col-sm-3 pt-4">
        <button type="button" class="btn btn-transparent" (click)="filtrar()">
          <i class="fas fa-filter"></i>
          filtrar
        </button>
      </div>
    </div>

    <div class="dashboard-counter row flex-wrap">
      <div class="col-md-6 col-lg-3 col-xs-12">
        <div class="item">
          <label>Total de cadastrados</label>
          <strong>{{indicadores.totalCandidatos}}</strong>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 col-xs-12">
        <div class="item">
          <label>Quantidade de produtos</label>
          <strong>{{indicadores.quantidadeProdutos}}</strong>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 col-xs-12">
        <div class="item">
          <label>Quantidade de serviços</label>
          <strong>{{indicadores.quantidadeServicos}}</strong>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 col-xs-12">
        <div class="item">
          <label>Total de comentários</label>
          <strong>{{indicadores.totalComentarios}}</strong>
        </div>
      </div>
    </div>

    <div class="panel">
      <h6>Adesão à plataforma AFROBIZ</h6>
      <google-chart *ngIf="adesaoDado" [type]="graficos.adesao.type" [data]="graficos.adesao.data" style="width: 100%;"
        [dynamicResize]="true" [options]="optionsAdesao">
      </google-chart>
      <p *ngIf="!adesaoDado" style="text-align: center; font-size: 9pt;">Nenhum dado</p>
    </div>

    <div class="row flex-wrap">
      <div class="col-lg-6 col-md-12">
        <div class="panel">
          <h6>Quantidade de produtos cadastrados</h6>
          <google-chart [type]="graficos.quantidadeProdutos.type" [data]="graficos.quantidadeProdutos.data"
            [columns]="graficos.quantidadeProdutos.columns" [height]="400" style="width: 100%;" [dynamicResize]="true"
            [options]="{legend: 'left', chartArea: {width: '100%'}}">
          </google-chart>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="panel">
          <h6>Quantidade de serviços cadastrados</h6>
          <google-chart [type]="graficos.quantidadeServicos.type" [data]="graficos.quantidadeServicos.data"
            [columns]="graficos.quantidadeServicos.columns" [height]="400" style="width: 100%;" [dynamicResize]="true"
            [options]="{legend: 'left', chartArea: {width:'100%'}}">
          </google-chart>
        </div>
      </div>
    </div>

  </div>
</div>
