<section class="produtos-servicos destaque-blocos recentes">
    <div class="center" *ngIf="estaLogado && ultimosVistos.length > 0">
        <h2>Recentes</h2>
        <div class="grid">
            <ul class="list coluna">
                <li class="card" *ngFor="let servico of ultimosVistos">
                    <a (click)="clickInteresse(servico, $event)" style="cursor: pointer;"></a>
                    <div class="user menor" (click)="irParaPessoa(servico.id_pessoa)">
                        <img src="{{servico.foto_pessoa}}" alt="">
                    </div>
                    <!-- <div class="like" (click)="AddFavorite(servico.id, $event)" *ngIf="exibeFavoritos">
                        <input type="checkbox" class="custom-control-input" [checked]='servico.favorito'
                            id="{{'chk'+servico.id}}" >
                        <label class="custom-control-label" for="{{'chk'+servico.id}}">
                            <i class="far fa-heart"></i>
                        </label>
                    </div> -->
                    <img src="{{servico.foto}}" alt="">

                    <div class="card-footer">
                        <div class="texto">
                            <h3>{{servico.nome}}</h3>
                            <p>{{servico.descricao}}</p>
                        </div>
                        <div class="info">
                            <strong *ngIf="servico.valor == 0"> </strong>
                            <strong *ngIf="servico.valor != 0">{{servico.valor | currency: 'BRL'}}</strong>
                            <app-avaliacao-estrela [pesId]="servico.id_pessoa">
                            </app-avaliacao-estrela>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</section>
<!-- <section class="destaques bg-cinza">
    <div class="top barra-titulo">
        <div class="center">
            <h2>Destaques</h2>
        </div>
    </div>
    <div class="lista-cards center">
        <div class="swiper-cards">
            <swiper [slidesPerView]="4" [spaceBetween]="10" [navigation]="true" [breakpoints]='swiperbreakpoints'>
                <ng-template swiperSlide *ngFor="let destaque of destaques">
                    <div class="card">
                        <div class="like">
                            <input type="checkbox" class="custom-control-input" [checked]='destaque.favorito'
                                id="{{'chk'+destaque.id_pessoa}}" (click)="AddFavorite(destaque.id_pessoa, $event)">
                            <label class="custom-control-label" for="{{'chk'+destaque.id_pessoa}}">
                                <i class="far fa-heart"></i>
                            </label>
                        </div>
                        <img src="{{destaque.Pessoa.templateFoto}}" alt="">

                        <div class="card-body">
                            <h5 class="card-title">{{destaque.Pessoa.nome}}</h5>
                            <p class="card-text">{{destaque.Pessoa.autobiografia}}</p>
                        </div>
                        <div class="card-footer">

                            <div class="flex">
                                <span><i class="fas fa-eye"></i></span>
                            </div>
                            <app-avaliacao-estrela pesId={{destaque.id_pessoa}}></app-avaliacao-estrela>
                            <!-- <div class="flex">

                                <span><i class="fas fa-eye"></i> (999.999.999</span>
                            </div>
                            <div class="rank">
                                <span class="star active">
                                    <i class="fas fa-star"></i>
                                </span>
                                <span class="star active">
                                    <i class="fas fa-star"></i>
                                </span>
                                <span class="star active">
                                    <i class="fas fa-star"></i>
                                </span>
                                <span class="star">
                                    <i class="fas fa-star"></i>
                                </span>
                                <span class="star">
                                    <i class="fas fa-star"></i>
                                </span>
                            </div> -->
<!--</div>
</div>
</ng-template>
</swiper>
</div>
<div class="destaque-footer">
    <button type="button" class="btn btn-transparent" (click)="vejaMais()">
        <i class="fas fa-plus"></i>
        VEJA MAIS
    </button>
</div>
</div>
<div *ngIf="estaLogado && ultimosVistos.length > 0">
    <h4>Visto recentemente</h4>
    <div class="lista-cards center">
        <div class="swiper-cards">
            <swiper [slidesPerView]="4" [spaceBetween]="10" [navigation]="true" [breakpoints]='swiperbreakpoints'>
                <ng-template swiperSlide *ngFor="let item of ultimosVistos">
                    <div class="card">
                        <img src="{{item.Pessoa.templateFoto}}" alt="">
                        <div class="card-body">
                            <h5 class="card-title">{{item.Pessoa.nome}}</h5>
                            <p class="card-text">{{item.Pessoa.autobiografia}}</p>
                        </div>
                    </div>
                </ng-template>
            </swiper>
        </div>
    </div>
</div>
</section> -->
