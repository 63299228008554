<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">AFROBIZ - Termos de Uso</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal('fecha')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body scrollbar-inner">
    <h5>1. ACEITAÇÃO.</h5>
    <p>Bem-vindo / Bem-vinda ao site Afrobiz Salvador (<a href="https://afrobizsalvador.com.br"
        target="_blank">www.afrobizsalvador.com.br</a>). O presente documento estabelece os
      termos e condições aplicáveis ao uso do site. Por favor, revise os termos de uso cuidadosamente antes de utilizar
      o site. Ao acessar, navegar ou utilizar o site ("uso"), todos os usuários e visitantes ("Você", "Vocês," "Usuário"
      ou "Usuários") declaram aceitar os termos e condições contidos nestes termos de uso. Caso você não concorde com os
      termos e condições contidos neste documento, por favor, não use o site. A Secretaria Municipal de Cultura e
      Turismo da Prefeitura de Salvador (SECULT) reserva-se o direito de atualizar os termos de uso periodicamente, ao
      seu exclusivo critério. Você deve revisar os termos de uso periodicamente para eventuais atualizações e
      modificações.</p>

    <h5>2. ELEGIBILIDADE.</h5>
    <p>O Site e todos os serviços a ele relacionados são disponibilizados somente se você puder celebrar contratos
      legalmente válidos sob a legislação aplicável. O Site não está disponível para menores de 18 anos. Caso você seja
      menor de 18 anos, por favor, não use o Site. Ao usar o site, você declara que tem capacidade jurídica plena, sendo
      maior de 18 anos de idade.</p>

    <h5>3. LICENÇA.</h5>
    <p>A SECULT concede a você uma licença limitada, não-exclusiva, intransferível, revogável e não passível de cessão,
      autorizando-o a visualizar e realizar downloads temporários de uma cópia dos materiais exibidos no Site, com o
      objetivo de promover e divulgar produtos e serviços oferecidos pelos atores do Turismo Étnico Afro ("TEA") de
      Salvador, estimulando seu protagonismo econômico. Todos os materiais exibidos ou disponibilizados no Site,
      incluindo, sem limitação, gráficos, documentos, textos, sons, vídeo, áudio, obras de arte, software e código HTML
      (coletivamente, o "Material") são de propriedade exclusiva da Secult ou de seus fornecedores de conteúdo, os quais
      reservam a si todos os seus direitos de propriedade intelectual. Os materiais são protegidos pelas leis de
      direitos autorais e outras regras, regulamentos e legislações brasileiras e internacionais de propriedade
      intelectual aplicáveis. Exceto conforme permitido no presente instrumento, você não poderá (i) usar, copiar,
      modificar, exibir, apagar, distribuir, realizar download, arquivar, transmitir, publicar, vender, revender,
      adaptar, realizar engenharia reversa ou criar obras derivadas do Material, ou (ii) usar o Material em outros
      websites ou em quaisquer outros tipos de mídia, como, por exemplo, em ambientes de rede, sem o consentimento
      prévio e por escrito da Secult.</p>
    <p>Todas as marcas comerciais, marcas de serviços e logotipos (as "Marcas") exibidas no Site são de propriedade
      exclusiva da SECULT ou de seus respectivos titulares. Você não poderá usar as Marcas, de nenhuma forma, sem o
      consentimento prévio e por escrito da SECULT e de seus respectivos titulares.</p>

    <h5>4. SUBMISSÕES DO USUÁRIO.</h5>
    <p>O Site fornece um espaço para que os atores do TEA forneçam suas informações para serem divulgadas para possíveis
      interessados na contratação dos seus serviços. Ao submeter, enviar ou validar suas informações no Site ("Material
      Submetido"), você concorda que concede à SECULT uma licença perpétua, não-exclusiva, irrevogável, gratuita e
      mundial, e o direito (mas não uma obrigação) de usar, copiar, modificar, exibir, distribuir, realizar download,
      armazenar, reproduzir, transmitir, publicar, adaptar e criar obras derivadas, de qualquer maneira e em qualquer
      tipo de mídia, do todo ou de parte do Material Submetido, sob qualquer forma e em qualquer parte do mundo sem
      limite de prazo.</p>
    <p>Você reconhece e concorda que a SECULT fornece apenas um canal passivo de divulgação dos serviços anunciados. A
      SECULT não filtra nem monitora nenhum Material Submetido fornecido por você ou por outros usuários do Site. Como
      consequência, a SECULT não fornece qualquer garantia quanto à confiabilidade, precisão, completude, validade ou
      veracidade de qualquer Material Submetido ou enviado por quaisquer usuários do website, incluindo quaisquer
      ofertas de serviços, currículos ou dados biográficos. A SECULT se reserva o direito, ao seu exclusivo critério, de
      apagar, remover, recusar-se a exibir ou bloquear qualquer Material Submetido que considere ser inaceitável, ao seu
      exclusivo critério, sem que a tenha qualquer responsabilidade perante você ou qualquer outro usuário do Site. Caso
      a SECULT receba uma notificação a respeito de quaisquer Materiais Submetidos considerados inaceitáveis, poderá
      investigar tais materiais, a seu exclusivo critério.</p>
    <p>Você reconhece e concorda que a SECULT poderá reter cópias de seus Materiais Submetidos e divulgá-los a qualquer
      terceiro caso acredite ser necessário para: (i) proteger a integridade do Site; (ii) proteger direitos da SECULT;
      (iii) cumprir com uma ordem judicial; (iv) cumprir com quaisquer procedimentos legais; (v) fundamentar uma
      reclamação da SECULT sob estes Termos de Uso; e (vi) atender a quaisquer reclamações de violações de direitos de
      terceiros.</p>

    <h5>5. PROIBIÇÕES GERAIS.</h5>
    <p>Com relação ao seu Uso, você concorda em:</p>
    <p>1. <strong>não</strong> violar quaisquer regras, regulamentos ou legislações aplicáveis, incluindo, sem
      limitação, legislações e regulamentos de exportação, leis contra discriminação ou quaisquer outras. Além disso,
      você concorda em não exportar os Materiais a qualquer país ou jurisdição que esteja incluído na lista de países
      proibidos ou sob restrição conforme as leis de exportação do Brasil;</p>
    <p>2. <strong>não</strong> violar quaisquer direitos de propriedade intelectual ou de privacidade, incluindo, sem
      limitação, patentes, direitos autorais, marcas ou segredos comerciais de quaisquer terceiros, incluindo a SECULT;
    </p>
    <p>3. <strong>não</strong> carregar, publicar, transmitir ou armazenar qualquer material que:</p>
    <p>a) seja ilegal, ofensivo, difamatório, fraudulento, enganoso, danoso, ameaçador, constrangedor, obsceno ou
      inaceitável;</p>
    <p>b) viole quaisquer de suas obrigações contratuais ou de confidencialidade;</p>
    <p>c) perturbe ou interfira nas operações normais do Site, tais como a publicação ou a transmissão de vírus, a
      publicação continuada de materiais repetidos ou a publicação de arquivos muito grandes, fora do tamanho normal; ou
    </p>
    <p>d) não seja permitido pela SECULT, incluindo, sem limitação, quaisquer materiais de publicidade não autorizada,
      materiais de propaganda não solicitada, "junk mail", mensagens de spam, cartas em "corrente", esquemas de
      pirâmide, franquias, distribuição, associação em clubes, acordos de venda ou outros materiais não aceitos;</p>
    <p>e) viole direitos de privacidade ou outros direitos de terceiros através do uso abusivo de Materiais, incluindo,
      sem limitação, através do constrangimento ou "perseguição" contra outra pessoa, do envio de mensagens de e-mail
      não solicitadas ou da coleta de informações pessoais de terceiros;</p>
    <p>f) viole ou tente violar quaisquer medidas de segurança do Site;</p>
    <p>g) use quaisquer dispositivos, processos ou mecanismos para monitorar, recuperar, pesquisar ou acessar dados do
      Site ou qualquer Material sem o consentimento prévio e por escrito da Secult, através, por exemplo, de robôs ou
      sistemas de varredura e armazenamento de dados ("spider");</p>
    <p>h) acesse ou tente acessar qualquer conta ou identificação de terceiros contida no Site;</p>
    <p>i) copie, modifique, reproduza, apague, distribua, realize download, armazene, transmita, venda, revenda,
      publique, realize engenharia reversa ou crie obras derivadas de quaisquer Materiais, exceto materiais que tenham
      sido submetidos por você e sejam de sua propriedade;</p>
    <p>j) publique ou submeta qualquer informação imprecisa, falsa ou incompleta, incluindo currículo, informações
      biográficas ou informações de emprego;</p>
    <p>l) se passe por outra pessoa ou organização;</p>
    <p>m) adultere qualquer informação de cabeçalho em uma publicação ou mensagem eletrônica; ou</p>
    <p>n) forneça falsa informação sobre você, sua afiliação junto a um terceiro ou sua organização.</p>

    <h5>6. USOS ESPECÍFICOS E RESPONSABILIDADES.</h5>
    <p>Além das responsabilidades gerais previstas na Cláusula 5, você concorda em cumprir com os seguintes termos.</p>
    <p>Você concorda ainda em:</p>
    <p>1. usar o Site somente para finalidades legítimas a fim de divulgar ou pesquisar serviços dos atores do TEA;</p>
    <p>2. fornecer e manter as informações submetidas por você completas, corretas, atualizadas e precisas;</p>
    <p>3. publicar materiais sobre os quais você tenha todos os direitos e licenças necessários;</p>
    <p>4. utilizar seu próprio discernimento, precaução e senso comum para gerenciamento dos serviços dos atores do TEA
      divulgados através do Site; e</p>
    <p>5. assumir os riscos de qualquer utilização de (ou confiança em) quaisquer Materiais ou informações fornecidas
      por um terceiro.</p>

    <h5>7. CONTA E SENHA.</h5>
    <p>O Site poderá apresentar a você a oportunidade de abrir uma conta de usuário junto ao Site. Ao abrir uma conta
      junto ao Site, você poderá receber uma identificação de conta (logon) e uma senha inicial. É de sua
      responsabilidade (a) manter o sigilo de sua identificação de conta e senha; (b) atualizar e revisar sua conta
      frequentemente; e (c) avisar prontamente a SECULT caso ocorra qualquer utilização não autorizada de sua conta ou
      qualquer quebra de segurança. Você é responsável também por todas as atividades que forem realizadas através de
      sua conta e a SECULT não será responsável por qualquer perda ou dano resultante de sua falha em cumprir com as
      obrigações previstas neste parágrafo.</p>

    <h5>8. CONTRATAÇÃO DOS SERVIÇOS.</h5>
    <p>Você entende e concorda que a SECULT não garante que você receberá quaisquer propostas de contratação dos
      serviços eventualmente oferecidos através do Site; (b) não será responsável por quaisquer ofertas de trabalho,
      filtragem, decisões e contratações efetivas apresentados por terceiros; (c) não garante a precisão, completude,
      validade ou pontualidade de informações fornecidas por quaisquer terceiros; (d) não será responsável por quaisquer
      materiais publicados por terceiros; e (e) não é ou será seu empregador nem seu contratante. Você utilizará seu
      próprio discernimento, precaução e senso comum para a avaliação a respeito de potenciais contratantes e
      informações fornecidas por terceiros.</p>

    <h5>9. LINKS.</h5>
    <p>O Site poderá apresentar links, tais como hyperlinksou botões, direcionando o acesso a websites de terceiros
      ("Sites Ligados"). Os Sites Ligados poderão não ser controlados ou monitorados pela SECULT. A SECULT não será
      responsável pelos materiais, informações ou conteúdo publicados em Sites Ligados, nem tampouco pela
      disponibilidade destes. A inclusão de Sites Ligados no Site não implica em nenhum relacionamento ou associação
      entre a SECULT e o proprietário de Sites Ligados, nem tampouco quaisquer endosso ou apoio ou patrocínio pela
      SECULT dos Sites Ligados. A SECULT inclui os Sites Ligados apenas para sua conveniência. Você é exclusivamente
      responsável por seu acesso aos Sites Ligados. Você utilizará seu próprio discernimento, precaução e senso comum na
      utilização de Sites Ligados, devendo conferir as políticas de privacidade e os termos de uso aplicáveis de tais
      Sites Ligados, os quais poderão ser diferentes dos termos contidos nestes Termos de Uso e na Política de
      Privacidade da SECULT. A SECULT não aceita qualquer responsabilidade por tais políticas de terceiros.</p>

    <h5>10. PRIVACIDADE.</h5>
    <p>Ao aceitar estes termos de uso, você automaticamente aceita todos os termos da política de privacidade da SECULT
      estabelecidos abaixo e, neste ato, autoriza a SECULT a coletar, armazenar, utilizar e divulgar seus dados pessoais
      conforme previsto em tal documento.</p>

    <h5>11. COOKIES.</h5>
    <p>Um cookie é um pequeno pedaço de dados (arquivo de texto) que um site - quando visitado por um usuário - solicita
      a seu navegador para armazenar em seu dispositivo, para lembrar informações sobre você, como sua preferência de
      idioma ou informações de login. Esses cookies são definidos por nós e são chamados de cookies primários. Também
      usamos cookies de terceiros - que são cookies de um domínio diferente do domínio do site que você está visitando -
      para os nossos esforços de publicidade e marketing. Mais especificamente, usamos cookies e outras tecnologias de
      rastreamento para os seguintes fins:</p>
    <h6>Cookies de publicidade</h6>
    <p>Estes cookies podem ser estabelecidos através do nosso site pelos nossos parceiros de publicidade. Podem ser
      usados por essas empresas para construir um perfil sobre os seus interesses e mostrar-lhe anúncios relevantes em
      outros websites. Eles não armazenam diretamente informações pessoais, mas são baseados na identificação exclusiva
      do seu navegador e dispositivo de internet. Se não permitir estes cookies, terá menos publicidade direcionada.</p>
    <h6>Cookies das redes sociais</h6>
    <p>Estes cookies são estabelecidos por uma série de serviços das redes sociais que adicionamos ao website para
      permitir que possa partilhar o nosso conteúdo com os seus amigos e conhecidos. Eles são capazes de rastrear a sua
      navegação por outros websites e criar um perfil sobre os seus interesses. Isso pode afetar o conteúdo e as
      mensagens que vê noutros websites que visita. Se não permitir estes cookies, talvez não consiga usar ou ver essas
      ferramentas de partilha.</p>
    <h6>Cookies de desempenho</h6>
    <p>Estes cookies permitem-nos contar visitas e fontes de tráfego, para que possamos medir e melhorar o desempenho do
      nosso website. Eles ajudam-nos a saber quais são as páginas mais e menos populares e a ver como os visitantes se
      movimentam pelo website. Todas as informações recolhidas por estes cookies são agregadas e, por conseguinte,
      anônimas. Se não permitir estes cookies, não saberemos quando visitou o nosso site.</p>
    <h6>Cookies estritamente necessários</h6>
    <p>Estes cookies são necessários para que o website funcione e não podem ser desligados nos nossos sistemas.
      Normalmente, eles só são configurados em resposta a ações levadas a cabo por si e que correspondem a uma
      solicitação de serviços, tais como definir as suas preferências de privacidade, iniciar sessão ou preencher
      formulários. Pode configurar o seu navegador para bloquear ou alertá-lo sobre esses cookies, mas algumas partes do
      website não funcionarão. Estes cookies não armazenam qualquer informação pessoal identificável.</p>

    <h5>12. INDENIZAÇÃO.</h5>
    <p>Você concorda em defender e isentar a Secult, bem como seus diretores, representantes, funcionários, empresas
      terceirizadas e fornecedores, contra quaisquer reclamações ou ações de quaisquer terceiros, incluindo, sem
      limitação, honorários advocatícios e custas judiciais, resultantes ou originadas do seu Uso do Site, de seus
      Materiais Submetidos ou de sua violação dos termos e condições contidos nestes Termos de Uso.</p>

    <h5>13. ISENÇÃO</h5>
    <p>Você reconhece e concorda que: (a) você assume todos os riscos relacionados ou resultantes do seu uso,
      visualização ou acesso ao site. Na máxima extensão permitida pela legislação aplicável, este site é oferecido no
      estado em que se encontra e tão somente conforme disponibilizado; (b) na máxima extensão permitida pela legislação
      aplicável, a SECULT se isenta expressamente de todas e quaisquer garantias, de qualquer natureza, sejam elas
      expressas ou implícitas, incluindo, sem limitação, garantias implícitas de comerciabilidade, adequação a uma
      finalidade específica e não violação ou garantias originadas do curso do desempenho, relacionamento comercial ou
      costumes. (c) na máxima extensão permitida pela legislação aplicável, a SECULT se isenta expressamente de
      quaisquer garantias no sentido de que (i) o site e os materiais serão livres de erros ou vírus; (ii) o site
      operará ininterruptamente e de forma segura; (iii) o site operará ininterruptamente e estará disponível em todo
      momento; (iv) o site atenderá a suas necessidades; e (v) quaisquer materiais submetidos serão confiáveis,
      precisos, completos, válidos e verdadeiros.</p>

    <h5>14. RESPONSABILIDADE E LIMITAÇÕES DE RESPONSABILIDADE.</h5>
    <p>Na máxima extensão permitida pela legislação aplicável, você concorda em assumir todos os riscos associados ou
      relacionados ao seu uso do site ou de quaisquer materiais submetidos, incluindo, sem limitação, riscos de perdas
      financeiras, danos físicos, danos materiais, relacionamento com outros usuários do site, estranhos, menores ou
      estrangeiros, e pessoas agindo sob falsa identidade. você concorda ainda em isentar a SECULT e suas empresas
      controladoras, subsidiárias e afiliadas, bem como seus diretores, representantes e empregados, contra quaisquer
      reclamações, ações, danos (diretos, indiretos e consequenciais) de qualquer tipo ou natureza, conhecidos ou não,
      associados, originados ou resultantes de seu uso do site, de seus materiais submetidos ou de quaisquer transações
      relacionadas ou resultantes de seu uso do site.</p>
    <p>Você entende e concorda ainda que, na máxima extensão permitida pela legislação aplicável, em hipótese alguma a
      SECULT, empresas terceirizadas, diretores, representantes, funcionários ou fornecedores serão responsáveis por
      quaisquer danos diretos, indiretos, consequenciais, incidentais, especiais, ou por perda de fundo de comércio, de
      receita, dados ou utilização, sofridos por você ou por qualquer terceiro, seja em razão de violação contratual,
      ato ilícito ou violação de garantia, ainda que a SECULT tenha sido advertida da possibilidade de tais danos. Caso
      alguma jurisdição proíba a exclusão de determinadas garantias, a limitação de responsabilidade ou a renúncia de
      determinados tipos de danos, a responsabilidade agregada da SECULT por quaisquer danos, na máxima extensão
      permitida pela legislação aplicável, não será superior ao valor de R$ 50,00 (cinquenta reais).</p>

    <h5>15. RESCISÃO.</h5>
    <p>A SECULT poderá encerrar, ao exclusivo critério da Secretaria, quaisquer serviços oferecidos pelo Site e remover
      quaisquer Materiais nele publicados. A SECULT poderá também rescindir o seu acesso a qualquer parte ou a todos os
      serviços oferecidos pela Secult a qualquer momento, com ou sem justa causa ou notificação prévia, e por qualquer
      razão ou motivo. Caso você deseje terminar sua conta, você poderá apenas cessar seu uso do Site. A SECULT não será
      responsável por manter ou devolver seus Materiais Submetidos, sua conta ou sua identificação e senha. Você deverá
      sempre manter uma cópia de seus Materiais Submetidos.</p>

    <h5>16. DISPOSIÇÕES GERAIS.</h5>
    <p>Você concorda que não se constitui qualquer relação de emprego, parceria, representação, agência ou joint venture
      entre você e a SECULT com resultado do seu Uso do Site. Estes Termos de Uso constituem o acordo integral entre
      você e a SECULT com relação ao seu Uso do Site, sendo adicional a qualquer contrato celebrado entre você e a
      SECULT. Estes Termos de Uso são regidos pelas leis da República Federativa do Brasil. Você não poderá usar ou
      exportar quaisquer Materiais ou realizar qualquer cópia ou adaptação em violação a quaisquer leis, regulamentos ou
      dispositivos aplicáveis, incluindo, sem limitação, as leis e regulamentos de exportação do Brasil. A esse
      respeito, você concorda em não exportar os Materiais a qualquer país ou jurisdição que esteja incluído na lista de
      países proibidos ou sob restrição conforme as leis de exportação do Brasil. A falha ou o atraso por qualquer parte
      na execução de qualquer termo condito nestes Termos de Uso não serão considerados como uma renúncia a qualquer
      destes termos. As Cláusulas 4, 5, 6, 7, 9, 10, 12, 13, 14 e 15 sobreviverão ao término destes Termos de Uso por
      qualquer motivo ou razão.</p>

    <span style="font-size: 9pt; float: right;"><strong>Última atualização 01/07/2021.</strong></span>
  </div>
  <div class="modal-footer">
    <button *ngIf="tela == 'pessoa'" type="button" class="btn btn-transparent" (click)="closeModal('aceita')">
      <i class="fas fa-arrow-right"></i>
      ACEITAR
    </button>
    <button *ngIf="tela == 'footer'" type="button" class="btn btn-transparent" (click)="closeModal('fecha')">
      <i class="fas fa-arrow-right"></i>
      OK
    </button>
  </div>
</div>
