import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EmailService } from '../service/email.service';

@Component({
  selector: 'app-suporte',
  templateUrl: './suporte.component.html',
  styleUrls: ['./suporte.component.css']
})
export class SuporteComponent implements OnInit {

  isSubmited = false;

  form: any = {}

  assuntoList = [{ id: 0, descricao: 'Selecione o assunto' }, { id: 1, descricao: 'Problema no site' }, { id: 2, descricao: 'Navegação' }]

  setorList = [{ id: 0, descricao: 'Selecione o setor' }, { id: 1, descricao: 'Suporte' }]

  constructor(
    private emailService: EmailService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.form.assunto = 0
    this.form.setor = 0
  }

  limpar() {
    this.form = {}
  }

  enviar() {

    this.isSubmited = true
    if (!this.validateField()) {
      return;
    }

    if (this.form.assunto == 0 || this.form.setor == 0) {
      this.toastr.error('Por favor, preencha os campos')
      return
    }

    let _assunto = this.assuntoList.filter(x => x.id == this.form.assunto)[0].descricao
    let _setor = this.setorList.filter(x => x.id == this.form.setor)[0].descricao

    let filter = {
      subject: this.setorList[this.form.setor].descricao,
      dados: {
        assunto: _assunto,
        setor: _setor,
        descricao: this.form.descricao,
        nome: this.form.nome,
        telefone: this.form.telefone,
        email: this.form.email
      }
    }

    this.emailService.sendMail(filter).subscribe((next: any) => {
      this.toastr.success('Seu e-mail foi enviado!');
      this.isSubmited = false;
      this.limpar();
    }, (error: any) => {
      this.toastr.error('Não foi possivel enviar o email');
    });
  }

  isFieldValid(field: any) {
    return this.isSubmited && !field
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    }
  }

  validateField() {
    let fields = [
      'nome',
      'email',
      'telefone',
      'setor'
    ]

    for (const iterator of fields) {
      if (!this.form[iterator]) {
        return false
      }
    }

    return true
  }

}
