import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { AvaliarService } from 'src/app/service/avaliar.service';
import { FavoritoService } from 'src/app/service/favorito.service';
import { HistoricoService } from 'src/app/service/historico.service';
import { PessoaService } from 'src/app/service/pessoa.service';
import { AvisoDeslogadoComponent } from '../../aviso-deslogado/aviso-deslogado.component';
import { InteresseComponent } from '../interesse/interesse.component';
import { environment } from '.env';

@Component({
  selector: 'app-pessoa-externo-pesquisa',
  templateUrl: './pessoa-externo-pesquisa.component.html',
  styleUrls: ['./pessoa-externo-pesquisa.component.css']
})
export class PessoaExternoPesquisaComponent implements OnInit {

  URL_WHATSAPP = environment.URL_WHATSAPP;
  qtdPaginas: any;
  qtdItensPorPagina = 9;
  paginaAtual = 1;
  list: any;
  categoriaList: any;
  termoPessoa = "";
  termoServico = "";
  qtdResultado = 0;
  filter: any;
  favoritoList = [];
  idSubcategoria = "";
  servicoModal: any;
  pesId = "";
  pesquisaGeral = ""

  constructor(
    private route: ActivatedRoute,
    private pessoaService: PessoaService,
    private router: Router,
    private authService: AuthService,
    private favoritoService: FavoritoService,
    private modalService: NgbModal,
    private avaliarService: AvaliarService,
    private historicoService: HistoricoService
  ) {
    this.filter = {
      offset: 0,
      limit: 0,
      order: 'ASC'
    };

    this.list = {
      rows: []
    }
  }

  ngOnInit(): void {
    this.termoPessoa = this.route.snapshot.queryParamMap.get('pes') || "";
    this.termoServico = this.route.snapshot.queryParamMap.get('serv') || "";
    this.idSubcategoria = this.route.snapshot.queryParamMap.get('sub') || "";
    this.idSubcategoria = this.route.snapshot.queryParamMap.get('cat') || "";
    this.pesquisaGeral = this.route.snapshot.queryParamMap.get('all') || "";

    this.pesId = this.route.snapshot.queryParamMap.get('pesId') || "";

    if (this.authService.isLoggedIn()) {
      this.favoritoService.list({ id_usuario: this.authService.getUser().id }).subscribe(next => {
        this.favoritoList = next;
      })

      if (this.pesId) {
        this.addVistoRecentemente();
      }
    }

    this.fillList(1);

  }

  fillList(pagina: number) {

    this.paginaAtual = pagina;

    this.filter.offset = (pagina - 1) * this.qtdItensPorPagina;
    this.filter.limit = this.qtdItensPorPagina;

    if (this.termoPessoa) {
      this.filter.pessoa = this.termoPessoa;
    }

    if (this.termoServico) {
      this.filter.servico = this.termoServico;
    }

    if (this.idSubcategoria) {
      this.filter.id_subcategoria = this.idSubcategoria;
    }

    if (this.pesId) {
      this.filter.id_pessoa = this.pesId;
    }

    this.pessoaService.searchReturnList(this.filter).subscribe((next) => {
      this.list = next;
      this.list.rows = next.rows.map((el: any) => {
        //el.templateFoto = el.foto ? `${this.pessoaService.getUrl()}images/${el.foto}` : "assets/images/produto.svg";
        el.templateFoto = el.foto || "assets/images/produto.svg";
        el.favorito = false;
        this.favoritoList.map((fav: any) => {
          if (el.id == fav.id_pessoa || el.id == fav.id_servico) {
            el.favorito = true
          }
        });
        return el;
      });
      this.list = next;
      const qtdTotalPaginas = Math.ceil(this.list.count / this.qtdItensPorPagina);
      this.list = { ...this.list, qtdTotalPaginas };
      this.qtdPaginas = [];
      this.qtdPaginas.push(pagina);
      let lastcount = 0;
      while (this.qtdPaginas.length != lastcount && this.qtdPaginas.length < 5) {
        lastcount = this.qtdPaginas.length;
        this.qtdPaginas.unshift(this.qtdPaginas[0] - 1);
        this.qtdPaginas.push(this.qtdPaginas[this.qtdPaginas.length - 1] + 1);
        this.qtdPaginas = this.qtdPaginas.filter(function (el: any) {
          return el > 0;
        })
        this.qtdPaginas = this.qtdPaginas.filter(function (el: any) {
          return el <= qtdTotalPaginas;
        })
      }
    })
  }

  resultadoBusca(subid: any) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(["resultado-da-busca2"], { queryParams: { sub: subid, serv: this.termoServico } });
  }

  AddFavorite(element: any, event: any) {
    if (this.authService.isLoggedIn()) {
      this.list.rows.map((ser: any) => {
        if (ser.id == element.id) {
          ser.favorito = !ser.favorito
          if (ser.favorito) {
            let filter;
            if (element.entidade == 'S') {
              filter = {
                id_usuario: this.authService.getUser().id,
                id_servico: ser.id
              }
            }
            else {
              filter = {
                id_usuario: this.authService.getUser().id,
                id_pessoa: ser.id
              }
            }
            this.favoritoService.add(filter).subscribe();
          }
          else {
            let filter;
            if (element.entidade == 'S') {
              filter = {
                id_usuario: this.authService.getUser().id,
                id_servico: ser.id
              }
            }
            else {
              filter = {
                id_usuario: this.authService.getUser().id,
                id_pessoa: ser.id
              }
            }
            this.favoritoService.delete(filter).subscribe();
          }
        }
      });

      //todo: salvar em banco usuário logado e produto favorito
    }
    else {
      event.preventDefault();
      this.clickAvisoDeslogado();
    }
  }

  clickInteresse(servico: any) {

    if (this.authService.isLoggedIn()) {
      forkJoin(
        this.pessoaService.getPessoa(servico.id_pessoa), this.avaliarService.getMedia({ id_pessoa: servico.id_pessoa })
      ).subscribe((res) => {
        const modalRef = this.modalService.open(InteresseComponent);
        modalRef.componentInstance.servicoModal = servico;
        let pessoa = res[0];
        //pessoa.templateFoto = pessoa.foto ? `${this.pessoaService.getUrl()}images/${pessoa.foto}` : "assets/images/produto.svg";
        pessoa.templateFoto = pessoa.foto || "assets/images/produto.svg";
        modalRef.componentInstance.pessoa = pessoa;
        modalRef.componentInstance.avaliacaoMedia = res[1][0];
      });
    }
    else {
      this.clickAvisoDeslogado();
    }
  }

  Produtos(pesId: string) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(["resultado-da-busca2"], { queryParams: { pesId: pesId } });
  }

  open(content: any) {
    this.modalService.open(content).result.then((result: any) => {
      //this.closeResult = `Closed with: ${result}`;
      console.log('modal abriu', result)
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log('modal erro', reason)
    });
  }

  clickAvisoDeslogado() {
    this.modalService.open(AvisoDeslogadoComponent);
  }

  addVistoRecentemente() {
    if (this.authService.isLoggedIn()) {
      let historico = {
        id_usuario: this.authService.getUser().id,
        id_pessoa: this.pesId
      }
      this.historicoService.add(historico).subscribe();
    }
  }

}
