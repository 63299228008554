import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth.service';
import { AvaliarService } from 'src/app/service/avaliar.service';

@Component({
  selector: 'app-avaliacao',
  template: `
 <div class="modal-content">
    <div class="modal-header">
        <h3>{{pessoa.nome}}</h3>

        <button type="button" class="btn-close close" (click)="activeModal.dismiss('Cross click')">
            <i class="fas fa-times"></i>
        </button>
    </div>

    <div class="modal-body">

        <div class="star-rating">
            <!-- AVALIAR COM 5 ESTRELAS ===== -->
            <input type="radio" id="5-stars" name="rating" [value]="5" [(ngModel)]="avaliarModal.rating" />
            <label for="5-stars" class="star">
                <i class="far fa-star"></i>
            </label>
            <!-- AVALIAR COM 4 ESTRELAS ===== -->
            <input type="radio" id="4-stars" name="rating" [value]="4" [(ngModel)]="avaliarModal.rating" />
            <label for="4-stars" class="star">
                <i class="far fa-star"></i>
            </label>
            <!-- AVALIAR COM 3 ESTRELAS ===== -->
            <input type="radio" id="3-stars" name="rating" [value]="3" [(ngModel)]="avaliarModal.rating" />
            <label for="3-stars" class="star">
                <i class="far fa-star"></i>
            </label>
            <!-- AVALIAR COM 12 ESTRELAS ===== -->
            <input type="radio" id="2-stars" name="rating" [value]="2" [(ngModel)]="avaliarModal.rating" />
            <label for="2-stars" class="star">
                <i class="far fa-star"></i>
            </label>
            <!-- AVALIAR COM 1 ESTRELA ===== -->
            <input type="radio" id="1-star" name="rating" [value]="1" [(ngModel)]="avaliarModal.rating" />
            <label for="1-star" class="star">
                <i class="far fa-star"></i>
            </label>
        </div>

        <div class="bloco-user">
            <span class="user maior">
                <img src='{{avaliador.templateFoto}}' alt="">
            </span>
            <span class="texto">
                <strong>{{avaliador.nome}} </strong>
            </span>
        </div>

        <div class="w-100">
            <div class="form-group ">
                <label for="">Comentários:</label>
                <textarea class="form-control" [(ngModel)]="avaliarModal.comentario" rows="4"></textarea>
            </div>
        </div>

    </div>
    <div class="d-flex justify-content-between modal-footer">
        <!-- <app-avaliacao-estrela pesId={{pesId}}></app-avaliacao-estrela> -->
        <div>
            <button type="button" class="btn btn-transparent" (click)="PostarAvaliacao()">
                <i class="far fa-comment-alt"></i>
                POSTAR
            </button>
            <button type="button" class="btn btn-transparent" (click)="activeModal.dismiss('CANCELAR click')">
                <i class="fas fa-times"></i>
                CANCELAR
            </button>
        </div>

    </div>
</div>
  `
})
export class AvaliacaoComponent implements OnInit {
  @Input() pessoa: any;
  @Input() avaliarModal: any;
  @Input() avaliador: any;
  @Input() pesId: any;

  constructor(
    public activeModal: NgbActiveModal,    
    private toastr: ToastrService,
    private avaliarService: AvaliarService,
    private authService: AuthService) { }

  ngOnInit(): void {
  }

  PostarAvaliacao() {

    if (this.avaliarModal.rating <= 0) {
      this.toastr.warning(`Quantas estrelas ${this.pessoa.nome} merece?`, "AVALIAÇÃO");
      return;
    }
    this.avaliarModal.id_usuario = this.authService.getUser().id;
    this.avaliarModal.id_pessoa = this.pessoa.id;

    this.avaliarService.add(this.avaliarModal).subscribe(next => {
      if (next == 1 || next.id) {
        this.toastr.success(`Obrigado por avaliar ${this.pessoa.nome}!`, "SUCESSO");
        //this.modalService.dismissAll();
        this.activeModal.close();
      }
    }, error => { this.toastr.error("Algo deu errado.", "ERRO") });

  }

}
