<main>
  <div class="conteudo">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item redirect" routerLink="/home">Página Inicial</li>
        <li class="breadcrumb-item redirect" routerLink="/lista-gerenciar-contas">Gerenciar Contas</li>
        <li class="breadcrumb-item active" aria-current="page">Cadastro</li>
      </ol>
    </nav>

    <h3>Cadastro</h3>
    <div class="descricao" *ngIf="tipo == 'externo'">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae tristique magna. Fusce pulvinar vel
        sem at fermentum. Vestibulum ut purus sit amet augue lobortis facilisis. Curabitur ac sapien erat.
        Quisque viverra faucibus nulla eu lacinia. Praesent
        egestas massa in augue posuere vehicula. </p>
    </div>
    <div class="header-title">
      <h2 *ngIf="tipo == 'interno'">
        <span>Contas Administrativas</span>
        {{usuario.nome}}
      </h2>
      <button type="button" class="btn btn-transparent"><i class="fas fa-chevron-left"></i> voltar para
        dados
        gerais</button>
    </div>

    <!-- =========== DADOS PESSOAIS================= -->
    <div class="blocos-cadastro">
      <div class="flex">
        <div class="bloco-avatar">
          <div class="avatar-upload">
            <label for="">Inserir foto:</label>
            <!-- <input type='file' class="upimage" accept=".png, .jpg, .jpeg"
                            (change)="handleFileInput($event.target)" /> -->
            <input type='file' class="upimage" accept=".png, .jpg, .jpeg" (change)="handleFileInput($event)" />
            <div class="avatar-edit">
            </div>
            <div class="avatar-preview">
              <div class="load" *ngIf="spinFoto">
                <i class="fas fa-circle-notch fa-spin"></i>
              </div>
              <img id="imagePreview" class="image" src='{{ templateFoto }}' />
            </div>
            <div class="botoes" *ngIf="templateFoto != 'assets/images/user.svg'">
              <button type="button" class="btn btn-padrao btn-sm" (click)="AlterarFoto()">
                <i class="fas fa-check"></i>
                alterar foto
              </button>
              <button type="button" class="btn btn-danger btn-sm" (click)="ExcluirFoto()">
                <i class="fas fa-times"></i>
                excluir foto
              </button>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="form-group item-required" [ngClass]="displayFieldCss(usuario.nome)">
                <label for="">Nome Completo: <span>*</span></label>
                <input [(ngModel)]="usuario.nome" type="text" class="form-control" placeholder="" maxlength="150">
                <app-field-error-display [displayError]="isFieldValid(usuario.nome)"
                  errorMsg="Por favor, informe o Nome Completo">
                </app-field-error-display>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 col-sm-12">
              <div class="form-group item-required" [ngClass]="displayFieldCss(usuario.email)">
                <label for="">Email: <span>*</span></label>
                <input [(ngModel)]="usuario.email" type="text" class="form-control" placeholder="" maxlength="150">
                <app-field-error-display [displayError]="isFieldValid(usuario.email)"
                  errorMsg="Por favor, informe o Email">
                </app-field-error-display>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group item-required" [ngClass]="displayFieldCss(usuario.id_perfil)">
                <label for="">Perfil: <span>*</span></label>
                <select class="form-control" [(ngModel)]="usuario.id_perfil" [disabled]="perfilDesabilitado">
                  <option value="">Selecione</option>
                  <option *ngFor="let pe of perfis" [value]="pe.id">
                    {{ pe.nome }}
                  </option>
                </select>
                <app-field-error-display [displayError]="isFieldValid(usuario.id_perfil)"
                  errorMsg="Por favor, informe o Perfil">
                </app-field-error-display>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="input-group mb-3 item-required" [ngClass]="displayFieldCss(usuario.senha)">
                <label for="" class="w-100">Senha:</label>
                <i class="toggle-password fa fa-fw {{ inputPassword == 'password' ? 'fa-eye' : 'fa-eye-slash' }}"
                  (click)="togglePassword()"></i>
                <input [(ngModel)]="usuario.senha" [type]="inputPassword" class="form-control" maxlength="150">
                <app-field-error-display class="input-group-error" [displayError]="isFieldValid(usuario.senha)"
                  errorMsg="Por favor, informe a Senha">
                </app-field-error-display>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="input-group mb-3 item-required" [ngClass]="displayFieldCss(usuario.senhaConfirma)">
                <label for="" class="w-100">Confirmar Senha:</label>
                <i class="toggle-password fa fa-fw {{ inputPasswordCheck == 'password' ? 'fa-eye' : 'fa-eye-slash' }}"
                  (click)="togglePasswordCheck()"></i>
                <input [(ngModel)]="usuario.senhaConfirma" [type]="inputPasswordCheck" class="form-control"
                  maxlength="150">
                <app-field-error-display class="input-group-error" *ngIf="usuario.senhaConfirma"
                  [displayError]="comparaSenha()" errorMsg="Senha diferente">
                </app-field-error-display>
                <app-field-error-display class="input-group-error" *ngIf="!usuario.senhaConfirma"
                  [displayError]="isFieldValid(usuario.senhaConfirma)" errorMsg="Por favor, confirme a senha">
                </app-field-error-display>
              </div>
            </div>
          </div>

          <div class="flex justify-content-end">
            <button type="button" class="btn btn-danger btn-sm " (click)="cancelar()">
              <i class="fas fa-times"></i>
              CANCELAR
            </button>
            <button type="button" class="btn btn-success btn-sm" (click)="salvar()">
              <i class="fas fa-check"></i>
              SALVAR
            </button>
          </div>

        </div>
      </div>
    </div>
    <!-- ===========FIM DADOS PESSOAIS================= -->
  </div>
  <!-- =====================FIM =conteudo===================== -->
</main>
