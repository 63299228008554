<section class="enquete" *ngIf="enquete && enquete.pergunta.respostas && enquete.pergunta.respostas.length > 0">
    <div class="center">
        <div class="col">
            <div class="banner-quadrado">
                <img src="assets/images/banner.png" alt="">
            </div>
        </div>

        <div class="card col">
            <h2>Enquete</h2>
            <div class="card-body">
                <h5>{{enquete.Titulo}}</h5>
                <div class="form-check branco">
                    <div class="custom-control custom-radio custom-control-inline" *ngFor="let resposta of enquete.pergunta.respostas; let i = index">
                        <div (click)="enqueteOptionSelected(i)">
                            <input type="radio" id="per1-{{i}}" name="radio" class="custom-control-input">
                            <label class="custom-control-label" for="per1-{{i}}">{{resposta.Descricao}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button class="btn btn-block btn-transparent" (click)="vote()">
                    <i class="fas fa-plus"></i>
                    votar
                </button>
                <button class="btn btn-block btn-transparent">
                    <i class="fas fa-plus"></i>
                    resultado
                </button>
            </div>

        </div>
    </div>
</section>