import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class RelatorioService extends MainService {

  //todo: colocar adm- como prefixo
  _url = `${super.getUrl()}relatorio/`

  constructor(private http: HttpClient) {
    super()
  }

  pessoas(filter: any) {
    const url = `${this._url}pessoas`
    return this.http.post(url, filter, { responseType: 'blob' })
  }

  servicos(filter: any) {
    const url = `${this._url}servicos`
    return this.http.post(url, filter, { responseType: 'blob' })
  }
}
