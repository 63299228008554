import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from '../layout/main-layout/main-layout.component';
import { EventoListaComponent } from './evento-lista/evento-lista.component';

const routes: Routes = [
  { 
    path: 'lista-evento', component: MainLayoutComponent, 
    pathMatch: 'full',
    children: [{ 
      path: '', component: EventoListaComponent
    }] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventoRoutingModule { }
