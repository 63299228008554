import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-oque',
  templateUrl: './modal-oque.component.html',
  styleUrls: ['./modal-oque.component.css']
})
export class ModalOqueComponent {
  @Input() modalRef: any;
  @Input() language: any;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  closeModal(): void {
    this.modalRef.close();
  }

}
