<section class="categorias destaque-blocos template-0{{templateAleatorio}}">
  <div class="center">

    <div class="grid">
      <ul class="list coluna">
        <li *ngFor="let destaque of destaques.parte1">
          <a [routerLink]="['/resultado-da-busca']" [queryParams]="{cat: destaque.id}" *ngIf="destaque.tipo=='c'"></a>
          <a [routerLink]="['/resultado-da-busca']" [queryParams]="{sub: destaque.id}" *ngIf="destaque.tipo=='s'"></a>
          <!-- <a [routerLink]="['/sp']" [queryParams]="{subId: destaque.id, subTitulo: destaque.nome, catTitulo: destaque.categoria }" *ngIf="destaque.tipo=='s'" style="cursor: pointer;"></a>
          <a [routerLink]="['/sp']" [queryParams]="{catId: destaque.id, catTitulo: destaque.nome }" *ngIf="destaque.tipo=='c'" style="cursor: pointer;"></a> -->
          <i class="{{destaque.style}}"></i>
          <h4>{{destaque.nome}}</h4>
          <img src="assets/images/{{destaque.imagem}}" alt="">
        </li>
      </ul>
      <ul class="list coluna">
        <li *ngFor="let destaque of destaques.parte2">
          <a [routerLink]="['/resultado-da-busca']" [queryParams]="{cat: destaque.id}" *ngIf="destaque.tipo=='c'"></a>
          <a [routerLink]="['/resultado-da-busca']" [queryParams]="{sub: destaque.id}" *ngIf="destaque.tipo=='s'"></a>
          <i class="{{destaque.style}}"></i>
          <h4>{{destaque.nome}}</h4>
          <img src="assets/images/{{destaque.imagem}}" alt="">
        </li>
      </ul>
      <ul class="list coluna">
        <li *ngFor="let destaque of destaques.parte3">
          <a [routerLink]="['/resultado-da-busca']" [queryParams]="{cat: destaque.id}" *ngIf="destaque.tipo=='c'"></a>
          <a [routerLink]="['/resultado-da-busca']" [queryParams]="{sub: destaque.id}" *ngIf="destaque.tipo=='s'"></a>
          <i class="{{destaque.style}}"></i>
          <h4>{{destaque.nome}}</h4>
          <img src="assets/images/{{destaque.imagem}}" alt="">
        </li>
      </ul>
    </div>

    <div class="grid">
      <ul class="list coluna">
        <li *ngFor="let destaque of destaques.parte4">
          <a [routerLink]="['/resultado-da-busca']" [queryParams]="{cat: destaque.id}" *ngIf="destaque.tipo=='c'"></a>
          <a [routerLink]="['/resultado-da-busca']" [queryParams]="{sub: destaque.id}" *ngIf="destaque.tipo=='s'"></a>
          <i class="{{destaque.style}}"></i>
          <h4>{{destaque.nome}}</h4>
          <img src="assets/images/{{destaque.imagem}}" alt="">
        </li>
      </ul>
      <ul class="list coluna">
        <li *ngFor="let destaque of destaques.parte5">
          <a [routerLink]="['/resultado-da-busca']" [queryParams]="{cat: destaque.id}" *ngIf="destaque.tipo=='c'"></a>
          <a [routerLink]="['/resultado-da-busca']" [queryParams]="{sub: destaque.id}" *ngIf="destaque.tipo=='s'"></a>
          <i class="{{destaque.style}}"></i>
          <h4>{{destaque.nome}}</h4>
          <img src="assets/images/{{destaque.imagem}}" alt="">
        </li>
      </ul>
      <ul class="list coluna">
        <li *ngFor="let destaque of destaques.parte6">
          <a [routerLink]="['/resultado-da-busca']" [queryParams]="{cat: destaque.id}" *ngIf="destaque.tipo=='c'"></a>
          <a [routerLink]="['/resultado-da-busca']" [queryParams]="{sub: destaque.id}" *ngIf="destaque.tipo=='s'"></a>
          <i class="{{destaque.style}}"></i>
          <h4>{{destaque.nome}}</h4>
          <img src="assets/images/{{destaque.imagem}}" alt="">
        </li>
      </ul>
    </div>

  </div>
</section>



<!-- <div id="slide-topo" class="slide-topo carousel slide" data-ride="carousel">

  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="bg" src="assets/images/bg-topo-artefatos.jpg">
      <img class="img-destaque" src="assets/images/img-dest-artefatos.png" alt="">
    </div>
    <div class="carousel-item">
      <img class="bg" src="assets/images/bg-topo-gastronomia.jpg">
      <img class="img-destaque" src="assets/images/img-dest-gastronomia.png" alt="">
    </div>
    <div class="carousel-item">
      <img class="bg" src="assets/images/bg-topo-danca.jpg">
      <img class="img-destaque" src="assets/images/img-dest-danca.png" alt="">
    </div>
    <div class="carousel-item">
      <img class="bg" src="assets/images/bg-topo-patrimonio.jpg">
      <img class="img-destaque" src="assets/images/img-dest-patrimonio.png" alt="">
    </div>
    <div class="carousel-item">
      <img class="bg" src="assets/images/bg-topo-artes.jpg">
      <img class="img-destaque" src="assets/images/img-dest-artes.png" alt="">
    </div>
    <div class="carousel-item">
      <img class="bg" src="assets/images/bg-topo-grio.jpg">
      <img class="img-destaque" src="assets/images/img-dest-grio.png" alt="">
    </div>
    <div class="carousel-item">
      <img class="bg" src="assets/images/bg-topo-moda.jpg">
      <img class="img-destaque" src="assets/images/img-dest-moda_beleza.png" alt="">
    </div>
    <div class="carousel-item">
      <img class="bg" src="assets/images/bg-topo-musica.jpg">
      <img class="img-destaque" src="assets/images/img-dest-musica.png" alt="">
    </div>
    <div class="carousel-item">
      <img class="bg" src="assets/images/bg-topo-flora.jpg">
      <img class="img-destaque" src="assets/images/img-dest-moda_flora.png" alt="">
    </div>
  </div>

  <a class="carousel-control-prev" href="#slide-topo" role="button" data-slide="prev">
    <i class="fas fa-chevron-left"></i>
  </a>
  <a class="carousel-control-next" href="#slide-topo" role="button" data-slide="next">
    <i class="fas fa-chevron-right"></i>
  </a>


  <div class="carousel-indicators categorias">
    <a [routerLink]="['/resultado-da-busca']" [queryParams]="{cat: '6021f474-648b-11eb-ab50-00155d807205'}" class="active">
      <span class="bg-amarelo">
        <i class="icon-rosario"></i>
      </span>
      <strong>Artefatos Religiosos</strong>
    </a>
    <a [routerLink]="['/resultado-da-busca']" [queryParams]="{cat: '6021f474-648b-11eb-ab50-00155d807204'}">
      <span class="bg-vermelho">
        <i class="icon-panela-3"></i>
      </span>
      <strong>Gastronomia</strong>
    </a>
    <a [routerLink]="['/resultado-da-busca']" [queryParams]="{cat: '6021f474-648b-11eb-ab50-00155d807208'}">
      <span class="bg-azul">
        <i class="icon-danca"></i>
      </span>
      <strong>Dança</strong>
    </a>
    <a [routerLink]="['/resultado-da-busca']" [queryParams]="{cat: '6021f474-648b-11eb-ab50-00155d807209'}">
      <span class="bg-verde">
        <i class="icon-igreja"></i>
      </span>
      <strong>Patrimônios</strong>
    </a>
    <a [routerLink]="['/resultado-da-busca']" [queryParams]="{cat: '6021f474-648b-11eb-ab50-00155d807207'}">
      <span class="bg-amarelo">
        <i class="icon-pintura"></i>
      </span>
      <strong>Artes visuais</strong>
    </a>
    <a [routerLink]="['/resultado-da-busca']" [queryParams]="{cat: '6021f474-648b-11eb-ab50-00155d807202'}">
      <span class="bg-azul">
        <i class="icon-encontro"></i>
      </span>
      <strong>Griôs</strong>
    </a>

    <a [routerLink]="['/resultado-da-busca']" [queryParams]="{cat: '50f2434c-648b-11eb-ab50-00155d807202'}">
      <span class="bg-verde">
        <i class="icon-shirt"></i>
      </span>
      <strong>Moda/Beleza</strong>
    </a>
    <a [routerLink]="['/resultado-da-busca']" [queryParams]="{cat: '6021f474-648b-11eb-ab50-00155d807203'}">
      <span class="bg-vermelho">
        <i class="icon-musica"></i>
      </span>
      <strong>Música</strong>
    </a>
    <a [routerLink]="['/resultado-da-busca']" [queryParams]="{cat: '6021f474-648b-11eb-ab50-00155d807206'}">
      <span class="bg-verde">
        <i class="icon-flora"></i>
      </span>
      <strong>Flora</strong>
    </a>

  </div>
</div>
 -->
