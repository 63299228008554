import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PessoaService } from '../service/pessoa.service';
import { RodadaNegocioService } from '../service/rodada-negocio.service';

@Component({
  selector: 'app-rodada-negocio',
  templateUrl: './rodada-negocio.component.html',
  styleUrls: ['./rodada-negocio.component.css']
})
export class RodadaNegocioComponent implements OnInit {

  rodadaNegocio = {
    imagem_modal: "assets/images/banner-640x500px-rodada-negocios.jpg",
    id: ''
  }

  constructor(
    public activeModal: NgbActiveModal,
    private rodadaNegocioService: RodadaNegocioService,
    private pessoaService: PessoaService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.rodadaNegocioService.disponivel().subscribe(next => {
      if (next) {
        this.rodadaNegocio = next
      }
    })
  }

  irParaCadastro() {
    this.activeModal.close()
    this.pessoaService.pessoa = { interesseRodada: true, id_rodada: this.rodadaNegocio.id }
    this.router.navigate(['pessoa'])
  }

}
