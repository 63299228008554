<main>
    <div class="conteudo">

        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item redirect" routerLink="/home">Página Inicial</li>
                <li class="breadcrumb-item redirect" routerLink="/rodada-de-negocio">Lista</li>
                <li class="breadcrumb-item active" aria-current="page">Cadastro de Rodada de Negócio</li>
            </ol>
        </nav>

        <h2> Rodada de Negócios</h2>

        <div class="guias-cadastro">
            <button type="button" class="btn btn-transparent active">
                <i class="fas fa-plus"></i>
                Cadastro de Rodadas
            </button>
            <button type="button" class="btn btn-transparent" (click)="irParaLista()">
                <i class="fas fa-list-ul"></i>
                Lista de Rodadas
            </button>
        </div>



        <div class="blocos-cadastro">
            <div class="flex">
                <div class="bloco-avatar">
                    <div class="avatar-upload upload-produto">
                        <label for="">
                            Inserir Modal da rodada (640x500px):
                        </label>
                        <input type='file' class="upimage" accept=".png, .jpg, .jpeg"
                            (change)="handleFileInput($event, 'modal')" />
                        <div class="avatar-edit">
                        </div>
                        <div class="avatar-preview">
                            <img id="imagePreview" class="image" src='{{templateModal}}' />
                        </div>
                        <div class="botoes">
                            <button type="button" class="btn btn-padrao btn-sm">
                                <i class="fas fa-check"></i>
                                alterar foto
                            </button>
                            <button type="button" class="btn btn-danger btn-sm" (click)="excluirModal()">
                                <i class="fas fa-times"></i>
                                excluir foto
                            </button>
                        </div>
                    </div>

                    <div class="avatar-upload upload-produto banner-rodada2">
                        <label for="">
                            Inserir banner da rodada (1328x138px):
                        </label>
                        <input type='file' class="upimage" accept=".png, .jpg, .jpeg"
                            (change)="handleFileInput($event, 'banner')" />
                        <div class="avatar-edit">
                        </div>
                        <div class="avatar-preview">
                            <img id="imagePreview" class="image" src='{{templateBanner}}' />
                        </div>
                        <div class="botoes">
                            <button type="button" class="btn btn-padrao btn-sm">
                                <i class="fas fa-check"></i>
                                alterar foto
                            </button>
                            <button type="button" class="btn btn-danger btn-sm" (click)="excluirBanner()">
                                <i class="fas fa-times"></i>
                                excluir foto
                            </button>
                        </div>
                    </div>

                </div>
                <div class="col">
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <div class="form-group" [ngClass]="displayFieldCss(rodadaNegocio.titulo)">
                                <label for="">
                                    <span>Título da Rodada:*</span>
                                    <a aria-label="Insira o Título da Rodada de Negócio" data-balloon-pos="up" class=""
                                        style="cursor: pointer;">
                                        <i class="fas fa-question-circle"></i>
                                    </a>
                                </label>
                                <input [(ngModel)]="rodadaNegocio.titulo" type="text" class="form-control"
                                    placeholder="" maxlength="50">
                                <app-field-error-display [displayError]="isFieldValid(rodadaNegocio.titulo)"
                                    errorMsg="Por favor, informe o Título">
                                </app-field-error-display>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="">
                                    <span>Texto do check:</span>
                                    <a aria-label="Insira o Título da Rodada de Negócio" data-balloon-pos="up" class=""
                                        style="cursor: pointer;">
                                        <i class="fas fa-question-circle"></i>
                                    </a>
                                </label>
                                <input [(ngModel)]="rodadaNegocio.descricao_checkbox" type="text" class="form-control"
                                    maxlength="50">

                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-md-6">
                            <div class="row">
                                <div class="col-md-6 col-sm-6">
                                    <div class="form-group ico-date"
                                        [ngClass]="displayFieldCss(rodadaNegocio.dataInicio)">
                                        <label for="">
                                            Data inicial: <span>*</span>
                                            <a aria-label="Digite a Data de Início" data-balloon-pos="up" class="">
                                                <i class="fas fa-question-circle"></i>
                                            </a>
                                        </label>
                                        <input [(ngModel)]="rodadaNegocio.dataInicio" class="form-control" ngbDatepicker
                                            #di="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}"
                                            [maxDate]="{year: currentDate.year - 1, month: 12, day: 31}"
                                            (click)="di.toggle()">
                                        <app-field-error-display [displayError]="isFieldValid(rodadaNegocio.dataInicio)"
                                            errorMsg="Por favor, informe a Data de Início">
                                        </app-field-error-display>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <div class="form-group ico-date" [ngClass]="displayFieldCss(rodadaNegocio.dataFim)">
                                        <label for="">
                                            Data final: <span>*</span>
                                            <a aria-label="Digite a Data de Fim" data-balloon-pos="up" class="">
                                                <i class="fas fa-question-circle"></i>
                                            </a>
                                        </label>
                                        <input [(ngModel)]="rodadaNegocio.dataFim" class="form-control" ngbDatepicker
                                            #df="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}"
                                            (click)="df.toggle()">
                                        <app-field-error-display [displayError]="isFieldValid(rodadaNegocio.dataFim)"
                                            errorMsg="Por favor, informe a Data de Fim">
                                        </app-field-error-display>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <div class="form-group">
                                <label for="">
                                    <span>Descrição:</span>
                                    <a aria-label="Insira a Descrição da Rodada de Negócio" data-balloon-pos="up"
                                        class="" style="cursor: pointer;">
                                        <i class="fas fa-question-circle"></i>
                                    </a>
                                </label>
                                <textarea [(ngModel)]="rodadaNegocio.descricao" class="form-control" maxlength="250"
                                    rows="5"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="form-group">
                            <input type="checkbox" [(ngModel)]="rodadaNegocio.ativo" class="form-check-input">
                            <label for="" class="form-check-label">Rodada de Negócio Ativa?</label>
                        </div>
                    </div>


                </div>
            </div>

            <div class="flex justify-content-end">
                <button type="button" class="btn btn-danger btn-sm " (click)="irParaLista()">
                    <i class="fas fa-times"></i>
                    CANCELAR
                </button>
                <button type="button" class="btn btn-success btn-sm" (click)="Salvar()" [disabled]="SalvarClicado">
                    <i class="fas fa-check"></i>
                    SALVAR
                </button>
            </div>
        </div>
    </div>
</main>