import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends MainService {

  _url = `${super.getUrl()}adm/usuario/`;

  constructor(private http: HttpClient) { super() }

  usuario: any;

  getUsuarios(filter: any): Observable<any[]> {
    const url = `${this._url}search`;
    return this.http.post<any[]>(url, filter, this.getOptions())
  }

  mudarStatusUsuario(userId: any): Observable<any[]> {
    const url = `${this._url}changeUserState`;
    return this.http.patch<any[]>(url, userId, this.getOptions())
  }

  addUsuario(usuario: any): Observable<any> {
    const url = `${this._url}add`;
    return this.http.post(url, usuario, this.getOptions());
  }

  updateUsuario(usuario: any): Observable<any> {
    const url = `${this._url}update`;
    return this.http.put(url, usuario, this.getOptions());
  }

  getUsuario(id: string): Observable<any> {
    const url = `${this._url}get/${id}`;
    return this.http.get<any>(url, this.getOptions());
  }

  recuperaSenha(email: string) {
    const url = `${super.getUrl()}usuario/recuperaSenha`;
    return this.http.post<any>(url, { email }, this.getOptions());
  }

  cadastrantes() {
    const url = `${this._url}cadastrantes`;
    return this.http.post<any>(url, {}, this.getOptions());
  }
}
