import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from '../../service/usuario.service';
import { PessoaService } from '../../service/pessoa.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-conta-lista',
  templateUrl: './conta-lista.component.html',
  styleUrls: ['./conta-lista.component.css']
})
export class ContaListaComponent implements OnInit {
  tipo: any;
  role: any;
  conta: any;
  usuarios: any;
  filtro: any;
  paginaAtual: number;
  qtdPaginas: any;
  qtdItensPorPagina: number;
  constructor(
    private route: ActivatedRoute,
    private usuarioService: UsuarioService,
    private pessoaService: PessoaService,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService
  ) {
    this.paginaAtual = 1;
    this.qtdPaginas = [];
    this.qtdItensPorPagina = 5;
    this.usuarios = { rows: [] };
    this.filtro = {
      nome: '',
      email: '',
      cpf: ''
    };
   }

  ngOnInit(): void {
    this.usuarioService.usuario = "";
    window.scroll({top: 0, left: 0});
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(["home"]);
      return;
    } else {
      this.tipo = this.route.snapshot.data['tipo'] || "";
      this.role = this.route.snapshot.data['role'] || "";
      this.getUsuariosList(this.paginaAtual);
    }
  }

  filtrar() {
    this.getUsuariosList(1);
  }

  getUsuariosList(pagina: number) {

    this.paginaAtual = pagina;
    
    this.filtro.offset = (pagina - 1) * this.qtdItensPorPagina;
    this.filtro.limit = this.qtdItensPorPagina;

    this.filtro.isAdm = true;

    this.usuarioService.getUsuarios(this.filtro).subscribe((list: any) => {
      list.rows = list.rows.map((usu: any) => {
        //usu.templateFoto = usu.foto ? `${this.pessoaService.getUrl()}images/${usu.foto}` : "./../../assets/images/user.jpg";
        usu.templateFoto = usu.foto || "./../../assets/images/user.jpg";
        return usu;
      });
      this.usuarios = list;
      const qtdTotalPaginas = Math.ceil(this.usuarios.count / this.qtdItensPorPagina);
      this.usuarios = { ...this.usuarios, qtdTotalPaginas };
      this.qtdPaginas = [];
      this.qtdPaginas.push(pagina);
      let lastcount = 0;
      while (this.qtdPaginas.length != lastcount && this.qtdPaginas.length < 5)
      {
          lastcount = this.qtdPaginas.length;
          this.qtdPaginas.unshift(this.qtdPaginas[0] - 1);
          this.qtdPaginas.push(this.qtdPaginas[this.qtdPaginas.length - 1] + 1);
          this.qtdPaginas = this.qtdPaginas.filter(function(el: any) {
              return el > 0;
          })
          this.qtdPaginas = this.qtdPaginas.filter(function(el: any) {
              return el <= qtdTotalPaginas;
          })
      }
    });
  }

  mudarStatusUsuario(usuario: any, status: string) {
    const frase = usuario.Pessoa ? `Há uma pessoa vinculada a este usuário, ${status.toLowerCase()} este usuário irá ${status.toLowerCase()} a pessoa também. Tem certeza que deseja ${status.toLowerCase()} o usuário ${usuario.nome}?` : 
                  `Tem certeza que deseja ${status} o usuário ${usuario.nome}?`;
    Swal.fire({
      title: "",
      text: frase,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        this.usuarioService.mudarStatusUsuario({ id: usuario.id }).subscribe((usuarios: any) => {
          this.getUsuariosList(this.paginaAtual);
          this.toastr.success("Status atualizado com sucesso", "Sucesso");
        });
        
      }
    });
  }

  criarUsuario() {
    this.router.navigate(["conta-cadastro"]);
  }

  editarUsuario(usuario: any) {
    if(usuario.id_pessoa && usuario.id_perfil === '6da4dc31-67b8-11eb-99db-641c678d9ca2'){
      this.usuarioService.usuario = usuario;
      this.pessoaService.isFromRoute = 'conta';
      this.router.navigate(["adm-cadastrar-dados-pessoais"]);
    } else {
      this.router.navigate(["conta-cadastro"], { queryParams: { usuario: usuario.id } });
    }
  }
}
