import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from '../../service/usuario.service';
import { UploadService } from '../../service/upload.service';
import { PerfilService } from 'src/app/service/perfil.service';
import { ToastrService } from 'ngx-toastr';
import { PessoaService } from 'src/app/service/pessoa.service';
import { AuthService } from 'src/app/service/auth.service';
import { environment } from '.env';
import { ModalCropperComponent } from 'src/app/image-cropper/modal/modal-cropper.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-conta-cadastro',
  templateUrl: './conta-cadastro.component.html',
  styleUrls: ['./conta-cadastro.component.css']
})
export class ContaCadastroComponent implements OnInit {
  tamanhoMaximoImagem = environment.MAX_SIZE_UPLOAD / 1000000;
  tipo: any;
  usuario: any;
  fileToUpload: any;
  templateFoto: any = "assets/images/user.svg";
  isSubmited: any;
  status: any;
  perfis: any;
  inputPassword = "password";
  inputPasswordCheck = "password";
  updateAuth = false;
  spinFoto = false;
  perfilDesabilitado = false

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usuarioService: UsuarioService,
    private perfilService: PerfilService,
    private upload: UploadService,
    private toastr: ToastrService,
    private pessoaService: PessoaService,
    private authService: AuthService,
    private modalService: NgbModal
  ) {
    this.status = [{ id: 1, descricao: 'Ativo' }, { id: 0, descricao: 'Inativo' }];
    this.isSubmited = false;
    this.perfis = [];
    this.usuario = {
      id_perfil: '',
      nome: '',
      email: '',
      foto: '',
      senha: '',
      senhaConfirma: ''
    };
  }

  ngOnInit(): void {
    window.scroll({ top: 0, left: 0 });
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(["home"]);
      return;
    }

    this.tipo = this.route.snapshot.data['tipo'] || "";
    if (this.router.url.replace('/', '') == 'editar-dados-usuario') {
      this.obterUsuario(this.authService.getUser().id);
      if (this.authService.getUser().nome_perfil === 'Agente') {
        this.perfilDesabilitado = true;
      }
    } else {
      this.route.queryParams
        .subscribe(params => {
          if (params.usuario) {
            this.obterUsuario(params.usuario);
          }
        }
        );
    }
    this.updateAuth = this.route.snapshot.data['updateAuth'] || false;
    this.carregarPerfis();
  }

  obterUsuario(usuarioId: string) {
    this.usuarioService.getUsuario(usuarioId).subscribe(usu => {
      this.usuario = usu;
      this.usuario.senhaConfirma = usu.senha;
      //this.templateFoto = usu.foto ? `${this.pessoaService.getUrl()}images/${usu.foto}` : "./../../assets/images/user.jpg";
      this.templateFoto = usu.foto || "./../../assets/images/user.jpg";
      if (this.usuario.id_perfil === '6da4dc31-67b8-11eb-99db-641c678d9ca2') {
        this.usuario.id_perfil = '';
      }
    },
      error => {
        this.toastr.error("Não foi possível obter usuário.", "Erro");
      }
    );
  }

  carregarPerfis() {
    this.perfilService.getPerfil({}).subscribe((list) => {
      this.perfis = list.filter((l: { nome: string; }) => l.nome != 'Pessoa');
      if (this.perfis.length === 1) {
        this.usuario.id_perfil = this.perfis[0].id;
      }
    });
  }

  cancelar() {
    if (this.router.url.replace('/', '') == 'editar-dados-usuario') {
      this.router.navigate(["home"]);
    } else {
      this.router.navigate(["conta"]);
    }
  }

  handleFileInput(event: any) {


    this.fileToUpload = event.target.files.item(0);

    if (!this.fileToUpload) {
      return;
    }

    if (this.fileToUpload.size > environment.MAX_SIZE_UPLOAD) {
      this.toastr.error(`Tamanho máximo de imagem permitido é ${this.tamanhoMaximoImagem}Mb`)
      return;
    }

    const modalRef = this.modalService.open(ModalCropperComponent)
    modalRef.componentInstance.imageChangedEvent = event
    modalRef.componentInstance.aspectRatio = 1
    modalRef.componentInstance.showControlPanel = true // apagar depois
    modalRef.componentInstance.clickevent.subscribe(($e: any) => {

      this.upload.upload($e).subscribe(x => {
        this.usuario.foto = x.persistentUrl
        this.templateFoto = $e
        //this.spinFoto = false;
      })

    })


    // this.fileToUpload = files.files.item(0);

    // if (!this.fileToUpload) {
    //   return;
    // }

    // if (this.fileToUpload.size > environment.MAX_SIZE_UPLOAD) {
    //   this.toastr.error(`Tamanho máximo de imagem permitido é ${this.tamanhoMaximoImagem}Mb`)
    //   return;
    // }
    // this.spinFoto = true;
    // var reader = new FileReader();
    // reader.onload = () => {
    //   this.upload.upload(reader.result).subscribe(x => {
    //     this.usuario.foto = x.persistentUrl;
    //     this.templateFoto = x.url
    //     this.spinFoto = false;
    //   });
    // }
    // reader.readAsDataURL(this.fileToUpload);
  }

  ExcluirFoto() {
    this.usuario.foto = null;
    this.templateFoto = "assets/images/user.svg";
  }

  AlterarFoto() {

  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  isFieldValid(field: any) {
    return this.isSubmited && field == ""
  }

  salvar() {
    this.isSubmited = true;
    if (!this.validateField() || this.comparaSenha()) {
      return;
    }
    if (this.usuario && this.usuario.id) {
      this.editarUsuario();
    } else {
      this.cadastrarUsuario();
    }
  }

  cadastrarUsuario() {
    this.usuarioService.addUsuario(this.usuario).subscribe(next => {
      this.toastr.success("Adicionado com sucesso", "Sucesso");
      this.router.navigate(["conta"]);
    },
      error => {
        this.toastr.error("Não foi possível adicionar.", "Erro");
      }
    );
  }

  editarUsuario() {
    this.usuarioService.updateUsuario(this.usuario).subscribe(next => {
      if (next) {
        if (this.updateAuth) {
          this.authService.updateUser(this.usuario.foto, this.usuario.nome);
        }
        this.toastr.success("Atualizado com sucesso", "Sucesso");
        if (this.router.url.replace('/', '') == 'editar-dados-usuario') {
          this.router.navigate(["home"]);
        } else {
          this.router.navigate(["conta"]);
        }
      }
    }, error => {
      this.toastr.error("Não foi possível atualizar.", "Erro");
    });
  }


  validateField() {
    const fields = [
      'id_perfil',
      'nome',
      'email',
      'senha',
      'senhaConfirma'
    ];

    for (const iterator of fields) {
      if (this.usuario[iterator] == '') {
        return false;
      }
    }

    return true;

  }

  togglePassword() {
    this.inputPassword = this.inputPassword == "password" ? "text" : "password";
  }

  togglePasswordCheck() {
    this.inputPasswordCheck = this.inputPasswordCheck == "password" ? "text" : "password";
  }

  comparaSenha() {
    return this.isSubmited && this.usuario.senha !== this.usuario.senhaConfirma
  }
}
