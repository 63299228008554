import { Component, OnInit, Input } from '@angular/core';
import { GerenciadorConteudoService } from 'src/app/service/gerenciador-conteudo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalEventoDetalheComponent } from 'src/app/evento/modal-evento-detalhe/modal-evento-detalhe.component';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from 'src/app/service/auth.service';
import { MenuService } from 'src/app/service/menu.service';
import { Router } from '@angular/router';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

@Component({
  selector: 'app-evento',
  templateUrl: './evento.component.html',
  styleUrls: ['./evento.component.css']
})
export class EventoComponent implements OnInit {
  carregandoEventos: any;
  eventos: any;
  eventosEmpty: boolean;
  modalRefEvento: any;

  constructor(
    private gerenciadorConteudo: GerenciadorConteudoService,
    private modalService: NgbModal,
    private sanitize: DomSanitizer,
    private router: Router,
    private spinnerEvento: NgxSpinnerService,
    private authService: AuthService,
    public menuService: MenuService
  ) {
    this.eventos = [];
    this.eventosEmpty = false;
    this.carregandoEventos = true;
  }

  @Input() titulo: any = '';
  @Input() linkList: any = '';
  @Input() cssClass: any = '';

  ngOnInit(): void {
    this.spinnerEvento.show('sp1');
    this.gerenciadorConteudo.getEventsSpotlight(this.authService.getLanguage()).subscribe((result: any) => {
      if (result.error) {
        console.log('eventos-destaque ', result.error);
        this.eventos = [];
        this.eventosEmpty = true;
        this.spinnerEvento.hide('sp1');
        return;
      }
      if (result.length == 0) {
        this.eventosEmpty = true;
        this.spinnerEvento.hide('sp1');
        return;
      }
      this.eventosEmpty = this.eventos.length == 0;
      const res = result.slice(0, 2);
      this.eventos = res;
      this.carregandoEventos = false;
      this.spinnerEvento.hide('sp1');
    });
  }

  getDayName(eventDate: string) {
    return this.capitalizeFirstLetter(moment(eventDate, "DD/MM/YYYY").format("ddd"));
  }

  getDayAndMonth(eventDate: string ) {
    return this.capitalizeFirstLetter(moment(eventDate, "DD/MM/YYYY").format("DD MMM"));
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  direcionarListagem() {
    this.router.navigate(["lista-evento"]);
  }

  openEventDetail (selectedEvent: any) {
    this.modalRefEvento = this.modalService.open(ModalEventoDetalheComponent, {
      windowClass: 'modal-dialog-centered modal-eventos',
      backdrop: 'static',
    });
    console.log('language', this.authService.getLanguage());
    this.modalRefEvento.componentInstance.evento = selectedEvent;
    this.modalRefEvento.componentInstance.conteudo = this.sanitize.bypassSecurityTrustHtml(selectedEvent.Conteudo);
    this.modalRefEvento.componentInstance.modalRef = this.modalRefEvento;
    this.modalRefEvento.componentInstance.language = this.authService.getLanguage();
  }


}
