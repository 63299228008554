import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvaliacaoEstrelaComponent } from './avaliacao-estrela.component';

@NgModule({
  declarations: [AvaliacaoEstrelaComponent],
  imports: [
    CommonModule
  ],
  exports: [AvaliacaoEstrelaComponent]
})
export class AvaliacaoEstrelaModule { }
