<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="btn-close close" (click)="activeModal.dismiss('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body pb-0">
    <div class="card-pessoa card">
      <div class="bloco-user">
        <div class="like" (click)="AddFavorite(pessoa.id, $event)" *ngIf="exibeFavoritos">
          <input type="checkbox" class="custom-control-input" [checked]='pessoa.favorito' id="{{'chk'+pessoa.id}}">
          <label class="custom-control-label" for="{{'chk'+pessoa.id}}">
            <i class="far fa-heart"></i>
          </label>
        </div>
        <span class="user maior">
          <img src="{{pessoa.templateFoto}}" alt="">
        </span>
        <span class="texto">
          <h2>
            {{pessoa.nome_fantasia}}
          </h2>
          <app-avaliacao-estrela [pesId]="pessoa.id"></app-avaliacao-estrela>
          <small>{{pessoa.qtdProdutos}} Produtos</small>
          <div class="social">
            <a href="tel:+55{{pessoa.telefone}}" *ngIf="pessoa.telefone" style="cursor: pointer;">
              <i class="fas fa-phone"></i>
            </a>
            <a [href]="pessoa.facebook" target="_blank" *ngIf="pessoa.facebook" style="cursor: pointer;">
              <i class="fab fa-facebook"></i>
            </a>
            <a href="mailto:{{pessoa.email}}" target="_blank" style="cursor: pointer;">
              <i class="fas fa-envelope"></i>
            </a>
            <a [href]="pessoa.instagram" target="_blank" *ngIf="pessoa.instagram" style="cursor: pointer;">
              <i class="fab fa-instagram"></i>
            </a>
            <a [href]="pessoa.website" *ngIf="pessoa.website" target="_blank" style="cursor: pointer;">
              <i class="fas fa-globe"></i>
            </a>
            <button type="button" id="Tag_FaleWhatsApp" class="btn btn-padrao btn-sm" (click)="clickWhatsapp(pessoa.celular)">
              <i class="fab fa-whatsapp"></i>
              <span>Fale por WhatsApp</span>
            </button>
          </div>
        </span>
      </div>
    </div>
    <div class="aumentar-fonte">
      <a [href]="" class="btn" style="cursor: pointer;" (click)="sizeUpFont()">
        A
        <i class="fas fa-plus"></i>
      </a>
      <a [href]="" class="btn" style="cursor: pointer;" (click)="sizeDownFont()">
        A
        <i class="fas fa-minus"></i>
      </a>
    </div>
    <label for="">Descrição:</label>
    <p [class]="acessibilidade">{{pessoa.autobiografia}}</p>
    <label for="">Localização:</label>
    <p>{{pessoa.endereco}}, {{pessoa.cidade}} - {{pessoa.uf}}, {{pessoa.cep}}</p>
  </div>
  <!-- src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d45938.1420127821!2d-38.47637650707331!3d-12.955903415902972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sbaiana%20de%20acaraje!5e0!3m2!1spt-BR!2sbr!4v1611943629176!5m2!1spt-BR!2sbr" -->
  <div class="mapa">
    <iframe [src]="pessoa.urlMapa | safe" frameborder="0" style="border:0;" allowfullscreen="" tabindex="0"></iframe>
  </div>
</div>
