import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { LayoutLogadoComponent } from '../layout/layout-logado/layout-logado.component';
import { PessoaComponent } from './pessoa.component';
import { AuthGuard } from '../guard/auth.guard';
import { PessoaListaComponent } from '../pessoa-lista/pessoa-lista.component';

const routes: Routes = [
  { path: 'cadastrar-dados-pessoais', component: LayoutLogadoComponent, children: [{ path: '', component: PessoaComponent, data: { tipo: 'externo' } }] },
  { path: 'editar-dados-pessoais', component: LayoutLogadoComponent, children: [{ path: '', component: PessoaComponent, data: { tipo: 'externo', updateAuth: true } }] },
  {
    path: 'adm-cadastrar-dados-pessoais', component: LayoutLogadoComponent,
    children: [{
      path: '', component: PessoaComponent, canActivate: [AuthGuard], data: { tipo: 'interno' }
    }]
  },
  {
    path: 'adm-listagem-pessoas', component: LayoutLogadoComponent,
    children: [{
      path: '', component: PessoaListaComponent, canActivate: [AuthGuard], data: { tipo: 'interno' }
    }]
  },
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PessoaRoutingModule { }
