import { LowerCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/service/auth.service';
import { FavoritoService } from 'src/app/service/favorito.service';
import { MenuService } from 'src/app/service/menu.service';
import { PessoaService } from 'src/app/service/pessoa.service';
import { ServicoService } from 'src/app/service/servico.service';
import { AvisoDeslogadoComponent } from '../../aviso-deslogado/aviso-deslogado.component';

@Component({
  selector: 'app-pessoa-externo-lista',
  templateUrl: './pessoa-externo-lista.component.html',
  styleUrls: ['./pessoa-externo-lista.component.css']
})

export class PessoaExternoListaComponent implements OnInit {

  filtro: any;
  qtdPaginas: any;
  qtdItensPorPagina = 12;
  paginaAtual = 1;
  pessoas: any;
  titulo: any;
  TituloSubcategoria = "";
  TituloCategoria = "";
  subId: string | any;
  Categorias: any;
  qtdTotal = 0;
  ImagemCategoria = "assets/images/produto.svg";
  cat: string | null = ''

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pessoaService: PessoaService,
    private menuService: MenuService,
    private authService: AuthService,
    private favoritoService: FavoritoService,
    private modalService: NgbModal,
    private servicoService: ServicoService
  ) {
    this.pessoas = {
      rows: []
    }
    this.Categorias = [
      { Subcategoria: [] }
    ];
    this.filtro = {
      offset: 0,
      limit: 0,
      order: 'ASC'
    };
  }

  ngOnInit(): void {

    this.subId = this.route.snapshot.queryParamMap.get('sub');
    this.cat = this.route.snapshot.queryParamMap.get('cat');

    if (!this.subId && !this.cat) {
      this.router.navigate(["home"]);
    }

    console.log(this.subId, this.cat)
    //this.getProdutoList(1)
    this.getPessoaList(1)

    if (this.cat) {
      this.getBlocoPesquisa({ id_categoria: this.cat });
    }
    else {
      this.getBlocoPesquisa({ id_subcategoria: this.subId });
    }

    if (this.authService.isLoggedIn()) {
      this.getFavoritosUsuario();
    }

  }

  getProdutoList(pagina: number) {
    this.paginaAtual = pagina;

    this.filtro.offset = (pagina - 1) * this.qtdItensPorPagina;
    this.filtro.limit = this.qtdItensPorPagina;
    this.filtro.id_subcategoria = this.subId;

    if (this.cat) {
      this.filtro.id_categoria = this.cat;
    }

    this.servicoService.search(this.filtro).subscribe((list: any) => {
      list.rows = list.rows.map((pes: any) => {
        //pes.templateFoto = pes.foto ? `${this.pessoaService.getUrl()}images/${pes.foto}` : "assets/images/user.svg";
        pes.templateFoto = pes.foto || "assets/images/user.svg";
        pes.favorito = false;
        return pes;
      });
      this.pessoas = list;
      const qtdTotalPaginas = Math.ceil(this.pessoas.count / this.qtdItensPorPagina);
      this.pessoas = { ...this.pessoas, qtdTotalPaginas };
      this.qtdPaginas = [];
      this.qtdPaginas.push(pagina);
      let lastcount = 0;
      while (this.qtdPaginas.length != lastcount && this.qtdPaginas.length < 5) {
        lastcount = this.qtdPaginas.length;
        this.qtdPaginas.unshift(this.qtdPaginas[0] - 1);
        this.qtdPaginas.push(this.qtdPaginas[this.qtdPaginas.length - 1] + 1);
        this.qtdPaginas = this.qtdPaginas.filter(function (el: any) {
          return el > 0;
        })
        this.qtdPaginas = this.qtdPaginas.filter(function (el: any) {
          return el <= qtdTotalPaginas;
        })
      }
    })
  }

  getFavoritosUsuario() {
    let filter = {
      id_usuario: this.authService.getUser().id
    }
    this.favoritoService.list(filter).subscribe(next => {
      this.pessoas.rows.map((element: any) => {
        next.map((favorito: any) => {
          if (favorito.id_pessoa == element.id || favorito.id_servico == element.id) {
            element.favorito = true;
          }
        });
      })
    })
  }

  getSubcategoria() {

  }

  getBlocoPesquisa(filter: any) {
    this.menuService.BlocoPesquisa(filter).subscribe(next => {
      this.Categorias = next;
      this.TituloCategoria = this.Categorias[0].nome;

      this.ImagemCategoria = `assets/images/${this.getImagemCategoria(this.Categorias[0].nome)}.jpg`;
      this.TituloSubcategoria = this.Categorias[0].Subcategoria.find((x: { id: any; }) => x.id == this.subId)?.nome;

      this.qtdTotal = 0;
      this.Categorias[0].Subcategoria.forEach((x: { Servicos: string | any[]; }) => {
        this.qtdTotal += x.Servicos.length

      })
    });

  }

  getPessoaList(pagina: number, catId: any = '') {

    this.paginaAtual = pagina;

    this.filtro.offset = (pagina - 1) * this.qtdItensPorPagina;
    this.filtro.limit = this.qtdItensPorPagina;
    this.filtro.id_subcategoria = this.subId;

    if (catId) {
      this.filtro.id_categoria = catId;
    }

    if (this.cat) {
      this.filtro.id_categoria = this.cat
      this.filtro.id_subcategoria = ''      
    }

    this.pessoaService.getPessoas(this.filtro).subscribe((list: any) => {
      list.rows = list.rows.map((pes: any) => {
        //pes.templateFoto = pes.foto ? `${this.pessoaService.getUrl()}images/${pes.foto}` : "assets/images/user.svg";
        pes.templateFoto = pes.foto || "assets/images/user.svg";
        pes.favorito = false;
        return pes;
      });
      this.pessoas = list;
      const qtdTotalPaginas = Math.ceil(this.pessoas.count / this.qtdItensPorPagina);
      this.pessoas = { ...this.pessoas, qtdTotalPaginas };
      this.qtdPaginas = [];
      this.qtdPaginas.push(pagina);
      let lastcount = 0;
      while (this.qtdPaginas.length != lastcount && this.qtdPaginas.length < 5) {
        lastcount = this.qtdPaginas.length;
        this.qtdPaginas.unshift(this.qtdPaginas[0] - 1);
        this.qtdPaginas.push(this.qtdPaginas[this.qtdPaginas.length - 1] + 1);
        this.qtdPaginas = this.qtdPaginas.filter(function (el: any) {
          return el > 0;
        })
        this.qtdPaginas = this.qtdPaginas.filter(function (el: any) {
          return el <= qtdTotalPaginas;
        })
      }
    })
  }

  resultadoBusca() {
    let catid = this.Categorias[0].id;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(["resultado-da-busca"], { queryParams: { cat: catid } });
  }

  resultadoBuscaSub(subId: any) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(["resultado-da-busca"], { queryParams: { sub: subId } });
  }

  AddFavorite(id: any, event: any) {
    if (this.authService.isLoggedIn()) {
      this.pessoas.rows.map((ser: any) => {
        if (ser.id == id) {
          ser.favorito = !ser.favorito
          if (ser.favorito) {
            this.favoritoService.add({ id_usuario: this.authService.getUser().id, id_pessoa: ser.id }).subscribe();
          }
          else {
            this.favoritoService.delete({ id_usuario: this.authService.getUser().id, id_pessoa: ser.id }).subscribe();
          }
        }
      });

      //todo: salvar em banco usuário logado e produto favorito
    }
    else {
      event.preventDefault();
      this.clickAvisoDeslogado();
    }
  }

  open(content: any) {
    this.modalService.open(content).result.then((result: any) => {
      //this.closeResult = `Closed with: ${result}`;
      console.log('modal abriu', result)
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log('modal erro', reason)
    });
  }


  clickAvisoDeslogado() {
    this.modalService.open(AvisoDeslogadoComponent);
  }

  getImagemCategoria(nome: string) {
    switch (nome) {
      case "Moda/Beleza":
        return "img-cat-moda_beleza";
      case "Griôs":
        return "img-cat-grios"
      case "Música":
        return "img-cat-musica"
      case "Gastronomia":
        return "img-cat-gastronomia"
      case "Artefatos Religiosos":
        return "img-cat-artefatos_religiosos"
      case "Flora":
        return "img-cat-flora"
      case "Artes Visuais":
        return "img-cat-artes_visuais"
      case "Dança":
        return "img-cat-danca"
      case "Patrimônio":
        return "img-cat-patrimonio"
      default:
        return "img-cat-patrimonio"
    }
  }
}
