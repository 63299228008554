import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ServicoExternoComponent } from './servico-externo/servico-externo.component';
import { RouterModule, Routes } from '@angular/router';
import { LayoutInternoComponent } from '../layout/layout-interno/layout-interno.component';
import { AvaliacaoEstrelaModule } from '../avaliacao-estrela/avaliacao-estrela.module';

const routes: Routes = [
  {
    path: 'sp',
    component: LayoutInternoComponent, children: [{ path: '', component: ServicoExternoComponent }]
  }
]

@NgModule({
  declarations: [ServicoExternoComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    AvaliacaoEstrelaModule,
    FormsModule
  ]
})
export class ServicoExternoModule { }
