<!-- ======================conteudo===================== -->
<main>
  <div class="conteudo">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item redirect" routerLink="/home">Página Inicial</li>
        <li class="breadcrumb-item active" aria-current="page">Rodada de Negócio</li>
      </ol>
    </nav>

    <h3>Pessoas/Entidades</h3>
    <!-- =============LISTA PRODUTOS=================== -->
    <div class="lista-produtos pagina">

      <div class=" filtro form-row align-items-center">
        <div class="d-md-flex w-100 mb-2">
          <label for="" class=" d-flex align-items-center">
            Filtros
          </label>

          <div class="col">
            <div class="form-group">
              <input type="text" [(ngModel)]="filtro.nome" class="form-control" placeholder="Nome">
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <input type="text" [(ngModel)]="filtro.nome_fantasia" class="form-control" placeholder="Nome Fantasia">
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <input type="text" [(ngModel)]="filtro.cidade" class="form-control" placeholder="Cidade">
            </div>
          </div>
          <div class="col pr-0">
            <div class="form-group">
              <input type="text" [(ngModel)]="filtro.cpf" class="form-control" placeholder="CPF/CNPJ" mask="CPF_CNPJ">
            </div>
          </div>
        </div>
        <div class="d-md-flex w-100">
          <div class="col pl-0">
            <div class="form-group group-select">
              <select class="form-control" [(ngModel)]="filtro.tipo">
                <option value="">Escolha o tipo</option>
                <option *ngFor="let opt of tipoCadastroList" [value]="opt.id">
                  {{ opt.nome }}
                </option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group group-select">
              <select class="form-control" [(ngModel)]="filtro.id_categoria" (ngModelChange)="getSubcategoriaList()">
                <option value="">Categoria</option>
                <option *ngFor="let opt of categoriaList" [value]="opt.id">
                  {{ opt.nome }}
                </option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group group-select">
              <select class="form-control " [(ngModel)]="filtro.id_subcategoria"
                [disabled]="subcategoriaDisabledToggle">
                <option value="">Subcategoria</option>
                <option *ngFor="let opt of subcategoriaList" [value]="opt.id">
                  {{ opt.nome }}
                </option>
              </select>
            </div>
          </div>
          <button class="btn btn-transparent" (click)="filtrar()">
            <i class="fas fa-filter"></i>
            <span>filtrar</span>
          </button>
        </div>



        <div class="custom-control custom-checkbox custom-control-inline" data-toggle="collapse" role="button"
          aria-expanded="false" aria-controls="">
          <input type="checkbox" class="custom-control-input" id="check1" [checked]="filtro.semProdutoServico"
            (change)="filtro.semProdutoServico = !filtro.semProdutoServico">
          <label class="custom-control-label" for="check1">
            <p>
              Pessoa/entidade sem produtos ou serviços cadastrados.
            </p>
          </label>
        </div>
      </div>




      <button type="submit" *permission="['add-people']" class="btn btn-padrao btn-sm mb-5" (click)="criarPessoa()">
        <i class="fas fa-user-friends"></i>
        cadastrar pessoa/entidade
      </button>

      <div class="d-flex justify-content-center" *ngIf="pessoas.rows.length <= 0">
        <span class="mt-5">Sem registros</span>
      </div>
      <div *ngIf="pessoas.rows.length > 0">
        <h4> Lista de Pessoas/Entidades</h4>

        <ul class="list">
          <li *ngFor="let pessoa of pessoas.rows; let i = index">
            <div class="image">
              <img src="{{pessoa.templateFoto}}" alt="">
            </div>
            <div class="texto">
              <h5>{{pessoa.nome_fantasia}}</h5>
              <h6>{{pessoa.nome}}</h6>
              <ul>
                <li>
                  <strong>CPF/CNPJ: </strong>
                  {{pessoa.cpf | mask: 'CPF_CNPJ'}}
                </li>
                <li>
                  <!-- <strong>Cidade:</strong>
                              {{pessoa.Cidade.nome}}-{{pessoa.Cidade.Uf.sigla}} -->
                  <strong>Telefone:</strong>
                  {{pessoa.celular | mask: '(99)9 9999-9999'}}
                </li>
              </ul>
            </div>
            <div class="botoes">
              <button type="submit" *permission="['edit-people']" class="btn btn-transparent" title="Editar"
                (click)="editarPessoa(pessoa)">
                <i class="fas fa-edit"></i>
              </button>
              <button type="submit" class="btn btn-transparent" title="Lista de Produtos/Serviços"
                (click)="listagemProdutosServicos(pessoa)">
                <i class="fas fa-list-ul"></i>
              </button>
              <div *ngIf="!pessoa.ativo">
                <button *permission="['active-people']" type="submit" class="btn btn-transparent" title="Ativar"
                  (click)="mudarStatusUsuario(pessoa, 'Ativar')">
                  <i class="fas fa-check"></i>
                </button>
              </div>
              <div *ngIf="pessoa.ativo">
                <button *permission="['inactive-people']" type="submit" class="btn btn-transparent" title="Inativar"
                  (click)="mudarStatusUsuario(pessoa, 'Inativar')">
                  <i class="fas fa-ban"></i>
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div class="d-flex justify-content-center">
          <nav>
            <ul class="pagination">
              <li class="page-item {{ paginaAtual == 1 ? 'disabled' : ''}}" *ngIf="pessoas.rows.length > 0">
                <a class="page-link" *ngIf="paginaAtual == 0" style="cursor: pointer;"><i
                    class="fas fa-chevron-left"></i></a>
                <button class="page-link" (click)="getPessoasList(paginaAtual - 1)" [disabled]="paginaAtual == 1">
                  <i class="fas fa-chevron-left"></i>
                  <div *ngIf="qtdPaginas[0] != 1">...</div>
                </button>
              </li>
              <li class="page-item " *ngFor="let opt of qtdPaginas;">
                <a class="page-link active" *ngIf="paginaAtual == opt" style="cursor: pointer;">{{ opt }}</a>
                <button class="page-link" (click)="getPessoasList(opt)" *ngIf="paginaAtual != opt">
                  {{ opt }}
                </button>
              </li>
              <li class="page-item {{ paginaAtual == pessoas.qtdTotalPaginas ? 'disabled' : ''}}"
                *ngIf="pessoas.rows.length > 0">
                <a class="page-link" *ngIf="paginaAtual == (qtdPaginas-1)" style="cursor: pointer;"><i
                    class="fas fa-chevron-right"></i></a>
                <button class="page-link" (click)="getPessoasList(paginaAtual + 1)"
                  [disabled]="paginaAtual == pessoas.qtdTotalPaginas">
                  <div *ngIf="qtdPaginas[qtdPaginas.length - 1] != pessoas.qtdTotalPaginas">...</div>
                  <i class="fas fa-chevron-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <!-- =============FIM LISTA PRODUTOS=================== -->
  </div>
  <!-- =====================FIM =conteudo===================== -->
</main>
