<!-- ===============BARRA USUARIO=========================== -->
<div class="bg-azul barra-titulo">
    <h2>O que procura?</h2>
</div>
<!-- ==============FIM==BARRA USUARIO=========================== -->



<main>
    <!-- ======================conteudo===================== -->
    <div class="conteudo">

        <!-- =========================BLOCOS PESQIOSA=============== -->
        <div class="blocos-pesquisa">

            <aside class="barra-info">
                <div class="h-100">

                    <div class="texto">
                        <div class="barra-info-head">
                            <h4>
                                {{termoPessoa}}
                            </h4>
                            <h4>
                                {{termoServico}}
                            </h4>
                            <p>{{list.count}} resultados</p>
                        </div>

                        <div class="form-group group-select">
                            <label for="">Ordenar</label>
                            <select class="form-control " [(ngModel)]="filter.order"
                                (ngModelChange)="fillList(paginaAtual)">
                                <option value="ASC">Ordem crescente</option>
                                <option value="DESC">Ordem decrescente</option>
                            </select>
                        </div>

                        <label for="">Categorias</label>
                        <!-- <a *ngFor="let cat of list.Categoria">{{cat.nome}} ({{cat.qtd}})</a> -->
                        <div *ngFor="let cat of list.Categoria">
                            <a [routerLink]="" (click)="resultadoBusca(cat.id)" style="cursor: pointer;">{{cat.nome}} ({{cat.qtd}})</a>
                        </div>
                    </div>
                </div>

            </aside>
            <div class="col">

                <p>Resultado da busca</p>

                <div class="lista-pesquisa">

                    <div class="d-flex justify-content-center" *ngIf="list.rows <= 0">
                        <span class="mt-5">Sem registros</span>
                    </div>

                    <!-- =============CARD============= -->
                    <div class="card" *ngFor="let el of list.rows">

                        <div class="like">
                            <input type="checkbox" class="custom-control-input" [checked]='el.favorito'
                                id="{{'chk'+el.id}}" (click)="AddFavorite(el, $event)">
                            <label class="custom-control-label" for="{{'chk'+el.id}}">
                                <i class="far fa-heart"></i>
                            </label>
                        </div>
                        <img src="{{el.templateFoto}}" alt="">
                        <div class="card-body">
                            <h5 class="card-title">{{el.nome}}</h5>
                            <span *ngIf="el.entidade == 'S'">{{el.preco}}</span>
                            <p class="card-text">{{el.descricao}}</p>
                            <a [routerLink]="" class="card-link" (click)="Produtos(el.id)" *ngIf="el.entidade == 'P'" style="cursor: pointer;">
                                + Produtos</a>
                        </div>
                        <div class="card-footer" *ngIf="el.entidade == 'S'">
                            <button type="submit" class="btn btn-padrao"
                                (click)="clickInteresse(el)">
                                <i class="fas fa-smile"></i>
                                Tenho interesse!
                            </button>
                        </div>
                        <div class="card-footer" *ngIf="el.entidade == 'P'">
                            <div class="flex">
                                <a href="https://maps.google.com/maps?mapclient=embed&q={{el.cep}}" target="_blank" style="cursor: pointer;">
                                    <i class="fas fa-map-marked-alt"></i>
                                </a>
                                <a [href]="" *ngIf="el.telefone" style="cursor: pointer;">
                                    <i class="fas fa-phone"></i>
                                </a>
                                <!-- <a href="https://web.whatsapp.com/send?phone=+55 {{el.celular}}" target="_blank" style="cursor: pointer;"> -->
                                    
                                <a href="{{URL_WHATSAPP.replace('<numero>', el.celular)}}" target="_blank" style="cursor: pointer;">
                                    <i class="fab fa-whatsapp"></i>
                                </a>
                                <a href="mailto:{{el.email}}" target="_blank" style="cursor: pointer;">
                                    <i class="fas fa-envelope"></i>
                                </a>
                            </div>
                            <app-avaliacao-estrela pesId={{el.id}}></app-avaliacao-estrela>
                        </div>
                    </div>
                    <!-- =============FIM CARD============= -->

                </div>

                <div class="d-flex justify-content-center">
                    <nav>
                        <ul class="pagination">
                            <li class="page-item {{ paginaAtual == 1 ? 'disabled' : ''}}" *ngIf="list.rows.length > 0">
                                <a class="page-link" *ngIf="paginaAtual == 0" style="cursor: pointer;"><i class="fas fa-chevron-left"></i></a>
                                <button class="page-link" (click)="fillList(paginaAtual - 1)"
                                    [disabled]="paginaAtual == 1">
                                    <i class="fas fa-chevron-left"></i>
                                    <div *ngIf="qtdPaginas[0] != 1">...</div>
                                </button>
                            </li>
                            <li class="page-item " *ngFor="let opt of qtdPaginas;">
                                <a class="page-link active" *ngIf="paginaAtual == opt" style="cursor: pointer;">{{ opt }}</a>
                                <button class="page-link" (click)="fillList(opt)" *ngIf="paginaAtual != opt">
                                    {{ opt }}
                                </button>
                            </li>
                            <li class="page-item {{ paginaAtual == list.qtdTotalPaginas ? 'disabled' : ''}}"
                                *ngIf="list.rows.length > 0">
                                <a class="page-link" *ngIf="paginaAtual == (qtdPaginas-1)" style="cursor: pointer;"><i
                                        class="fas fa-chevron-right"></i></a>
                                <button class="page-link" (click)="fillList(paginaAtual + 1)"
                                    [disabled]="paginaAtual == list.qtdTotalPaginas">
                                    <div *ngIf="qtdPaginas[qtdPaginas.length - 1] != list.qtdTotalPaginas">...</div>
                                    <i class="fas fa-chevron-right"></i>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>

            </div>

        </div>
        <!-- ========================fim =BLOCOS PESQIOSA=============== -->
    </div>
    <!-- =====================FIM =conteudo===================== -->

</main>
