import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService extends MainService {

  _url = `${super.getUrl()}email/`;

  constructor(private http: HttpClient) {
    super();
  }

  sendMail(filter: any): Observable<any[]> {
    const url = `${this._url}sendToSuport`;
    return this.http.post<any[]>(url, filter, this.getOptions())
  }
}
