<!-- <div class="title-header slogan">
    <div class="center">
      <h2>{{ sloganPt1 }} <span>{{ sloganPt2 }}</span> {{ sloganPt3 }}</h2>
      <button type="button" class="btn btn-padrao btn-sm" (click)="openLink('/servico')" *ngIf="logado && botao && this.pessoa.nome_perfil === 'Pessoa'">
        <i class="{{classBotao}}"></i>
        {{botao}}
      </button>
      <button type="button" class="btn btn-padrao btn-sm" (click)="clickPreCadastro()" *ngIf="!logado && botao">
          <i class="{{classBotao}}"></i>
          {{ botao }}
      </button>
    </div>
</div> -->

<div class="banner-home" *ngIf="pt">
  <div class="center">
    <h2>{{ sloganPt1 }} <span>{{ sloganPt2 }}</span> {{ sloganPt3 }}</h2>
    <button type="button" class="btn btn-transparent" (click)="clickPreCadastro()">
      <img src="../../../assets/images/bt-crie-vitrine.svg" alt="">
    </button>
  </div>
</div>

<div class="banner-home" *ngIf="!pt">
  <div class="center">
    <div class="en">
      <h2>{{ sloganPt1 }} <span>{{ sloganPt2 }}</span> {{ sloganPt3 }}</h2>
    </div>
  </div>
</div>
