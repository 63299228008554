import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-evento-detalhe',
  template: `
    <div class="modal-content modal-eventos">
      <div class="modal-header">
        <button type="button" class="close" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
        <h3>
          {{evento.Titulo}}
        </h3>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-header" *ngIf="evento.Imagem">
            <img [src]="evento.Imagem" alt="">
          </div>
          <div class="card-body">
            <div class="" *ngIf="language !== 'us-en'">
              <p>
                <strong>Data:</strong> de {{evento.DtInicial.substring(0, 5)}} a {{evento.DtFinal.substring(0, 5)}}
              </p>
              <p>
                <strong>Horário:</strong> {{evento.HoraInicial}} às {{evento.HoraFinal}}
              </p>
              <p>
                <strong>Local:</strong> {{evento.Local}}
              </p>
              <p *ngIf="evento.Valor">
                <strong>Valor (R$):</strong> {{ evento.Valor }}
              </p>
              <div class="flex">
                <a class="btn btn-transparent" [href]='evento.Url' target="_blank" style="cursor: pointer;">
                  <i class="fas fa-link"></i> Mais informações
                </a>
              </div>
            </div>
            <div class="" *ngIf="language === 'us-en'">
              <p>
                <strong>Date:</strong> from {{evento.DtInicial.substring(0, 5)}} to {{evento.DtFinal.substring(0, 5)}}
              </p>
              <p>
                <strong>Hour:</strong> {{evento.HoraInicial}} to {{evento.HoraFinal}}
              </p>
              <p>
                <strong>Local:</strong> {{evento.Local}}
              </p>
              <p *ngIf="evento.Valor">
                <strong>Value (R$):</strong> {{ evento.Valor }}
              </p>
              <div class="flex">
                <a class="btn btn-transparent" [href]='evento.Url' target="_blank">
                  <i class="fas fa-link"></i> more
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="texto  scrollbar-inner">
        <div class="aumentar-fonte text-left">
          <a href="" class="btn" style="cursor: pointer;">
            A
            <i class="fas fa-plus"></i>
          </a>
          <a href="" class="btn" style="cursor: pointer;">
            A
            <i class="fas fa-minus"></i>
          </a>
        </div>
          <h5>{{ evento.Titulo }}</h5>
          <div [innerHtml]="conteudo"></div>
        </div>
      </div>
    </div>
  `
})
export class ModalEventoDetalheComponent {
  @Input() evento: any;
  @Input() conteudo: any;
  @Input() modalRef: any;
  @Input() language: any;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  closeModal() {
    this.modalRef.close();
  }

}
