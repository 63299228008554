import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../.env";
@Injectable({
  providedIn: 'root'
})
export class MainService {

  private url = environment.API;

  getOptions() {
    var device = JSON.parse(localStorage.getItem('device-id') || 'false');

    if (device) {
      return {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': device.v })
      }
    }
    else {
      return {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      }
    }
  }

  getUrl(): string {
    return this.url;
  }

}
