import { environment } from '.env';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AvisoDeslogadoComponent } from 'src/app/aviso-deslogado/aviso-deslogado.component';
import { AuthService } from 'src/app/service/auth.service';
import { FavoritoService } from 'src/app/service/favorito.service';

@Component({
  selector: 'app-detalhe',
  templateUrl: './detalhe.component.html',
  styleUrls: ['./detalhe.component.css']
})
export class DetalheComponent implements OnInit {

  pessoa: any;

  tamanhosFonts = ['f-10', 'f-12', 'f-14', 'f-16', 'f-18', 'f-20', 'f-24']

  indicetamanhoAtual = 3;

  acessibilidade = this.tamanhosFonts[this.indicetamanhoAtual]

  exibeFavoritos = false

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private modalService: NgbModal,
    private favoritoService: FavoritoService
  ) { }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.exibeFavoritos = true
      let filter = {
        id_usuario: this.authService.getUser().id
      }
      this.favoritoService.list(filter).subscribe(next => {
        next.map((favorito: any) => {
          if (favorito.id_pessoa == this.pessoa.id) {
            this.pessoa.favorito = true
          }
        })
      })
    }
  }

  clickWhatsapp(celular: any) {
    //window.open(`https://web.whatsapp.com/send?phone=+55 ${celular}`, '_blak')
    window.open(`${environment.URL_WHATSAPP}${celular}`, '_blak')
  }

  sizeUpFont() {
    this.indicetamanhoAtual = this.indicetamanhoAtual < 6 ? (++this.indicetamanhoAtual) : this.indicetamanhoAtual
    this.acessibilidade = this.tamanhosFonts[this.indicetamanhoAtual]
  }

  sizeDownFont() {
    this.indicetamanhoAtual = this.indicetamanhoAtual > 0 ? (--this.indicetamanhoAtual) : this.indicetamanhoAtual
    this.acessibilidade = this.tamanhosFonts[this.indicetamanhoAtual]
  }

  AddFavorite(id: any, event: any) {
    event.preventDefault();
    //this.modalService.dismissAll();
    if (this.authService.isLoggedIn()) {
      var ehFavorito = this.pessoa.favorito
      this.pessoa.favorito = !this.pessoa.favorito
      if (ehFavorito) {
        this.favoritoService.delete({ id_usuario: this.authService.getUser().id, id_pessoa: id }).subscribe();
      }
      else {
        this.favoritoService.add({ id_usuario: this.authService.getUser().id, id_pessoa: id }).subscribe();
      }
    }
    else {
      this.modalService.open(AvisoDeslogadoComponent);
    }
  }

}
