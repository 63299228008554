import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guard/auth.guard';
import { LayoutLogadoComponent } from "../layout/layout-logado/layout-logado.component";
import { ServicoComponent } from "../servico/servico.component";

const routes: Routes = [
  {
    path: 'servico',
    component: LayoutLogadoComponent,
    children: [
      { path: '', component: ServicoComponent, data: { tipoTela: 'cadastro' } }
    ]
  },
  {
    path: 'servico/:id',
    component: LayoutLogadoComponent,
    children: [
      { path: '', component: ServicoComponent }
    ]
  },
  {
    path: 'meus-produtos-servicos',
    component: LayoutLogadoComponent,
    children: [
      { path: '', component: ServicoComponent, canActivate: [AuthGuard], data: { role: "Pessoa" } }
    ]
  },
  {
    path: 'adm-servico',
    component: LayoutLogadoComponent,
    children: [
      { path: '', component: ServicoComponent, canActivate: [AuthGuard], data: { tipoTela: 'cadastro' } }
    ]
  },
  {
    path: 'adm-servico-lista',
    component: LayoutLogadoComponent,
    children: [
      { path: '', component: ServicoComponent, canActivate: [AuthGuard], data: { tipoTela: 'lista' } }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class ServicoRoutingModule { }
