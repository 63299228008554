<div class="conteudo">
  <h2>Relatórios</h2>
  
  <div class="lista-produtos pagina">
    <h3>Filtros</h3>
    <div class="row flex-wrap mb-5 border-bottom w-100 pb-2">
      <div class="col-md-4">
        <div class="form-group">
          <label for="">Nome do ator TEA:</label>
          <input [(ngModel)]="filtro.nome" type="text" class="form-control" placeholder="Digite o nome do ator TEA"
            maxlength="150">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">CPF/CNPJ:</label>
          <input [(ngModel)]="filtro.cpf" type="text" class="form-control" placeholder="Digite o CNPJ do ator TEA"
            mask="CPF_CNPJ">
        </div>
      </div>

      <div class="flex p-0 col-md-5 ">
        <div class="col">
          <div class="form-group">
            <label for="">Data inicial de cadastro:</label>
            <input [(ngModel)]="filtro.dataInicialTela" class="form-control" ngbDatepicker 
            #d="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}" placeholder="dd/mm/aaa"
            [maxDate]="{year: currentDate.year, month: 12, day: 31}" (click)="d.toggle()">
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="">Data final de cadastro:</label>
            <input [(ngModel)]="filtro.dataFinalTela" class="form-control" ngbDatepicker 
            #d2="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}" placeholder="dd/mm/aaa"
            [maxDate]="{year: currentDate.year, month: 12, day: 31}" (click)="d2.toggle()">
          </div>
        </div>
      </div>



      <div class="d-sm-flex p-0 col-md-6">
        
        <div class="col-md-4 col-xs-12">
          <div class="form-group">
            <label for="">Afroestima:</label>
            <select [(ngModel)]="filtro.afroestima" class="form-control ">
              <option *ngFor="let opt of listSIMNAO" [value]="opt.id">{{opt.nome}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-4 col-xs-12">
          <div class="form-group">
            <label for="">Possui produto/serviço:</label>
            <select [(ngModel)]="filtro.possui_produto" class="form-control" (change)="filtroCategoria()">
              <option *ngFor="let opt of listSIMNAO" [value]="opt.id">{{opt.nome}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-4 col-xs-12">
          <div class="form-group">
            <label for="">Tipo:</label>
            <select [(ngModel)]="filtro.tipo" class="form-control" [disabled]="naoPossuiServico">
              <option *ngFor="let opt of listTipo" [value]="opt.id">{{opt.nome}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Categoria:</label>
          <select [(ngModel)]="filtro.id_categoria" class="form-control" (ngModelChange)="getSubcategoriaList()" [disabled]="naoPossuiServico">
            <option *ngFor="let opt of listCategoria" [value]="opt.id">{{opt.nome}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Subcategoria:</label>
          <select [(ngModel)]="filtro.id_subcategoria" class="form-control " [disabled]="subcategoriaDisabledToggle">
            <option *ngFor="let opt of listSubCategoria" [value]="opt.id">{{opt.nome}}</option>
          </select>
        </div>
      </div>
      
      <div class="col-md-4 col-xs-12">
        <div class="form-group">
          <label for="">Agente cadastrante:</label>
          <select [(ngModel)]="filtro.id_cadastrante" class="form-control">
            <option *ngFor="let opt of listCadastrante" [value]="opt.id">{{opt.nome}}</option>
          </select>
        </div>
      </div>   

      <div class="col-md-4 col-xs-12">
        <div class="form-group">
          <label for="">Rodada de negócio:</label>
          <select [(ngModel)]="filtro.id_rodada" class="form-control">
            <option *ngFor="let opt of listRodadaNegocio" [value]="opt.id">{{opt.titulo}}</option>
          </select>
        </div>
      </div>

    </div>
    <div class="mb-5 border-bottom w-100 pb-2">
      <h5>
        <i class="fas fa-file-alt"></i>
        LISTA DE ATORES TEA
      </h5>
      <button type="button" class="btn btn-danger btn-lg" (click)="RelatorioPessoas('pdf')" [disabled]="botaoClicado">
        <i class="fas fa-file-pdf"></i>
        RELATÓRIO PESSOAS PDF
      </button>
      <button type="button" class="btn btn-padrao btn-lg ml-md-2" (click)="RelatorioPessoas('xls')" [disabled]="botaoClicado">
        <i class="fas fa-file-excel"></i>
        RELATÓRIO PESSOAS XLS
      </button>
    </div>

    <div class="mb-5 border-bottom w-100 pb-2" *ngIf="!naoPossuiServico">
      <h5>
        <i class="fas fa-file-alt"></i>
        LISTA DE PRODUTOS E SERVIÇOS DOS ATORES TEA
      </h5>
      <button type="button" class="btn btn-danger btn-lg" (click)="RelatorioServicos('pdf')" [disabled]="botaoClicado">
        <i class="fas fa-file-pdf"></i>
        RELATÓRIO PROD/SERV PDF
      </button>
      <button type="button" class="btn btn-padrao btn-lg ml-md-2" (click)="RelatorioServicos('xls')" [disabled]="botaoClicado">
        <i class="fas fa-file-excel"></i>
        RELATÓRIO PROD/SERV XLS
      </button>
      <button type="button" class="btn btn-padrao btn-lg ml-md-2" (click)="RelatorioServicos('xls', true)" [disabled]="botaoClicado">
        <i class="fas fa-file-excel"></i>
        RELATÓRIO PROD/SERV XLS (SIMPLIFICADO)
      </button>
    </div>

    <h2 *ngIf="false"> Exportações</h2>


    <!-- =============TABELA LOGS=================== -->
    <div class="table-responsive" *ngIf="false">
      <table class="table tabela-relatorio">

        <tbody>
          <tr>
            <td>
              <i class="fas fa-file-alt"></i>
              Exportar lista de atores TEA
            </td>
            <td width="50">
              <button class="btn-icon">
                <i class="fas fa-file-import"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <i class="fas fa-file-alt"></i>
              Exportar lista de atores TEA
            </td>
            <td width="50">
              <button class="btn-icon">
                <i class="fas fa-file-import"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <i class="fas fa-file-alt"></i>
              Exportar lista de atores TEA
            </td>
            <td width="50">
              <button class="btn-icon">
                <i class="fas fa-file-import"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

</div>
<!-- =====================FIM =conteudo===================== -->