import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class AvaliarService extends MainService {

  constructor(private http: HttpClient) { super(); }

  add(avaliacao: any) {
    const url = `${super.getUrl()}avaliacao/add`;
    return this.http.post<any>(url, avaliacao, this.getOptions());
  }

  get(avaliacao: any) {
    const url = `${super.getUrl()}avaliacao/get`;
    return this.http.post<any>(url, avaliacao, this.getOptions());
  }

  getMedia(avaliacao: any) {
    const url = `${super.getUrl()}avaliacao/getMedia`;
    return this.http.post<any>(url, avaliacao, this.getOptions());
  }

  getMelhoresAvaliados() {
    const url = `${super.getUrl()}avaliacao/getMelhoresAvaliados`;
    return this.http.post<any[]>(url, {}, this.getOptions());
  }

  list(id_pessoa: string) {
    const url = `${super.getUrl()}avaliacao/list`;
    return this.http.post<any[]>(url, {id_pessoa}, this.getOptions());
  }

  // delete(favorito: any) {
  //   const url = `${super.getUrl()}favorito/delete`;
  //   return this.http.post<any>(url, favorito, this.getOptions());
  // }

  // list(filter: any) {
  //   const url = `${super.getUrl()}favorito/list`;
  //   return this.http.post<any>(url, filter, this.getOptions());
  // }
}
