import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guard/auth.guard';
import { LayoutLogadoComponent } from '../layout/layout-logado/layout-logado.component';
import { ContaListaComponent } from './conta-lista/conta-lista.component';
import { ContaCadastroComponent } from './conta-cadastro/conta-cadastro.component';

const routes: Routes = [
  { 
    path: 'lista-gerenciar-contas', component: LayoutLogadoComponent, 
    children: [{ 
      path: '', component: ContaListaComponent, canActivate: [AuthGuard], data: { role: 'Administrador', tipo: 'interno' } 
    }] 
  },
  { 
    path: 'conta-cadastro', component: LayoutLogadoComponent, 
    children: [{ 
      path: '', component: ContaCadastroComponent, canActivate: [AuthGuard], data: { role: 'Administrador', tipo: 'interno' } 
    }] 
  },
  { 
    path: 'editar-dados-usuario', component: LayoutLogadoComponent, 
    children: [{ 
      path: '', component: ContaCadastroComponent, canActivate: [AuthGuard], data: { tipo: 'interno', updateAuth: true } 
    }] 
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContaRoutingModule { }
