import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth.service';
import { PessoaService } from 'src/app/service/pessoa.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { GerenciadorConteudoService } from 'src/app/service/gerenciador-conteudo.service';
import { LoginComponent } from 'src/app/login/login.component';
import { PrecadastroComponent } from 'src/app/precadastro/precadastro.component';
import { ModalTermosComponent } from 'src/app/modal-termos/modal-termos.component';
import { ModalOqueComponent } from 'src/app/modal-oque/modal-oque.component';
import { ModalNewsletterComponent } from 'src/app/modal-newsletter/modal-newsletter.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  modalRef: any;
  logado = false;
  pessoa: any = {};
  optionsLogado: any = [];
  optionsDeslogado: any = [];
  cookieAceito = false;

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private router: Router,
    private toastr: ToastrService,
    private pessoaService: PessoaService,
    private usuarioService: UsuarioService,
    private gerenciadorConteudo: GerenciadorConteudoService,
  ) {
    this._login = {};
    this._login.email = "";
    this._login.senha = "";
  }

  ngOnInit(): void {
    this.verificaAceitarCookie()

    if (this.authService.isLoggedIn()) {
      this.logado = true;
      this.pessoa = this.authService.getUser();
    }

    this.gerenciadorConteudo.getMenu(
      'rodape_deslogado_prodetur',
      this.authService.getLanguage()
    ).subscribe((result: any) => {
      if (result.error) {
        console.log('rodape deslogado ', result.error);
        this.optionsDeslogado = [];
        return;
      }
      this.optionsDeslogado = result.Itens;
    });

    this.gerenciadorConteudo.getMenu(
      'rodape_logado_prodetur',
      this.authService.getLanguage()
    ).subscribe((result: any) => {
      if (result.error) {
        console.log('rodape logado ', result.error);
        this.optionsLogado = [];
        return;
      }
      this.optionsLogado = result.Itens;
    });
  }

  verificaAceitarCookie() {
    this.cookieAceito = JSON.parse(localStorage.getItem('cookieAceito') || 'false')
  }

  aceitarCookies() {
    localStorage.setItem('cookieAceito', 'true')
    this.verificaAceitarCookie()
  }

  openLink(option: any): void {
    if (option && (option.URL == 'cadastro' || option.URL == 'acesso')) {
      const content = option.URL == 'cadastro' ? 'precadastro' : 'login';
      this.open(content);
    } else if (option.URL == 'oque') {
      this.open(option.URL);
    } else if (option.URL == 'newsletter') {
      this.opennewsletter();
    } else {
      this.router.navigate([option.URL]);
    }
  }

  open(content: any): void {
    if (content === 'precadastro') {
      this.modalService.open(PrecadastroComponent);
    } else if (content === 'login') {
      this.modalService.open(LoginComponent);
    } else if (content === 'aceite') {
      this.modalRef = this.modalService.open(ModalTermosComponent);

      this.modalRef.componentInstance.modalRef = this.modalRef;
      this.modalRef.componentInstance.language = this.authService.getLanguage();
      this.modalRef.componentInstance.tela = 'footer';
    } else if (content === 'oque') {
      this.modalRef = this.modalService.open(ModalOqueComponent);

      this.modalRef.componentInstance.modalRef = this.modalRef;
      this.modalRef.componentInstance.language = this.authService.getLanguage();
    }
  }

  _login: any;
  inputPassword = "password";

  Entrar(): void {
    this.authService.login(this._login).subscribe(
      next => {
        this.authService.setUser(next);
        this.modalService.dismissAll();
        this.reload();
        this.toastr.success(`Bem-vindo(a) ${next.nome}`, 'Sucesso');
      }
    );
  }

  togglePassword(): void {
    this.inputPassword = this.inputPassword == 'password' ? 'text' : 'password';
  }

  Sair(): void {
    this.authService.logout();
    this.reload();
    this.router.navigate(['home']);
  }

  reload(): void {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  IrParaCadastro(precadastro: any): void {
    this.modalService.dismissAll();
    this.open(precadastro);
  }

  IniciarCadastro(): void {
    if (!this.pessoa.email) {
      this.toastr.error('Por favor, informe o E-mail', 'Erro');
      return;
    }

    if (!this.pessoa.cpf) {
      this.toastr.error('Pro favor, informe o CPF/CNPJ', 'Erro');
      return;
    }

    this.pessoaService.getPessoas(this.pessoa).subscribe(
      response => {
        if (Array.isArray(response) && response.length > 0) {
          if (response[0].senha) {
            this.toastr.error('Já existe um registro completo no sistema. Entre com seu e-mail e senha em ACESSE', 'Erro');
            return;
          }
          this.modalService.dismissAll();
          this.pessoaService.pessoa = response[0];
          this.router.navigate(['pessoa']);
        } else {
          this.modalService.dismissAll();
          this.pessoaService.pessoa = this.pessoa;
          this.router.navigate(['pessoa']);
        }
      },
      error => {
        this.toastr.error(error, 'Erro');
      }
    );
    this.pessoaService.pessoa;
  }

  criarPessoa(): void {
    this.pessoaService.isFromRoute = 'home';
    this.usuarioService.usuario = '';
    this.router.navigate(['adm-cadastrar-dados-pessoais']);
  }

  resultadoBusca(subid: any): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['resultado-da-busca'], { queryParams: { sub: subid } });
  }
  opennewsletter() {
    this.modalRef = this.modalService.open(ModalNewsletterComponent);
  }
}
