<main>
  <!-- ======================conteudo===================== -->
  <div class="conteudo">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb" *ngIf="!search">
        <li class="breadcrumb-item redirect">{{menuService.RetornarDestaqueGerenciador('pagina_inicial').Titulo}}</li>
        <li class="breadcrumb-item" *ngIf="!subcategoria && !categoria"> {{ titulo ? titulo :
          menuService.RetornarDestaqueGerenciador('produtos').Titulo}}</li>
        <li class="breadcrumb-item {{subcategoria ? '' : 'active'}}" *ngIf="!titulo">{{categoria}}</li>
        <li class="breadcrumb-item" *ngIf="subcategoria && !titulo">{{subcategoria}}</li>
      </ol>
      <ol class="breadcrumb" *ngIf="search">
        <li class="breadcrumb-item redirect">{{menuService.RetornarDestaqueGerenciador('pagina_inicial').Titulo}}</li>
        <li class="breadcrumb-item" *ngIf="!subcategoria && !categoria"> {{ titulo ? titulo :
          menuService.RetornarDestaqueGerenciador('produtos').Titulo}}</li>
        <li class="breadcrumb-item active">{{search}}</li>
      </ol>
    </nav>
    <div class="header-title">
      <h3>{{subcategoria ? subcategoria : (categoria ? categoria : (titulo ? titulo : 'Resultado da busca'))}}</h3>
      <div>
        <select class="form-control" [(ngModel)]="filtroTipo" (ngModelChange)="getList(1)">
          <option *ngFor="let opt of filtroTipoList" [value]="opt.key">{{opt.value}}</option>
        </select>
      </div>
    </div>
    <h2 *ngIf="search">{{search}}</h2>

    <!-- ================LISTA RESULTADO SERVICOS===========   -->
    <div class="mb-3" *ngIf="search">
      <div>
        <span>{{resultados}} resultado(s) para <strong>Produto(s) e Serviço(s)</strong>:</span>
      </div>
    </div>

    <div class="d-flex justify-content-center" *ngIf="servicos.count <= 0">
      <span class="mt-5">Sem registros</span>
    </div>
    <ul class="listagem">
      <!-- ================ITEM CARD SERVICO===========   -->
      <li class="card" *ngFor="let servico of servicos.rows">
        <div class="bloco">
          <div class="user menor" (click)="irParaPessoa(servico.id_pessoa)">
            <img src="{{servico.foto_pessoa}}" alt="">
          </div>
          <div class="like" (click)="AddFavorite(servico.id, $event)" *ngIf="exibeFavoritos">
            <input type="checkbox" class="custom-control-input" [checked]='servico.favorito' id="{{'chk'+servico.id}}">
            <label class="custom-control-label" for="{{'chk'+servico.id}}">
              <i class="far fa-heart"></i>
            </label>
          </div>
          <a (click)="clickInteresse(servico, $event)">
            <img src="{{servico.templateFoto}}" alt="">
            <div class="card-body">
              <div class="texto">
                <h3>Tenho interesse!</h3>
              </div>
              <div class="info">
                <strong *ngIf="servico.valor == 0"> </strong>
                <strong *ngIf="servico.valor != 0">{{servico.valor | currency:'BRL'}}</strong>
              </div>
            </div>
          </a>
        </div>
        <div class="card-footer">
          <a (click)="clickInteresse(servico, $event)">
            <h5>{{servico.nome}}</h5>
          </a>
        </div>
      </li>
    </ul>
    <div class="d-flex justify-content-center" *ngIf="servicos.qtdTotalPaginas > 1">
      <nav>
        <ul class="pagination">
          <li class="page-item {{ paginaAtual == 1 ? 'disabled' : ''}}" *ngIf="servicos.count > 0">
            <a class="page-link" *ngIf="paginaAtual == 0"><i class="fas fa-chevron-left"></i></a>
            <button class="page-link" (click)="getList(paginaAtual - 1)" [disabled]="paginaAtual == 1">
              <i class="fas fa-chevron-left"></i>
              <div *ngIf="qtdPaginas[0] != 1">...</div>
            </button>
          </li>
          <li class="page-item " *ngFor="let opt of qtdPaginas;">
            <a class="page-link active" *ngIf="paginaAtual == opt">{{ opt }}</a>
            <button class="page-link" (click)="getList(opt)" *ngIf="paginaAtual != opt">
              {{ opt }}
            </button>
          </li>
          <li class="page-item {{ paginaAtual == servicos.qtdTotalPaginas ? 'disabled' : ''}}"
            *ngIf="servicos.count > 0">
            <a class="page-link" *ngIf="paginaAtual == (qtdPaginas-1)"><i class="fas fa-chevron-right"></i></a>
            <button class="page-link" (click)="getList(paginaAtual + 1)"
              [disabled]="paginaAtual == servicos.qtdTotalPaginas">
              <div *ngIf="qtdPaginas[qtdPaginas.length - 1] != servicos.qtdTotalPaginas">...</div>
              <i class="fas fa-chevron-right"></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>

    <hr class="my-5">

    <!-- ================LISTA RESULTADO PESSOAS===========   -->
    <div class="mb-3" *ngIf="search">
      <div>
        <span>{{resultadosPessoas}} resultado(s) para <strong>Empreendedor(es)</strong>:</span>
        <!-- <h2>{{search}}</h2> -->
      </div>
    </div>

    <div class="d-flex justify-content-center" *ngIf="pessoas.count <= 0 && search">
      <span class="mt-5">Sem registros</span>
    </div>

    <div *ngIf="search">
      <ul class="listagem">
        <!-- ================ITEM CARD PESSOA===========   -->
        <li class="card" *ngFor="let pessoa of pessoas.rows">
          <div class="bloco">
            <div class="like" (click)="AddFavoritePessoa(pessoa.id, $event)" *ngIf="exibeFavoritos">
              <input type="checkbox" class="custom-control-input" [checked]='pessoa.favorito' id="{{'chk'+pessoa.id}}">
              <label class="custom-control-label" for="{{'chk'+pessoa.id}}">
                <i class="far fa-heart"></i>
              </label>
            </div>
            <a (click)="acessarPerfilPessoa(pessoa)">
              <img src="{{pessoa.foto}}" alt="">
              <div class="card-body">
                <div class="texto">
                  <h3>{{pessoa.nome_fantasia}}</h3>
                </div>
                <!-- <div class="info">
                  <strong *ngIf="servico.valor == 0"> </strong>
                  <strong *ngIf="servico.valor != 0">{{servico.valor | currency:'BRL'}}</strong>
                </div> -->
              </div>
            </a>
          </div>
          <div class="card-footer">
            <a (click)="acessarPerfilPessoa(pessoa)">
              <h5>{{pessoa.nome_fantasia}}</h5>
            </a>
          </div>
        </li>
      </ul>
    </div>

    <div class="d-flex justify-content-center" *ngIf="pessoas.qtdPaginasPes > 1">
      <nav>
        <ul class="pagination">
          <li class="page-item {{ paginaAtualPes == 1 ? 'disabled' : ''}}" *ngIf="pessoas.count > 0">
            <a class="page-link" *ngIf="paginaAtualPes == 0"><i class="fas fa-chevron-left"></i></a>
            <button class="page-link" (click)="getList(paginaAtualPes - 1)" [disabled]="paginaAtualPes == 1">
              <i class="fas fa-chevron-left"></i>
              <div *ngIf="qtdPaginasPes[0] != 1">...</div>
            </button>
          </li>
          <li class="page-item " *ngFor="let opt of qtdPaginasPes;">
            <a class="page-link active" *ngIf="paginaAtualPes == opt">{{ opt }}</a>
            <button class="page-link" (click)="getList(opt)" *ngIf="paginaAtualPes != opt">
              {{ opt }}
            </button>
          </li>
          <li class="page-item {{ paginaAtualPes == pessoas.qtdPaginasPes ? 'disabled' : ''}}"
            *ngIf="pessoas.count > 0">
            <a class="page-link" *ngIf="paginaAtualPes == (qtdPaginasPes-1)"><i class="fas fa-chevron-right"></i></a>
            <button class="page-link" (click)="getList(paginaAtualPes + 1)"
              [disabled]="paginaAtualPes == pessoas.qtdPaginasPes">
              <div *ngIf="qtdPaginasPes[qtdPaginasPes.length - 1] != pessoas.qtdPaginasPes">...</div>
              <i class="fas fa-chevron-right"></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <!-- =====================FIM =conteudo===================== -->



</main>
