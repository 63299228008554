import { environment } from '.env';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AvisoDeslogadoComponent } from 'src/app/aviso-deslogado/aviso-deslogado.component';
import { AuthService } from 'src/app/service/auth.service';
import { FavoritoService } from 'src/app/service/favorito.service';
import { HistoricoService } from 'src/app/service/historico.service';

@Component({
  selector: 'app-interesse',
  template: `
<div class="modal-interesse modal-content">
        <div class="modal-header">
            <!-- <h3>{{pessoa.nome}}</h3> -->

            <button type="button" class="btn-close close" (click)="activeModal.dismiss('Cross click')">
                <i class="fas fa-times"></i>
            </button>
        </div>

        <div class="modal-body">

            <div class="card">
                <div class="card-header">
                    <div class="like" (click)="AddFavorito(servicoModal.id, avisoDeslogado, $event)" *ngIf="exibeFavoritos">
                        <input type="checkbox" class="custom-control-input" [checked]='servicoModal.favorito'
                            id="{{'chk'+servicoModal.id}}"                            >
                        <label class="custom-control-label" for="{{'chk'+servicoModal.id}}">
                            <i class="far fa-heart"></i>
                        </label>
                    </div>
                    <img src="{{servicoModal.foto}}" alt="">
                </div>
                <div class="card-body">
                    <h5 class="card-title">{{servicoModal.nome}}</h5>
                    <span class="preco" *ngIf="servicoModal.valor == 0">Valor a Combinar</span>
                    <span class="preco" *ngIf="servicoModal.valor != 0"> {{servicoModal.valor | currency:'BRL'}}</span>
                    <div class="bloco-user">
                        <span class="user maior" (click)="irParaPessoa(pessoa.id)">
                            <img src="{{pessoa.templateFoto}}" alt="">
                        </span>
                        <span class="texto">
                            <small>Comercializado(a) por:</small>
                            <strong>{{pessoa.nome_fantasia}}</strong>
                            <div class="rank">
                                <span class="star {{avaliacaoMedia.resultado >= 1 ? 'active' : ''}}">
                                    <i class="fas fa-star"></i>
                                </span>
                                <span class="star {{avaliacaoMedia.resultado >= 2 ? 'active' : ''}}">
                                    <i class="fas fa-star"></i>
                                </span>
                                <span class="star {{avaliacaoMedia.resultado >= 3 ? 'active' : ''}}">
                                    <i class="fas fa-star"></i>
                                </span>
                                <span class="star {{avaliacaoMedia.resultado >= 4 ? 'active' : ''}}">
                                    <i class="fas fa-star"></i>
                                </span>
                                <span class="star {{avaliacaoMedia.resultado >= 5 ? 'active' : ''}}">
                                    <i class="fas fa-star"></i>
                                </span>
                                <small>({{avaliacaoMedia.total}})</small>
                            </div>
                        </span>
                    </div>

                </div>

            </div>
            <div class="aumentar-fonte">
            <a [href]="" class="btn" style="cursor: pointer;" (click)="sizeUpFont()">
                A
                <i class="fas fa-plus"></i>
            </a>
            <a [href]="" class="btn" style="cursor: pointer;" (click)="sizeDownFont()">
                A
                <i class="fas fa-minus"></i>
            </a>
            </div>
            <p [class]="acessibilidade">{{servicoModal.descricao}}</p>
            <iframe *ngIf="servicoModal.youtube" width="100%" height="315" [src]="servicoModal.youtube | safe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="modal-footer d-flex ">


                <div class="social">
                  <a href="tel:+55{{pessoa.telefone}}" *ngIf="pessoa.telefone" style="cursor: pointer;">
                    <i class="fas fa-phone"></i>
                  </a>
                   <a [href]="pessoa.facebook" *ngIf="pessoa.facebook" target="_blank" style="cursor: pointer;">
                     <i class="fab fa-facebook-f"></i>
                   </a>
                   <a [href]="pessoa.instagram" *ngIf="pessoa.instagram" target="_blank" style="cursor: pointer;">
                     <i class="fab fa-instagram"></i>
                   </a>
                  <a [href]="pessoa.website" *ngIf="pessoa.website" target="_blank" style="cursor: pointer;">
                     <i class="fas fa-globe"></i>
                   </a>
                 </div>
                 <button *ngIf="pessoa.celular" type="button" id="Tag_FaleWhatsApp" class="btn btn-padrao btn-sm" (click)="clickWhatsapp(pessoa.celular)">
                    <i class="fab fa-whatsapp"></i>
                    <span>Fale por WhatsApp</span>
                  </button>
         </div>
    </div>
  `
})
export class InteresseComponent implements OnInit {
  @Input() pessoa: any;
  @Input() servicoModal: any;
  @Input() avaliacaoMedia: any;
  @Input() avisoDeslogado: any;
  @Input() servicos: any;
  @Input() id: any;

  tamanhosFonts = ['f-10', 'f-12', 'f-14', 'f-16', 'f-18', 'f-20', 'f-24']

  indicetamanhoAtual = 3;

  acessibilidade = this.tamanhosFonts[this.indicetamanhoAtual]

  exibeFavoritos = false;

  botaoFavorito = true;

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private modalService: NgbModal,
    private historicoService: HistoricoService,
    private router: Router,
    private favoritoService: FavoritoService
  ) { }

  ngOnInit(): void {
    this.addVistoRecentemente()
    if (this.authService.isLoggedIn()) {
      this.exibeFavoritos = this.authService.getRole() == 'Pessoa'
    }
    this.exibeFavoritos = this.botaoFavorito;
  }

  closeModal() {
    this.activeModal.close();
  }

  AddFavorito(id: any, content: any, event: any) {
    event.preventDefault();
    if (this.authService.isLoggedIn()) {
      let ehFavorito = this.servicoModal.favorito
      this.servicoModal.favorito = !this.servicoModal.favorito
      if (ehFavorito) {
        this.favoritoService.delete({ id_usuario: this.authService.getUser().id, id_servico: id }).subscribe()
      }
      else {
        this.favoritoService.add({ id_usuario: this.authService.getUser().id, id_servico: id }).subscribe()
      }
    }
    else {
      this.modalService.dismissAll()
      this.modalService.open(AvisoDeslogadoComponent);
    }
  }

  open(content: any) {
    this.modalService.open(content).result.then((result: any) => {
      //this.closeResult = `Closed with: ${result}`;
      console.log('modal abriu', result)
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log('modal erro', reason)
    });
  }

  clickWhatsapp(celular: any) {
    //window.open(`https://web.whatsapp.com/send?phone=+55 ${celular}`, '_blak')
    window.open(`${environment.URL_WHATSAPP}`.replace('<numero>', celular), '_blak')
  }

  addVistoRecentemente() {
    if (this.authService.isLoggedIn()) {
      let historico = {
        id_usuario: this.authService.getUser().id,
        id_servico: this.servicoModal.id
      }
      this.historicoService.add(historico).subscribe();
    }
  }

  irParaPessoa(idPessoa: any) {
    this.closeModal()
    this.router.navigate(["detalhe-pessoa"], { queryParams: { pes: idPessoa } });
  }

  sizeUpFont() {
    this.indicetamanhoAtual = this.indicetamanhoAtual < 6 ? (++this.indicetamanhoAtual) : this.indicetamanhoAtual
    this.acessibilidade = this.tamanhosFonts[this.indicetamanhoAtual]
  }

  sizeDownFont() {
    this.indicetamanhoAtual = this.indicetamanhoAtual > 0 ? (--this.indicetamanhoAtual) : this.indicetamanhoAtual
    this.acessibilidade = this.tamanhosFonts[this.indicetamanhoAtual]
  }

}
