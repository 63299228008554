<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">O que é o AFROBIZ SALVADOR</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body scrollbar-inner" style="text-align: justify;">
    <p>O <strong>AfroBiz Salvador</strong> é uma plataforma online de marketplace para produtos e serviços de
      afroempreendedores do turismo de
      Salvador. Além disso, o projeto realizará Rodadas de Negócios voltadas para o protagonismo econômico dos atores
      Turismo Étnico Afro.</p>

    <p>O objetivo do projeto é fortalecer o patrimônio cultural e histórico da cidade, além de valorizar seus
      afrodescendentes, suas trajetórias e saberes transmitidos entre gerações.</p>

    <p>O Projeto <strong>AfroBiz Salvador</strong> também visa apoiar o desenvolvimento econômico e tecnológico dos
      atores do turismo
      étnico-afro, incluindo meios de hospedagem, agências e guias de turismo, terreiros, blocos e afoxés, artistas,
      capoeiristas, baianas, turbanteiras, feirantes e ambulantes.</p>
  </div>
</div>
