import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-video',
  templateUrl: './modal-video.component.html',
  styleUrls: ['./modal-video.component.css']
})
export class ModalVideoComponent {

  @Input() modalRef: any;
  constructor(
    public activeModal: NgbActiveModal) { }

  closeModal(aceite: any): void {
    this.modalRef.close();
  }


}
