<h2>{{menuService.RetornarDestaqueGerenciador('eventos').Titulo}}</h2>
  <div class="grid">
    <div style="height: 293px; width: 601px; position: relative; z-index: 1;" *ngIf="carregandoEventos">
      <div style="height: 100%; width: 100%; position: relative; align-content: center;"  *ngIf="eventosEmpty && carregandoEventos">
        <div style="text-align: center; vertical-align: middle; line-height: 100%; margin-top: 15%;">{{menuService.RetornarDestaqueGerenciador('sem_registros').Titulo}}</div>
      </div>
      <ngx-spinner name="sp1" bdColor="#fff" size="medium" color="#CCCCCC" type="timer" [fullScreen]="false"></ngx-spinner>
    </div>
    <a *ngFor="let evento of eventos" class="item" style="cursor: pointer;">
      <div (click)="openEventDetail(evento)" style="cursor: pointer;">
        <div class="image">
          <span>{{getDayAndMonth(evento.DtInicial)}}</span>
          <img src="{{evento.Imagem}}" alt="">
        </div>
        <h5>{{evento.Titulo | slice:0:40}}{{evento.Titulo && evento.Titulo.length > 40 ? '...':''}}</h5>
        <p>{{evento.Resumo | slice:0:60}}{{evento.Resumo && evento.Resumo.length > 60 ? '...':''}}</p>
      </div>
    </a>
  </div>
  <div class="ver-mais">
    <button type="button" class="btn btn-transparent" (click)="direcionarListagem()">
      {{menuService.RetornarDestaqueGerenciador('ver_eventos').Titulo}}
      <i class="{{menuService.RetornarDestaqueGerenciador('ver_eventos').CssClass}}"></i>
    </button>
  </div>
