import { Component, OnInit, Input } from '@angular/core';
import { GerenciadorConteudoService } from 'src/app/service/gerenciador-conteudo.service';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from 'src/app/service/auth.service';
import { MenuService } from 'src/app/service/menu.service';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Router } from '@angular/router';
moment.locale('pt-br');

@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.css']
})
export class NoticiaComponent implements OnInit {
  carregandoNoticias: any;
  noticias: any;
  noticiasEmpty: boolean;

  constructor(
    private gerenciadorConteudo: GerenciadorConteudoService,
    private router: Router,
    private spinnerNoticia: NgxSpinnerService,
    private authService: AuthService,
    public menuService: MenuService

  ) {
    this.noticias = [];
    this.carregandoNoticias = true;
    this.noticiasEmpty = false;
  }

  @Input() titulo: any = '';
  @Input() linkList: any = '';
  @Input() cssClass: any = '';

  ngOnInit(): void {
    this.spinnerNoticia.show('sp2');
    this.gerenciadorConteudo.getNewsSpotlight(this.authService.getLanguage()).subscribe((result: any) => {
      if (result.error) {
        console.log('noticias-destaque ', result.error);
        this.noticias = [];
        this.noticiasEmpty = true;
        this.spinnerNoticia.hide('sp2');
        return;
      }
      if (result.length == 0) {
        this.noticiasEmpty = true;
        this.spinnerNoticia.hide('sp2');
        return;
      }
      const res = result.slice(0, 3);
      this.noticias = res;
      this.carregandoNoticias = false;
      this.spinnerNoticia.hide('sp2');
    });
  }

  getDayName(eventDate: string) {
    return this.capitalizeFirstLetter(moment(eventDate, "DD/MM/YYYY").format("ddd"));
  }

  getDayAndMonth(eventDate: string) {
    return this.capitalizeFirstLetter(moment(eventDate, "DD/MM/YYYY").format("DD MMM"));
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  direcionarListagem() {
    this.router.navigate(["lista-noticia"]);
  }

  redirectToNewsDetail(noticia: any) {
    this.gerenciadorConteudo.noticia = noticia;
    this.router.navigate(["detalhe-noticia"], { queryParams: { noticiaId: noticia.Id } });
  }

}
