import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends MainService {
  //todo: colocar adm- como prefixo
  _url = `${super.getUrl()}dashboard/`

  constructor(private http: HttpClient) {
    super()
  }

  dashboard(filter: any) {
    const url = `${this._url}`
    return this.http.post<any>(url, filter, this.getOptions())
  }
}
