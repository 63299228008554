import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GerenciadorConteudoService } from 'src/app/service/gerenciador-conteudo.service';

@Component({
  selector: 'app-enquete',
  templateUrl: './enquete.component.html',
  styleUrls: ['./enquete.component.css']
})
export class EnqueteComponent implements OnInit {
  enquete: any;
  enqueteResult: any;
  optionSelected: any;

  constructor(
    private gerenciadorConteudo: GerenciadorConteudoService,
    private toastr: ToastrService
  ) {
    this.enquete = { 
      pergunta: { 
        respostas: [] 
      } 
    };
   }

  ngOnInit(): void {
    this.getSurvey();
    this.voteResult();
  }

  getSurvey() {
    this.gerenciadorConteudo.getSurvey("1").subscribe(result => {
      this.enquete = result[0];
      console.log(this.enquete);
    },
      error => {
        console.log("Carregar Enquete - Não foi possível obter enquete.");
      }
    );
  }

  enqueteOptionSelected(option: any) {
    let responseObj = {
      Id: 0,
      IdEnquete: this.enquete.pergunta.IdEnquete,
      IdResposta: this.enquete.pergunta.respostas[option].Id,
      IdIdioma: 1,
      DsIp: ""
    }
    this.optionSelected = responseObj;
    console.log(this.optionSelected);
  }

  vote() {
    if (!this.optionSelected) {
      this.toastr.error("É necessário selecionar uma resposta.", "Erro");
    }
    this.gerenciadorConteudo.getIp().subscribe(ipResult => {
      this.optionSelected.DsIp = ipResult.query;
      this.gerenciadorConteudo.vote(this.optionSelected).subscribe(result => {
      },
        error => {
          this.toastr.error("Não foi possível votar.", "Erro");
        }
      );
    },
      error => {
        this.toastr.error("Não foi possível votar.", "Erro");
      }
    );
  }

  voteResult() {
    this.gerenciadorConteudo.voteResult("1").subscribe(result => {
      this.enqueteResult = result;
      // const totalRespostas = this.enqueteResult.pergunta.totalRespostas;
      // this.enqueteResult.pergunta.respostas = this.enqueteResult.pergunta.respostas.map((res: any) => {
      //   res.pctResposta = 0;
      //   return res;
      // });
    },
      error => {
        this.toastr.error("Não foi possível carregar o resultado da enquete.", "Erro");
      }
    );
  }
}
