<main>
  <!-- ======================conteudo===================== -->
  <div class="conteudo">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Pessoas</li>
        <li class="breadcrumb-item {{ !TituloSubcategoria ? 'active' : '' }}">{{TituloCategoria}}</li>
        <li class="breadcrumb-item active" aria-current="page" *ngIf="TituloSubcategoria">
          {{TituloSubcategoria}}</li>
      </ol>
    </nav>
    <h3>{{TituloSubcategoria}}</h3>

    <div class="d-flex justify-content-center" *ngIf="pessoas.rows <= 0">
      <span class="mt-5">Sem registros</span>
    </div>
    <ul class="listagem">
      <!-- ================ITEM CARD===========   -->
      <li class="card" *ngFor="let pessoa of pessoas.rows">
        <a routerLink="/detalhe-pessoa" [queryParams]="{pes: pessoa.id, catId: subId, servicoId: pessoa.id}"
          class="card-link" style="cursor: pointer;">
          <div class="bloco">
            <div class="like">
              <input type="checkbox" class="custom-control-input" [checked]='pessoa.favorito' id="{{'chk'+pessoa.id}}"
                (click)="AddFavorite(pessoa.id, $event)">
              <label class="custom-control-label" for="{{'chk'+pessoa.id}}">
                <i class="far fa-heart"></i>
              </label>
            </div>
            <img src="{{pessoa.templateFoto}}" alt="">
          </div>
          <div class="card-footer">
            <h5>{{ pessoa.nome_fantasia }}</h5>
            <app-avaliacao-estrela pesId={{pessoa.id}}></app-avaliacao-estrela>
          </div>
        </a>
      </li>
      <!-- ================FIM ITEM CARD===========   -->

    </ul>


    <div class="d-flex justify-content-center" *ngIf="pessoas.qtdTotalPaginas > 1">
      <nav>
        <ul class="pagination">
          <li class="page-item {{ paginaAtual == 1 ? 'disabled' : ''}}" *ngIf="pessoas.count > 0">
            <a class="page-link" *ngIf="paginaAtual == 0"><i class="fas fa-chevron-left"></i></a>
            <button class="page-link" (click)="getPessoaList(paginaAtual - 1)" [disabled]="paginaAtual == 1">
              <i class="fas fa-chevron-left"></i>
              <div *ngIf="qtdPaginas[0] != 1">...</div>
            </button>
          </li>
          <li class="page-item " *ngFor="let opt of qtdPaginas;">
            <a class="page-link active" *ngIf="paginaAtual == opt">{{ opt }}</a>
            <button class="page-link" (click)="getPessoaList(opt)" *ngIf="paginaAtual != opt">
              {{ opt }}
            </button>
          </li>
          <li class="page-item {{ paginaAtual == pessoas.qtdTotalPaginas ? 'disabled' : ''}}" *ngIf="pessoas.count > 0">
            <a class="page-link" *ngIf="paginaAtual == (qtdPaginas-1)"><i class="fas fa-chevron-right"></i></a>
            <button class="page-link" (click)="getPessoaList(paginaAtual + 1)"
              [disabled]="paginaAtual == pessoas.qtdTotalPaginas">
              <div *ngIf="qtdPaginas[qtdPaginas.length - 1] != pessoas.qtdTotalPaginas">...</div>
              <i class="fas fa-chevron-right"></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <!-- =====================FIM =conteudo===================== -->


  <!-- FIM DO NOVO -->

</main>