import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { InteresseComponent } from 'src/app/pessoa-externo/interesse/interesse.component';
import { AuthService } from '../service/auth.service';
import { AvaliarService } from 'src/app/service/avaliar.service';
import { ServicoService } from '../service/servico.service';
import { FavoritoService } from '../service/favorito.service';
import { PessoaService } from '../service/pessoa.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-favoritos',
  templateUrl: './favoritos.component.html',
  styleUrls: ['./favoritos.component.css'],
})
export class FavoritosComponent implements OnInit {
  favoritoList: any = [];

  qtdPaginas: any;
  qtdItensPorPagina: number = 10;
  paginaAtual: number = 0;

  disabled: any = true;
  selectedItems: any = [];

  constructor(
    private avaliarService: AvaliarService,
    private servicoService: ServicoService,
    private pessoaService: PessoaService,
    private favoritoService: FavoritoService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getFavoritosList(this.paginaAtual);
  }

  async getFavoritosList(pagina: number) {
    this.paginaAtual = pagina;
    this.favoritoList = [];

    const filter = {
      id_usuario: this.authService.getUser().id,
      offset: pagina * this.qtdItensPorPagina,
      limit: this.qtdItensPorPagina,
    };

    await this.favoritoService.listByPessoa(filter).subscribe((list) => {
      list.result.rows.forEach((element: any) => {
        const { Servico, Pessoa } = element;
        if (Servico) {
          let fotoCat;
          if (Servico.Subcategorium.Categorium.nome == 'Afroempreendedores') {
            fotoCat =
              'assets/images/categoria_perfil_padrao/img-padrao-afroempreendedores.svg';
          } else if (Servico.Subcategorium.Categorium.nome == 'Baiana') {
            fotoCat =
              'assets/images/categoria_perfil_padrao/img-padrao-baiana.svg';
          } else if (Servico.Subcategorium.Categorium.nome == 'Capoeira') {
            fotoCat =
              'assets/images/categoria_perfil_padrao/img-padrao-capoeira.svg';
          } else if (
            Servico.Subcategorium.Categorium.nome == 'Arte & Cultura'
          ) {
            fotoCat =
              'assets/images/categoria_perfil_padrao/img-padrao-cultura.svg';
          } else if (Servico.Subcategorium.Categorium.nome == 'Flora') {
            fotoCat =
              'assets/images/categoria_perfil_padrao/img-padrao-flora.svg';
          } else if (Servico.Subcategorium.Categorium.nome == 'Gastronomia') {
            fotoCat =
              'assets/images/categoria_perfil_padrao/img-padrao-gastronomia.svg';
          } else if (Servico.Subcategorium.Categorium.nome == 'Moda & Beleza') {
            fotoCat =
              'assets/images/categoria_perfil_padrao/img-padrao-moda_beleza.svg';
          } else if (Servico.Subcategorium.Categorium.nome == 'Religião') {
            fotoCat =
              'assets/images/categoria_perfil_padrao/img-padrao-religiao.svg';
          } else if (Servico.Subcategorium.Categorium.nome == 'Turismo') {
            fotoCat =
              'assets/images/categoria_perfil_padrao/img-padrao-turismo.svg';
          } else {
            fotoCat = 'assets/images/user.svg';
          }

          // Servico.foto = Servico.foto ? `${this.servicoService.getUrl()}images/${Servico.foto}` : 'assets/images/produto.svg';
          Servico.foto = Servico.foto || fotoCat;
          Servico.youtube = Servico.youtube
            ? `https://www.youtube.com/embed/${Servico.youtube}`
            : '';

          element.Servico.alias = 'servico';
          element.Servico.created_at = element.created_at;
          element.Servico.templateFoto = Servico.foto;
          this.favoritoList.push(element.Servico);
        } else {
          //Pessoa.foto = Pessoa.foto ? `${this.pessoaService.getUrl()}images/${Pessoa.foto}` : 'assets/images/produto.svg';
          Pessoa.foto = Pessoa.foto || 'assets/images/user.svg';

          element.Pessoa.alias = 'pessoa';
          element.Pessoa.created_at = element.created_at;
          this.favoritoList.push(element.Pessoa);
        }
      });

      console.log('this.qtdItensPorPagina:', this.qtdItensPorPagina);
      console.log('list.result:', list.result);
      console.log('list.result.count:', list.result.count);

      this.qtdPaginas = Math.ceil(list.result.count / this.qtdItensPorPagina);
      console.log('qtdPaginas:', this.qtdPaginas);
      this.favoritoList.sort((a: any, b: any) => {
        return a.created_at > b.created_at
          ? -1
          : b.created_at > a.created_at
          ? 1
          : 0;
      });
    });
  }

  delete(idItem: any, alias: any) {
    this.favoritoService
      .delete(this.createItem(idItem, alias))
      .subscribe((_) => {
        this.toastr.success('Excluído com sucesso', 'Sucesso');
        this.getFavoritosList(0);
      });
  }

  createItem(idItem: any, alias: any) {
    let favorito;
    if (alias === 'servico') {
      favorito = {
        id_usuario: this.authService.getUser().id,
        id_servico: idItem,
        alias,
      };
    } else {
      favorito = {
        id_usuario: this.authService.getUser().id,
        id_pessoa: idItem,
        alias,
      };
    }

    return favorito;
  }

  itemExists(itemId: any) {
    let exists = false;

    for (const item of this.selectedItems) {
      if (item.id_servico) {
        if (item.id_servico === itemId) {
          exists = true;
        }
      } else {
        if (item.id_pessoa === itemId) {
          exists = true;
        }
      }
    }

    return exists;
  }

  delItemArr(item: any, alias: any) {
    if (alias === 'selecteds') {
      let index;
      if (item.id_servico) {
        index = this.selectedItems.findIndex(
          (el: any) => el.id_servico === item.id_servico
        );
      } else {
        index = this.selectedItems.findIndex(
          (el: any) => el.id_pessoa === item.id_servico
        );
      }
      this.selectedItems.splice(index, 1);
    }
  }

  onCheckBoxChange(itemId: any, alias: any) {
    if (this.itemExists(itemId)) {
      this.delItemArr(this.createItem(itemId, alias), 'selecteds');
      if (this.selectedItems.length > 0) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    } else {
      this.selectedItems.push(this.createItem(itemId, alias));
      this.disabled = false;
    }
  }

  async delSelecteds() {
    for (let element of this.selectedItems) {
      this.favoritoService.delete(element).subscribe((_) => {});
    }

    this.disabled = true;
    this.toastr.success('Excluídos com sucesso', 'Sucesso');
    window.location = window.location;
  }

  clickInteresse(servico: any) {
    console.log(servico);
    if (this.authService.isLoggedIn()) {
      forkJoin(
        this.pessoaService.getPessoa(servico.Pessoa.id),
        this.avaliarService.getMedia({ id_pessoa: servico.Pessoa.id })
      ).subscribe((res) => {
        const modalRef = this.modalService.open(InteresseComponent);
        modalRef.componentInstance.botaoFavorito = false;
        servico.foto = servico.templateFoto;
        modalRef.componentInstance.servicoModal = servico;
        let pessoa = res[0];
        //pessoa.templateFoto = pessoa.foto ? `${this.pessoaService.getUrl()}images/${pessoa.foto}` : "assets/images/produto.svg";
        pessoa.templateFoto = pessoa.foto || 'assets/images/produto.svg';
        modalRef.componentInstance.pessoa = pessoa;
        modalRef.componentInstance.avaliacaoMedia = res[1][0];
      });
    }
  }
}
