import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PessoaService } from '../service/pessoa.service';
import { EstadoCivilService } from '../service/estado-civil.service';
import { QuantidadeFilhosService } from '../service/quantidade-filhos.service';
import { RendaFamiliarService } from '../service/renda-familiar.service';
import { CidadeService } from '../service/cidade.service';
import { CepService } from '../service/cep.service';
import { ToastrService } from 'ngx-toastr';
import { UploadService } from '../service/upload.service';
import { PerfilService } from '../service/perfil.service';
import { AuthService } from '../service/auth.service';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SharedDataService } from '../service/shared-data.service';
import { UsuarioService } from '../service/usuario.service';
import { ServicoService } from '../service/servico.service';
import { environment } from '.env';
import { DatePipe } from '@angular/common';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { ThrowStmt } from '@angular/compiler';
import { ModalCropperComponent } from '../image-cropper/modal/modal-cropper.component';
import { GeneroService } from '../service/genero.service';
import { RacaService } from '../service/raca.service';
import { NumeroFuncionarioService } from '../service/numero-funcionario.service';
import { FaixaEtariaService } from '../service/faixa-etaria.service';
import { GeocodeService } from '../service/geocode.service';
import { ModalTermosComponent } from '../modal-termos/modal-termos.component';
import { RodadaNegocioService } from '../service/rodada-negocio.service';
import { ModalVideoComponent } from '../modal-video/modal-video.component';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : '';
  }
}

declare var gtag: any;

@Component({
  selector: 'app-pessoa',
  templateUrl: './pessoa.component.html',
  styleUrls: ['./pessoa.component.css'],
  providers: [
    DatePipe,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class PessoaComponent implements OnInit {
  currentDate = {
    date: new Date().getDate(),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  };

  tamanhoMaximoImagem = environment.MAX_SIZE_UPLOAD / 1000000;
  isSubmited = false;
  pessoa: any;
  faixaEtariaList: any;
  numeroFuncionarioList: any;
  estadoCivilList: any;
  quantidadeFilhosList: any;
  rendaFamiliaList: any;
  cidadeList: any;
  ufList: any;
  painelCEP = '';
  //painelCEP = 'disabled';
  fileToUpload: any;
  templateFoto: any = 'assets/images/user.svg';
  inputPassword = 'password';
  inputPasswordCheck = 'password';
  perfis: any;
  lastRoute: any;
  pessoaPersisted = false; //utilizado para desabilitar edição de e-mail quando salvo no banco
  cadastrarProdutosServicos = false;
  SalvarClicado = false; //controla o clique do botão salvar
  updateAuth = false;
  spinFoto = false;
  tipo = 'externo'; // cor do título
  generoList: any;
  racaList: any;
  mostraRodada = false;
  modalRef: any;
  tipoPessoa = 'PF';
  edicaoTipoPessoa = true;
  exibePainelRodada = false;
  rodadaNegocio = {
    id: '',
    titulo: '',
    descricao: '',
    descricao_tooltip: '',
    descricao_checkbox: '',
  };

  constructor(
    private router: Router,
    public pessoaService: PessoaService,
    private estadoCivilService: EstadoCivilService,
    private quantidadeFilhosService: QuantidadeFilhosService,
    private rendaFamiliarService: RendaFamiliarService,
    private cidadeService: CidadeService,
    private cepService: CepService,
    private toastr: ToastrService,
    private upload: UploadService,
    private perfilService: PerfilService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private usuarioService: UsuarioService,
    private sharedDataService: SharedDataService,
    private servicoService: ServicoService,
    public datePipe: DatePipe,
    private modalService: NgbModal,
    private generoService: GeneroService,
    private racaService: RacaService,
    private numeroFuncionarioService: NumeroFuncionarioService,
    private faixaEtariaService: FaixaEtariaService,
    private geocodeService: GeocodeService,
    private rodadaNegocioService: RodadaNegocioService
  ) {
    this.pessoa = {
      autobiografia: '',
      nome: '',
      nome_social: '',
      cpf: '',
      email: '',
      id_faixa_etaria: '',
      nome_fantasia: '',
      id_numero_funcionario: '',
      celular: '',
      id_estado_civil: '',
      id_qtd_filhos: '',
      id_renda: '',
      cep: '',
      endereco: '',
      id_perfil: '',
      bairro: '',
      id_uf: '', // "56629d1b-5f29-11eb-ab50-00155d807202",
      id_cidade: '', //"626c1734-5f29-11eb-ab50-00155d807202",
      senha: '',
      deAcordo: false,
      id_genero: '',
      id_raca: '',
      website: '',
    };
  }

  ngOnInit(): void {
    window.scroll({ top: 0, left: 0 });

    this.tipo = this.route.snapshot.data['tipo'] || 'externo';

    this.updateAuth = this.route.snapshot.data['updateAuth'] || false;

    if (
      this.tipo == 'externo' ||
      this.router.url.replace('/', '') == 'adm-cadastrar-dados-pessoais'
    ) {
      //Só é possível adicionar produto ou serviço para usuários que o perfil é Pessoa.
      this.cadastrarProdutosServicos = true;
    }

    this.getFaixaEtaria();
    this.getNumeroFuncionario();
    this.getEstadoCivil();
    this.getQuantidadeFilhos();
    this.getRendaFamiliar();
    //this.getCidadeList();
    this.getUfList();
    this.carregarPerfis();

    this.listGenero();
    this.listRaca();

    if (this.pessoaService.pessoa) {
      this.pessoa.bairro = this.pessoaService.pessoa.bairro;
      this.pessoa.celular = this.pessoaService.pessoa.celular;
      this.pessoa.cep = this.pessoaService.pessoa.cep;
      this.pessoa.complemento = this.pessoaService.pessoa.complemento;
      this.pessoa.cpf = this.pessoaService.pessoa.cpf;
      this.tipoPessoa =
        this.pessoaService.pessoa.cpf?.length > 11 ? 'PJ' : 'PF';
      this.pessoa.email = this.pessoaService.pessoa.email;
      this.pessoa.endereco = this.pessoaService.pessoa.endereco;
      this.pessoa.id_cidade = this.pessoaService.pessoa.id_cidade;
      this.pessoa.id_estado_civil = this.pessoaService.pessoa.id_estado_civil;
      this.pessoa.id_perfil = this.pessoaService.pessoa.id_perfil;
      this.pessoa.id_qtd_filhos = this.pessoaService.pessoa.id_qtd_filhos;
      this.pessoa.importacao = this.pessoaService.pessoa.importacao;
      this.pessoa.nome = this.pessoaService.pessoa.nome;
      this.pessoa.nome_social = this.pessoaService.pessoa.nome_social;
      this.pessoa.numero = this.pessoaService.pessoa.numero;
      this.pessoa.telefone = this.pessoaService.pessoa.telefone;
      this.pessoa.id_estado_civil = '';
      this.pessoa.id_qtd_filhos = '';
      this.pessoa.interesseRodada =
        this.pessoaService.pessoa.interesseRodada || false;
    }

    this.rodadaNegocioService.disponivel().subscribe((next) => {
      if (next) {
        this.rodadaNegocio = next;
        this.exibePainelRodada = true;
      }
    });

    if (this.authService.isLoggedIn()) {
      if (this.router.url.replace('/', '') == 'adm-cadastrar-dados-pessoais') {
        this.lastRoute = this.pessoaService.isFromRoute;
        this.pessoa.email = '';
        this.pessoa.cpf = '';
        if (this.usuarioService.usuario) {
          this.getPessoa(this.usuarioService.usuario.id);
        } else {
          this.pessoaPersisted = false;
          return;
        }
      } else {
        this.pessoaService.isFromRoute = '';
        this.getPessoa(this.authService.getUser().id);
      }
      if (this.authService.getUser().nome_perfil === 'Pessoa') {
        this.pessoaPersisted = true;
      }
    }
  }

  getPessoa(id: string) {
    this.pessoaService.getPessoaFromUser(id).subscribe((next) => {
      this.pessoa = { ...next };

      if (!this.pessoa.id_estado_civil) {
        this.pessoa.id_estado_civil = '';
      }
      if (!this.pessoa.id_qtd_filhos) {
        this.pessoa.id_qtd_filhos = '';
      }
      if (!this.pessoa.id_genero) {
        this.pessoa.id_genero = '';
      }
      if (!this.pessoa.id_raca) {
        this.pessoa.id_raca = '';
      }
      if (!this.pessoa.id_numero_funcionario) {
        this.pessoa.id_numero_funcionario = '';
      }
      if (!this.pessoa.id_faixa_etaria) {
        this.pessoa.id_faixa_etaria = '';
      }
      if (this.pessoa['PessoaRodadas.interesse']) {
        this.pessoa.interesseRodada = true;
      } else if (this.pessoaService.pessoa?.interesseRodada) {
        this.pessoa.interesseRodada = true;
        this.pessoa.rodadaNegocio = {
          id_rodada: this.pessoaService.pessoa.id_rodada,
          interesse: true,
        };
        this.pessoaService.updatePessoa(this.pessoa).subscribe((next) => {
          this.toastr.success(
            'Tudo certo! Cadastro para Rodada de Negócios feito com sucesso!',
            'Sucesso'
          );
        });
      }

      this.getCidadeList();
      // this.pessoa.id_cidade = next.id_cidade;
      //this.templateFoto = this.pessoa.foto ? `${this.pessoaService.getUrl()}images/${this.pessoa.foto}` : "assets/images/user.svg";
      this.templateFoto = this.pessoa.foto || 'assets/images/user.svg';
      //this.pessoa.senhaConfirma = this.pessoa.senha;
      if (
        this.tipo == 'externo' ||
        (this.router.url.replace('/', '') == 'adm-cadastrar-dados-pessoais' &&
          this.pessoa.id_perfil == '6da4dc31-67b8-11eb-99db-641c678d9ca2')
      ) {
        //Só é possível adicionar produto ou serviço para usuários que o perfil é Pessoa.
        this.cadastrarProdutosServicos = true;
      } else {
        this.cadastrarProdutosServicos = false;
      }

      // Se for Perfil Pessoa não deixar editar tipo de Pessoa (física/jurídica)
      if (this.authService.getUser().nome_perfil.includes('Pessoa')) {
        this.edicaoTipoPessoa = false;
      }

      if (this.pessoa.cpf.length >= 14) {
        this.tipoPessoa = 'PJ';
      }

      this.pessoa.deAcordo = true;
    });
  }

  getNumeroFuncionario() {
    this.numeroFuncionarioService
      .getList()
      .subscribe((list) => (this.numeroFuncionarioList = list));
  }

  getFaixaEtaria() {
    this.faixaEtariaService
      .getList()
      .subscribe((list) => (this.faixaEtariaList = list));
  }

  getEstadoCivil() {
    this.estadoCivilService
      .getList()
      .subscribe((list) => (this.estadoCivilList = list));
  }

  getQuantidadeFilhos() {
    this.quantidadeFilhosService
      .getList()
      .subscribe((list) => (this.quantidadeFilhosList = list));
  }

  getRendaFamiliar() {
    this.rendaFamiliarService
      .getList()
      .subscribe((list) => (this.rendaFamiliaList = list));
  }

  getCidadeList() {
    this.cidadeService
      .getList(this.pessoa.id_uf)
      .subscribe((list) => (this.cidadeList = list));
  }

  getUfList() {
    this.cidadeService.getUfList().subscribe((list) => (this.ufList = list));
  }

  listGenero() {
    this.generoService.list().subscribe((next) => (this.generoList = next));
  }

  listRaca() {
    this.racaService.list().subscribe((next) => (this.racaList = next));
  }

  // {
  //   "cep": "41745-715",
  //   "logradouro": "Rua Mundo",
  //   "complemento": "",
  //   "bairro": "Trobogy",
  //   "localidade": "Salvador",
  //   "uf": "BA",
  //   "ibge": "2927408",
  //   "gia": "",
  //   "ddd": "71",
  //   "siafi": "3849"
  // }
  getEndereco(cep: string) {
    this.pessoa.cep = '';
    let _cep = cep.split('.').join('').split('-').join('');
    if (_cep.length == 8) {
      this.painelCEP = ''; //remove disabed do painel
      this.cepService.getEndereco(_cep).subscribe((next: any) => {
        if (!next.erro) {
          let endereco = next;

          this.pessoa.endereco = endereco.logradouro;
          this.pessoa.bairro = endereco.bairro;
          this.pessoa.cidade = endereco.localidade;
          this.pessoa.uf = endereco.uf;

          this.cidadeService.getCidade(endereco.ibge).subscribe((next) => {
            this.pessoa.id_uf = next.uf;
            this.getCidadeList();
            this.pessoa.id_cidade = next.id;
          });

          this.obterCoordenadas();
        }
      });
      this.pessoa.cep = _cep;
    }
  }
  async Salvar(redireciona: boolean = false) {
    this.modalService.dismissAll();
    this.isSubmited = true;

    if (!this.validateField()) {
      return;
    }

    if (this.senhaDiferente()) {
      return;
    }

    if (
      !this.pessoa.deAcordo &&
      (!this.authService.getUser() ||
        this.authService.getUser().nome_perfil === 'Pessoa')
    ) {
      this.toastr.error('É necessário aceitar os Termos de uso do Portal');
      return;
    }

    if (!this.pessoaService.validateCpfCnpj(this.pessoa.cpf)) {
      this.toastr.error('Por favor, informe o CPF/CNPJ valido');
      return;
    }

    if (this.tipoPessoa == 'PF' && this.pessoa.cpf.length > 11) {
      this.pessoa.cpf = '';
      this.toastr.error('Para Pessoa Física, por favor, informe um CPF');
      return;
    }

    if (this.tipoPessoa == 'PJ' && this.pessoa.cpf.length < 14) {
      this.pessoa.cpf = '';
      this.toastr.error('Para Empresa, por favor, informe um CNPJ');
      return;
    }

    if (this.exibePainelRodada) {
      this.pessoa.rodadaNegocio = {
        id_rodada: this.rodadaNegocio.id,
        interesse: this.pessoa.interesseRodada,
      };
    }

    this.SalvarClicado = true;
    if (this.pessoa && this.pessoa.id) {
      this.atualizarPessoa(redireciona);
      return;
    } else {
      if (this.lastRoute) {
        this.salvarPessoa(redireciona);
        return;
      }
      this.salvarPerfilPessoa(redireciona);
    }
  }

  obterCoordenadas() {
    if (this.pessoa.endereco && this.pessoa.bairro && this.pessoa.id_cidade) {
      let nomeCidade = this.cidadeList.find(
        (x: any) => x.id == this.pessoa.id_cidade
      ).nome;
      let filtro = `${this.pessoa.endereco}+${this.pessoa.numero}+${this.pessoa.bairro}+${nomeCidade}`;
      this.geocodeService.getCoordinate(filtro).subscribe((next: any) => {
        let res = next.results[0].geometry.location;
        this.pessoa.latitude = res.lat;
        this.pessoa.longitude = res.lng;
      });
    }
  }

  atualizarPessoa(redireciona: boolean = false) {
    if (this.pessoa.cpf) {
      if (!this.pessoaService.validateCpfCnpj(this.pessoa.cpf)) {
        this.toastr.error('Por favor, informe o CPF/CNPJ válido', 'Erro');
        return;
      }
    }

    this.pessoaService.updatePessoa(this.pessoa).subscribe(
      (next) => {
        if (next) {
          if (this.updateAuth) {
            this.authService.updateUser(this.pessoa.foto, this.pessoa.nome);
          }
          this.SalvarClicado = false;
          this.pessoaPersisted = true;
          this.pessoaService.pessoa = this.pessoa;
          this.toastr.success('Atualizado com sucesso', 'Sucesso');
          if (redireciona) {
            if (
              this.router.url.replace('/', '') == 'adm-cadastrar-dados-pessoais'
            ) {
              this.pessoa.id = next.Usuario.id;
              this.servicoService.usuario = this.pessoa;
              this.servicoService.isFromRoute = 'adm-cadastrar-dados-pessoais';
              this.sharedDataService.setData({ tipoTela: 'cadastro' });
              this.router.navigate(['adm-servico']);
              return;
            }
            this.sharedDataService.setData({ tipoTela: 'cadastro' });
            this.router.navigate(['servico']);
          } else if (this.lastRoute) {
            this.router.navigate([this.lastRoute]);
            return;
          } else {
            this.reload();
          }
        }
      },
      (error) => {
        this.SalvarClicado = false;
        this.toastr.error('Não foi possível concluir.', 'Erro');
      }
    );
  }

  salvarPerfilPessoa(redireciona: boolean = false) {
    this.pessoaService
      .addPessoa(this.pessoa)
      .pipe(
        mergeMap((_) =>
          this.authService.login({
            email: this.pessoa.email,
            senha: this.pessoa.senha,
          })
        )
      )
      .subscribe(
        (next) => {
          this.SalvarClicado = false;
          this.pessoaPersisted = true;
          this.toastr.success('Adicionado com sucesso', 'Sucesso');
          this.authService.setUser(next);
          if (redireciona) {
            this.sharedDataService.setData({ tipoTela: 'cadastro' });
            this.router.navigate(['servico']);
          } else {
            this.reload();
          }
        },
        (error) => {
          console.log(error);
          this.SalvarClicado = false;
          this.toastr.error('Não foi possível concluir.', 'Erro');
        }
      );
  }

  salvarPessoa(redireciona: boolean = false) {
    if (this.authService.isLoggedIn()) {
      this.pessoa.id_cadastrante = this.authService.getUser().id;
    }
    this.pessoaService.addPessoa(this.pessoa).subscribe(
      (next) => {
        this.SalvarClicado = false;
        this.pessoaPersisted = true;
        this.toastr.success('Adicionado com sucesso', 'Sucesso');
        if (redireciona) {
          if (
            this.router.url.replace('/', '') == 'adm-cadastrar-dados-pessoais'
          ) {
            this.servicoService.usuario = next.Usuario;
            this.servicoService.isFromRoute = 'adm-cadastrar-dados-pessoais';
            this.sharedDataService.setData({ tipoTela: 'cadastro' });
            this.router.navigate(['adm-servico']);
            return;
          }
          this.sharedDataService.setData({ tipoTela: 'cadastro' });
          this.router.navigate(['servico']);
          return;
        }
        this.router.navigate([this.lastRoute]);
      },
      (error) => {
        this.SalvarClicado = false;
        this.toastr.error('Não foi possível adicionar.', 'Erro');
      }
    );
    gtag('event', 'conversion', {
      sent_to: 'AW-10937115683/Si4TCPvxnuADEKPInN8o',
    });
  }

  handleFileInput(event: any) {
    this.fileToUpload = event.target.files.item(0);

    if (!this.fileToUpload) {
      return;
    }

    if (this.fileToUpload.size > environment.MAX_SIZE_UPLOAD) {
      this.toastr.error(
        `Tamanho máximo de imagem permitido é ${this.tamanhoMaximoImagem}Mb`
      );
      return;
    }

    const modalRef = this.modalService.open(ModalCropperComponent);
    modalRef.componentInstance.imageChangedEvent = event;
    modalRef.componentInstance.aspectRatio = 1;
    modalRef.componentInstance.showControlPanel = true; // apagar depois
    modalRef.componentInstance.clickevent.subscribe(($e: any) => {
      this.upload.upload($e).subscribe((x) => {
        this.pessoa.foto = x.persistentUrl;
        this.templateFoto = $e;
        this.spinFoto = false;
      });
    });

    // this.fileToUpload = files.files.item(0);

    // if (!this.fileToUpload) {
    //   return;
    // }

    // if (this.fileToUpload.size > environment.MAX_SIZE_UPLOAD) {
    //   this.toastr.error(`Tamanho máximo de imagem permitido é ${this.tamanhoMaximoImagem}Mb`)
    //   return;
    // }

    // this.spinFoto = true;
    // var reader = new FileReader();
    // reader.onload = () => {
    //   this.upload.upload(reader.result).subscribe(x => {
    //     this.pessoa.foto = x.persistentUrl;
    //     this.templateFoto = x.url
    //     this.spinFoto = false;
    //   });
    // }
    // reader.readAsDataURL(this.fileToUpload);
  }

  AlterarFoto() {}

  ExcluirFoto() {
    this.pessoa.foto = null;
    this.templateFoto = 'assets/images/user.svg';
  }

  togglePassword() {
    this.inputPassword = this.inputPassword == 'password' ? 'text' : 'password';
  }

  togglePasswordCheck() {
    this.inputPasswordCheck =
      this.inputPasswordCheck == 'password' ? 'text' : 'password';
  }

  reload() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  isFieldValid(field: any) {
    return this.isSubmited && !field;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field),
    };
  }

  senhaDiferente() {
    return this.isSubmited && this.pessoa.senha != this.pessoa.senhaConfirma;
  }

  comparaSenhadisplayFieldCss() {
    return {
      'has-error': this.senhaDiferente(),
      'has-feedback': this.senhaDiferente(),
    };
  }

  validateField() {
    let fields = [
      'autobiografia',
      'nome',
      'cpf',
      'email',
      'celular',
      'cep',
      'endereco',
      'id_cidade',
      'bairro',
      'nome_fantasia',
      'id_uf',
    ];

    if (this.tipoPessoa == 'PF') {
      fields.push('nome_social');
    }

    if (
      !this.authService.getUser() ||
      this.authService.getUser().nome_perfil === 'Pessoa' ||
      (this.authService.getUser() &&
        this.authService.getUser().nome_perfil !== 'Pessoa' &&
        !this.pessoa.id)
    ) {
      fields.push('senha');
    }

    for (const iterator of fields) {
      if (!this.pessoa[iterator]) {
        return false;
      }
    }

    return true;
  }

  Cancelar() {
    if (this.lastRoute) {
      this.router.navigate([this.lastRoute]);
      return;
    } else if (
      this.router.url.replace('/', '') == 'adm-cadastrar-dados-pessoais'
    ) {
      this.router.navigate(['adm-listagem-pessoas']);
      return;
    }
    this.router.navigate(['home']);
  }

  carregarPerfis() {
    this.perfilService.getPerfil({}).subscribe((list) => {
      this.perfis = list;
      if (this.tipo == 'externo') {
        this.pessoa.id_perfil = this.perfis.find(
          (p: { nome: string }) => p.nome === 'Pessoa'
        ).id;
      } else if (this.perfis.length === 1) {
        this.pessoa.id_perfil = this.perfis[0].id;
      }
    });
  }

  verificarCadastroProdutoServico(idPerfil: string) {
    if (
      this.tipo == 'externo' ||
      (this.router.url.replace('/', '') == 'adm-cadastrar-dados-pessoais' &&
        idPerfil == '6da4dc31-67b8-11eb-99db-641c678d9ca2')
    ) {
      this.cadastrarProdutosServicos = true;
    } else {
      this.cadastrarProdutosServicos = false;
    }
  }

  mostrarTermos() {
    this.modalRef = this.modalService.open(ModalTermosComponent);

    this.modalRef.componentInstance.modalRef = this.modalRef;
    this.modalRef.componentInstance.language = this.authService.getLanguage();
    this.modalRef.componentInstance.tela = 'pessoa';
    this.modalRef.componentInstance.pessoa = this.pessoa;
  }

  mudaTipoPessoa() {
    this.pessoa.cpf = '';

    if (this.tipoPessoa == 'PJ') {
      this.pessoa.nome_social = '';
      this.pessoa.id_genero = '';
      this.pessoa.id_raca = '';
      this.pessoa.id_faixa_etaria = '';
      this.pessoa.id_estado_civil = '';
      this.pessoa.id_qtd_filhos = '';
      this.pessoa.id_renda = '';
    }

    if (this.tipoPessoa == 'PF') {
      this.pessoa.id_numero_funcionario = '';
    }
  }
  showVideoModal() {
    this.modalRef = this.modalService.open(ModalVideoComponent);

    this.modalRef.componentInstance.modalRef = this.modalRef;
  }
}
