import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-slide-topo',
  templateUrl: './slide-topo.component.html',
  styleUrls: ['./slide-topo.component.css']
})
export class SlideTopoComponent implements OnInit {

  destaques: any = [];

  templateAleatorio = parseInt(Math.random().toString().slice(2,3)) <= 3 ? 1 : 
  parseInt(Math.random().toString().slice(2,3)) > 6 ? 3 : 2

  constructor(
    private menuService: MenuService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getDestaque();
  }

  getDestaque() {
    let _idioma = this.authService.getLanguage()

    this.menuService.Destaques(_idioma).subscribe(next => {

      var t = this.shuffle(next)
      this.destaques.parte1 = t.splice(0, 3)
      this.destaques.parte2 = t.splice(0, 3)
      this.destaques.parte3 = t.splice(0, 3)
      this.destaques.parte4 = t.splice(0, 3)
      this.destaques.parte5 = t.splice(0, 3)
      this.destaques.parte6 = t.splice(0, 3)
    })
  }

  shuffle(array: any) {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

}
