<footer>
  <div class="center">
    <!-- <img src="/assets/images/marca.svg" alt=""> -->
    <div class="logo"></div>
  </div>
  <div class="menu-footer menor" *ngIf="logado">
    <ul>
      <li *ngFor="let option of optionsLogado">
        <a style="cursor: pointer;" (click)="openLink(option)" *ngIf="option && option.URL != 'sair'"
          style="cursor: pointer;">
          {{option.Titulo}}
        </a>
        <a style="cursor: pointer;" (click)="Sair()" *ngIf="option && option.URL == 'sair'" style="cursor: pointer;">
          <i class="{{option.CssClass}}"></i>
          {{option.Titulo}}
        </a>
      </li>
    </ul>
  </div>
  <div class="menu-footer menor" *ngIf="!logado">
    <ul>
      <li *ngFor="let option of optionsDeslogado">
        <a style="cursor: pointer;" (click)="openLink(option)" style="cursor: pointer;">
          <i class="{{option.CssClass}}"></i>
          {{option.Titulo}}
        </a>
      </li>
    </ul>
  </div>
  <div class="center">
    <img src="/assets/images/logo-prodetur.svg" alt="">
    <img src="/assets/images/logo-bid.svg" alt="">
    <img src="/assets/images/logo-prefeitura.svg" alt="">
  </div>

  <!-- infelizmente tive q colocar aqui, depois vejo como fazer melhor -->
  <ng-template #login let-modal>
    <div class="modal-content text-center">
      <div class="modal-login">
        <div class="modal-header">
          <div class="text-center w-100">
            <img src="assets/images/marca.svg" alt="">
          </div>
          <button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <h4>Acesse com sua conta:</h4>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group input-group">
                  <label for="">Email:</label>
                  <span>
                    <i class="fas fa-user"></i>
                  </span>
                  <input [(ngModel)]="_login.email" type="text" class="form-control" placeholder="">
                </div>
              </div>

              <div class="col-sm-6">
                <div class="input-group mb-3">
                  <label for="" class="w-100">Senha:<span>*</span></label>
                  <span>
                    <i class="fas fa-lock"></i>
                  </span>
                  <i class="toggle-password fa fa-fw {{ inputPassword == 'password' ? 'fa-eye' : 'fa-eye-slash' }}"
                    (click)="togglePassword()"></i>
                  <input [(ngModel)]="_login.senha" [type]="inputPassword" class="form-control" placeholder=""
                    (keydown.enter)="Entrar()">
                </div>
              </div>

            </div>
            <a href="" class="btn-block" style="cursor: pointer;"><strong>Esqueceu a senha?</strong></a>
            <button type="button" class="btn btn-success btn-lg" (click)="Entrar()">
              <i class="fas fa-check"></i>
              ACESSAR
            </button>
            <div class="login-redes">
              <h4>ou acesse com sua redes social preferida:</h4>
              <div class="botoes">
                <button type="button" class="btn btn-lg btn-facebook">
                  <i class="fab fa-facebook-f"></i>
                  Login com Facebook
                </button>
                <button type="button" class="btn btn-lg btn-google">
                  <i class="ico-google"></i>
                  Login com Google
                </button>
              </div>
            </div>
            <p>Ainda não faz parte do TEA? <a routerLink="" (click)="IrParaCadastro(precadastro)"
                style="cursor: pointer;"><strong>Cadastre-se! </strong></a></p>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- infelizmente tive q colocar aqui, depois vejo como fazer melhor -->
  <ng-template #precadastro let-modal>
    <div class="modal-content">
      <div class="modal-header">
        <h3>Cadastro de usuário</h3>

        <button type="button" class="close" (click)="modal.dismiss('Cross click')">
          <i class="fas fa-times"></i>
        </button>
      </div>

      <div class="modal-body">
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
          et dolore magna.</p>

        <div class="col-sm-12">
          <div class="form-group ">
            <label for="">E-mail:</label>
            <input [(ngModel)]="pessoa.email" type="text" class="form-control" placeholder="" maxlength="150">
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group ">
            <label for="">CPF/CNPJ:</label>
            <input [(ngModel)]="pessoa.cpf" type="text" class="form-control" placeholder="" mask="CPF_CNPJ"
              (keydown.enter)="IniciarCadastro()">
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button type="button" class="btn btn-padrao" (click)="IniciarCadastro()">
            INICIAR CADASTRO
          </button>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <p>Já possui cadastro na plataforma?<a href="" style="cursor: pointer;"><strong>Acesse aqui!</strong></a></p>
      </div>

    </div>
  </ng-template>
</footer>
<div class="cookie" *ngIf="!cookieAceito">
  <div class="center">
    <p><i class="fas fa-cookie"></i>Este site utiliza cookies para te proporcionar uma maior experiência. Ao continuar,
      navegando, você aceita nossa <a (click)="open('aceite')"
        style="cursor: pointer; text-decoration: underline;">Politica
        de Privacidade</a>.</p>
    <button class="btn btn-padrao" (click)="aceitarCookies()">
      <i class="fas fa-check"></i>
      OK
    </button>
  </div>
</div>
