import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class RacaService extends MainService {
  constructor(private http: HttpClient) { super(); }

  list() {
    const url = `${super.getUrl()}raca/list`;
    return this.http.post<any>(url, {}, this.getOptions());
  }
}
