<main>
    <div class="conteudo">

        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item redirect" routerLink="/home">Página Inicial</li>
                <li class="breadcrumb-item active" aria-current="page">Lista de Rodada de Negócio</li>
            </ol>
        </nav>
        
        <h2>Rodadas de negócios</h2>

        <div class="guias-cadastro">
            <button type="submit" *permission="['add-people']" class="btn btn-transparent" (click)="criarRodada()">
                <i class="fas fa-plus"></i>
                Cadastro de Rodadas
            </button>
            <button type="button" class="btn btn-transparent active">
                <i class="fas fa-list-ul"></i>
                Lista de Rodadas
            </button>
        </div>

        <div class="lista-produtos pagina">

            <button type="submit" *permission="['add-people']" class="btn btn-transparent btn-sm mb-5"
                (click)="criarRodada()">
                <i class="fas fa-plus"></i>
                CADASTRAR NOVA RODADA DE NEGÓCIO
            </button>

            <div class="d-flex justify-content-center" *ngIf="rodadasNegocio.rows.length <= 0">
                <span class="mt-5">Sem registros</span>
            </div>
            <div *ngIf="rodadasNegocio.rows.length > 0">
                <h4> Lista de Rodada de Negócio</h4>

                <ul class="list">
                    <li *ngFor="let rodada of rodadasNegocio.rows; let i = index">
                        <div class="texto">
                            <h5>{{rodada.titulo}}</h5>
                            <p><strong>Texto check:</strong> {{rodada.descricao_checkbox}}</p>
                            <p>
                                <strong>Descrição:</strong> {{rodada.descricao}}
                            </p>
                            <ul>
                                <li>
                                    <strong>Data inicial:</strong>
                                    {{rodada.data_inicio.split('T')[0].split('-').reverse().join('/')}}
                                </li>
                                <li>
                                    <strong>Data final:</strong>
                                    {{rodada.data_fim.split('T')[0].split('-').reverse().join('/')}}
                                </li>
                            </ul>
                        </div>
                        <div class="botoes">
                            <button type="submit" *permission="['edit-people']" class="btn btn-transparent"
                                title="Editar" (click)="editar(rodada)">
                                <i class="fas fa-edit"></i>
                            </button>
                            <div *ngIf="!rodada.ativo">
                                <button *permission="['active-people']" type="submit" class="btn btn-transparent"
                                    title="Ativar" (click)="mudarStatus(rodada, 'Ativar')">
                                    <i class="fas fa-check"></i>
                                </button>
                            </div>
                            <div *ngIf="rodada.ativo">
                                <button *permission="['inactive-people']" type="submit" class="btn btn-transparent"
                                    title="Inativar" (click)="mudarStatus(rodada, 'Inativar')">
                                    <i class="fas fa-ban"></i>
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="d-flex justify-content-center">
                    <nav>
                        <ul class="pagination">
                            <li class="page-item {{ paginaAtual == 1 ? 'disabled' : ''}}"
                                *ngIf="rodadasNegocio.rows.length > 0">
                                <a class="page-link" *ngIf="paginaAtual == 0" style="cursor: pointer;"><i
                                        class="fas fa-chevron-left"></i></a>
                                <button class="page-link" (click)="obterRodadas(paginaAtual - 1)"
                                    [disabled]="paginaAtual == 1">
                                    <i class="fas fa-chevron-left"></i>
                                    <div *ngIf="qtdPaginas[0] != 1">...</div>
                                </button>
                            </li>
                            <li class="page-item " *ngFor="let opt of qtdPaginas;">
                                <a class="page-link active" *ngIf="paginaAtual == opt" style="cursor: pointer;">{{ opt
                                    }}</a>
                                <button class="page-link" (click)="obterRodadas(opt)" *ngIf="paginaAtual != opt">
                                    {{ opt }}
                                </button>
                            </li>
                            <li class="page-item {{ paginaAtual == rodadasNegocio.qtdTotalPaginas ? 'disabled' : ''}}"
                                *ngIf="rodadasNegocio.rows.length > 0">
                                <a class="page-link" *ngIf="paginaAtual == (qtdPaginas-1)" style="cursor: pointer;"><i
                                        class="fas fa-chevron-right"></i></a>
                                <button class="page-link" (click)="obterRodadas(paginaAtual + 1)"
                                    [disabled]="paginaAtual == rodadasNegocio.qtdTotalPaginas">
                                    <div *ngIf="qtdPaginas[qtdPaginas.length - 1] != rodadasNegocio.qtdTotalPaginas">...
                                    </div>
                                    <i class="fas fa-chevron-right"></i>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</main>