import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../service/auth.service';
import { PrecadastroComponent } from '../precadastro/precadastro.component';
import { RecuperaSenhaComponent } from '../recupera-senha/recupera-senha.component';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

@Component({
  selector: 'app-login',
  template: `
  <div class="modal-content text-center">
  <div class="modal-login">
      <div class="modal-header">
          <div class="text-center w-100">
            <div class="logo"></div>
          </div>
          <button type="button" class="close" (click)="activeModal.dismiss('Cross click')">
              <i class="fas fa-times"></i>
          </button>
      </div>
      <div class="modal-body">
          <div class="text-center">
              <h4>Acesse com sua conta:</h4>
              <div class="row">
                  <div class="col-sm-6">
                      <div class="form-group input-group">
                          <label for="">
                            Email:
                            <a aria-label="Por favor, insira o e-mail cadastrado na plataforma" data-balloon-pos="up" class="" style="cursor: pointer;">
                                        <i  class="fas fa-question-circle"></i>
                                    </a>
                          </label>
                          <span>
                              <i class="fas fa-user"></i>
                          </span>

                          <input [(ngModel)]="_login.email" type="text" class="form-control" placeholder="">
                      </div>
                  </div>

                  <div class="col-sm-6">
                      <div class="input-group mb-3">
                          <label for="" class="w-100">
                            Senha:
                            <a aria-label="Insira sua senha" data-balloon-pos="up" class="" style="cursor: pointer;">
                                        <i  class="fas fa-question-circle"></i>
                                    </a>
                            </label>
                            <span>
                                <i class="fas fa-lock"></i>
                            </span>

                          <i class="toggle-password fa fa-fw {{ inputPassword == 'password' ? 'fa-eye' : 'fa-eye-slash' }}"
                              (click)="togglePassword()"></i>
                          <input [(ngModel)]="_login.senha" [type]="inputPassword" class="form-control"
                              placeholder="" (keydown.enter)="Entrar()">
                      </div>
                  </div>

              </div>
              <a [routerLink]="" class="btn-block mb-4" (click)="recuperaSenha()" style="cursor: pointer;"><strong>Esqueceu a senha?</strong></a>
              <button type="button" class="btn btn-padrao btn-lg" (click)="Entrar()">
                  <i class="fas fa-check"></i>
                  Acessar
              </button>
              <div class="login-redes" *ngIf="false">
                  <h6>ou acesse com sua redes social preferida:</h6>
                  <div class="botoes">
                      <button type="button" class="btn btn-lg btn-facebook">
                          <i class="fab fa-facebook-f"></i>
                          Login com Facebook
                      </button>
                      <button type="button" class="btn btn-lg btn-google" (click)="signInWithGoogle()">
                          <i class="ico-google"></i>
                          Login com Google
                      </button>
                  </div>
              </div>

          </div>
      </div>
      <div class="modal-footer">
          <p>Ainda não possui cadastro na plataforma? <a [routerLink]=""
          (click)="IrParaCadastro()" style="cursor: pointer;"><strong>Cadastre-se! </strong></a></p>
      </div>
  </div>
</div>

  `
})

export class LoginComponent implements OnInit {

  inputPassword = "password";
  _login: any;

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private authService: AuthService,
    private socialService: SocialAuthService
  ) {
    this._login = {};
    this._login.email = "";
    this._login.senha = "";
  }

  ngOnInit(): void {
    this.socialService.authState.subscribe((user) => {
      console.log('user', user)
    }, err => { console.log(err) });
  }

  Entrar() {
    this.authService.login(this._login).subscribe(
      next => {
        this.authService.setUser(next);
        this.modalService.dismissAll();
        this.reload();
        this.toastr.success(`Bem-vindo(a) ${next.nome}`, "Sucesso")
      },
      error => {
        if (error && error.error.includes('Usuário não existe')) {
          this.toastr.error("Este usuário não existe. Para acessar, faça o cadastro.", "Erro")
          return
        }
        this.toastr.error("Não foi possível fazer o login", "Erro")
      }
    );
  }

  reload() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  IrParaCadastro() {
    this.activeModal.close();
    this.modalService.open(PrecadastroComponent);
  }

  togglePassword() {
    this.inputPassword = this.inputPassword == "password" ? "text" : "password";
  }

  recuperaSenha() {
    this.activeModal.close();
    this.modalService.open(RecuperaSenhaComponent);
  }

  signInWithGoogle(): void {
    this.activeModal.close();
    this.socialService.signIn(GoogleLoginProvider.PROVIDER_ID);
    this.socialService.authState.subscribe((user) => {
      console.log('user', user)
    }, err => { console.log(err) });
  }
}
