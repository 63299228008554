<!-- ======================conteudo===================== -->
<main>

<div class="conteudo">

 <nav aria-label="breadcrumb">
   <ol class="breadcrumb">
     <li class="breadcrumb-item redirect" routerLink="/home">{{menuService.RetornarDestaqueGerenciador('pagina_inicial').Titulo}}</li>
     <li class="breadcrumb-item active" aria-current="page">{{menuService.RetornarDestaqueGerenciador('eventos').Titulo}}</li>
   </ol>
 </nav>

 <h3>{{menuService.RetornarDestaqueGerenciador('eventos').Titulo}}</h3>
 <div class="lista-produtos lista-eventos">
      <div style="height: 293px; width: 100%; position: relative; z-index: 1; align-content: center;" *ngIf="carregandoListaEventos">
        <div style="height: 100%; width: 100%; position: relative; align-content: center;"  *ngIf="eventosEmpty && carregandoListaEventos">
          <div style="text-align: center; vertical-align: middle; line-height: 100%; margin-top: 10%;">{{menuService.RetornarDestaqueGerenciador('sem_registros').Titulo}}</div>
        </div>
        <ngx-spinner name="sp4" bdColor="#fff" size="medium" color="#CCCCCC" type="timer" [fullScreen]="false"></ngx-spinner>
      </div>
      <ul class="list row">
        <!-- ==============ITEM LISTA=============== -->
        <li *ngFor="let evento of eventos.rows; let i = index" (click)="open(evento)" style="cursor: pointer;">
          <div class="image">
            <img src="{{evento.Imagem}}" alt="">
          </div>
          <a class="item" style="cursor: pointer;">
            <div>
              <div class="texto" *ngIf="language === 'pt-br'">
                  <h5>{{evento.Titulo}}</h5>
                  <p>
                    <strong>Data:</strong> de {{evento.DtInicial.substring(0, 5)}} a {{evento.DtFinal.substring(0, 5)}}
                  </p>
                  <p>
                    <strong>Horário:</strong> {{evento.HoraInicial}} às {{evento.HoraFinal}}
                  </p>
                  <p>
                    <strong>Local:</strong> {{evento.Local}}
                  </p>
                  <p *ngIf="evento.Valor">
                    <strong>Valor (R$):</strong> {{ evento.Valor }}
                  </p>
              </div>
              <div class="texto" *ngIf="language !== 'pt-br'">
                <h5>{{evento.Titulo}}</h5>
                <p>
                  <strong>Date:</strong> from {{evento.DtInicial.substring(0, 5)}} to {{evento.DtFinal.substring(0, 5)}}
                </p>
                <p>
                  <strong>Hour:</strong> {{evento.HoraInicial}} to {{evento.HoraFinal}}
                </p>
                <p>
                  <strong>Local:</strong> {{evento.Local}}
                </p>
                <p *ngIf="evento.Valor">
                  <strong>Value (R$):</strong> {{ evento.Valor }}
                </p>
            </div>
            </div>
          </a>
        </li>
        <!-- ==============FIM ITEM LISTA=============== -->
      </ul>
  </div>

  <div class="d-flex justify-content-center">
    <nav>
      <ul class="pagination">
        <li class="page-item {{ paginaAtual == 1 ? 'disabled' : ''}}" *ngIf="eventos.rows.length > 0">
          <a class="page-link" *ngIf="paginaAtual == 0" style="cursor: pointer;"><i class="fas fa-chevron-left"></i></a>
          <button class="page-link" (click)="getEventsList(paginaAtual - 1)" [disabled]="paginaAtual == 1">
            <i class="fas fa-chevron-left"></i>
            <div *ngIf="qtdPaginas[0] != 1">...</div>
          </button>
        </li>
        <li class="page-item " *ngFor="let opt of qtdPaginas;">
          <a class="page-link active" *ngIf="paginaAtual == opt" style="cursor: pointer;">{{ opt }}</a>
          <button class="page-link" (click)="getEventsList(opt)" *ngIf="paginaAtual != opt">
            {{ opt }}
          </button>
        </li>
        <li class="page-item {{ paginaAtual == eventos.qtdTotalPaginas ? 'disabled' : ''}}"
          *ngIf="eventos.rows.length > 0">
          <a class="page-link" *ngIf="paginaAtual == (qtdPaginas-1)" style="cursor: pointer;"><i class="fas fa-chevron-right"></i></a>
          <button class="page-link" (click)="getEventsList(paginaAtual + 1)"
            [disabled]="paginaAtual == eventos.qtdTotalPaginas">
            <div *ngIf="qtdPaginas[qtdPaginas.length - 1] != eventos.qtdTotalPaginas">...</div>
            <i class="fas fa-chevron-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  </div>
  <!-- =============FIM LISTA PRODUTOS=================== -->


</div>

<!-- =====================FIM =conteudo===================== -->



</main>
