import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class VersionamentoService extends MainService {

  constructor(private http: HttpClient) { super(); }

  getVersion() {
    const url = `${super.getUrl()}version`;
    return this.http.get<any>(url, this.getOptions());
  }
}
