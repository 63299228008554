import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MainService } from './main.service';
import { AnyARecord } from 'node:dns';
import { cpf, cnpj } from 'cpf-cnpj-validator';

type retornoGetPessoa = {
  count: number;
  rows: Array<any>;
};

@Injectable({
  providedIn: 'root',
})
export class PessoaService extends MainService {
  _url = `${super.getUrl()}pessoa/`;

  pessoa: any;
  isFromRoute: string;

  constructor(private http: HttpClient) {
    super();
    this.isFromRoute = ''; //Variável criada para obter a rota anterior do administradaor, com o objetivo de ao salvar ou atualizar, redirecionar o administrador novamente para tela anterior. Se for pelo perfil do usuário esta variável fica vazia.
  }

  getPessoas(filter: any): Observable<retornoGetPessoa> {
    const url = `${this._url}search`;
    return this.http.post<retornoGetPessoa>(url, filter, this.getOptions());
  }

  getPessoa(id: string): Observable<any> {
    const url = `${this._url}get/${id}`;
    return this.http.get<any>(url, this.getOptions());
  }

  getPessoaFromUser(id: string): Observable<any> {
    const url = `${this._url}get-from-user/${id}`;
    return this.http.get<any>(url, this.getOptions());
  }

  addPessoa(pessoa: any): Observable<any> {
    const url = `${this._url}add`;
    return this.http.post(url, pessoa, this.getOptions());
  }

  deletePessoa(id: string): Observable<any> {
    const url = `${this._url}delete/${id}`;
    return this.http.delete(url, this.getOptions());
  }

  updatePessoa(pessoa: any): Observable<any> {
    const url = `${this._url}update`;
    return this.http.put(url, pessoa, this.getOptions());
  }

  searchPessoa(term: string): Observable<any> {
    if (!term.trim()) {
      return of([]);
    }

    const url = `${this._url}searchReturnNames`;
    //return this.http.post<any[]>(url, term, this.getOptions())

    return this.http.post<any>(url, { nome: term }).pipe(
      tap((x) =>
        x.length
          ? this.log(`encontrado com termo "${term}"`)
          : this.log(`não encontrado com termo "${term}"`)
      ),
      catchError(this.handleError<any>('searchPessoa', []))
    );
  }

  searchReturnList(filter: any) {
    const url = `${this._url}searchReturnList`;
    return this.http.post<any>(url, filter, this.getOptions());
  }

  getCidade(id_usuario: any): Observable<any> {
    const url = `${this._url}getCidade`;
    return this.http.post(url, { id: id_usuario }, this.getOptions());
  }

  searchImportacao(filter: any) {
    const url = `${this._url}searchImportacao`;
    return this.http.post(url, filter, this.getOptions());
  }

  private log(message: string) {
    console.log(message);
    //this.messageService.add(`HeroService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getPessoaEServicos(term: any) {
    const url = `${this._url}searchTerm`;
    return this.http.post<any[]>(url, { term }, this.getOptions());
  }

  validateCpfCnpj(val: any) {
    if (val.length > 14) {
      return false;
    }

    if (val.length === 11) {
      return cpf.isValid(val);
    }

    if (val.length === 14) {
      return cnpj.isValid(val);
    }

    return false;
  }
}
