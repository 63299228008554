<!-- <div class="bg-verde barra-titulo">
    <h2>Suporte</h2>
</div> -->
<div class="conteudo">
  <h2>Suporte</h2>
  <div class="descricao">
    <p>Para dúvidas e maiores informações sobre a plataforma Afrobiz Salvador, utilize por gentileza o formulário
      abaixo.</p>
  </div>
  <div class="">


    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="form-group" [ngClass]="displayFieldCss(form.nome)">
          <label for="">Nome Completo: <span>*</span></label>
          <input [(ngModel)]="form.nome" type="text" class="form-control" placeholder="" maxlength="100">
          <app-field-error-display [displayError]="isFieldValid(form.nome)" errorMsg="Por favor, informe a Nome">
          </app-field-error-display>
        </div>
      </div>

      <div class="col-md-6 col-sm-12">
        <div class="form-group" [ngClass]="displayFieldCss(form.email)">
          <label for="">E-mail: <span>*</span></label>
          <input [(ngModel)]="form.email" type="email" class="form-control" placeholder="" maxlength="100">
        </div>
        <app-field-error-display [displayError]="isFieldValid(form.email)" errorMsg="Por favor, informe a E-mail">
        </app-field-error-display>
      </div>

    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="form-group" [ngClass]="displayFieldCss(form.telefone)">
          <label for="">Telefone: <span>*</span></label>
          <input [(ngModel)]="form.telefone" type="text" class="form-control" placeholder="" mask="(00) 0 0000-0000">
          <app-field-error-display [displayError]="isFieldValid(form.telefone)"
            errorMsg="Por favor, informe o Telefone">
          </app-field-error-display>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="form-group" [ngClass]="displayFieldCss(form.setor)">
          <label for="">Setor: <span>*</span></label>
          <select class="form-control" [(ngModel)]="form.setor">
            <option *ngFor="let opt of setorList" [value]="opt.id">
              {{ opt.descricao }}
            </option>
          </select>
          <app-field-error-display [displayError]="isFieldValid(form.setor)" errorMsg="Por favor, informe o Setor">
          </app-field-error-display>
        </div>
      </div>

    </div>


    <div class="form-group">
      <label for="">Assunto:</label>
      <select class="form-control" [(ngModel)]="form.assunto">
        <option *ngFor="let opt of assuntoList" [value]="opt.id">
          {{ opt.descricao }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="" class="form-label">Descrição:</label>
      <textarea [(ngModel)]="form.descricao" class="form-control" id="" maxlength="500" rows="3"></textarea>
    </div>

    <div class="form-footer">
      <div class="w-100 text-right">
        <button type="submit" class="btn btn-transparent" (click)="limpar()">
          <i class="fas fa-times"></i>
          LIMPAR
        </button>

        <button type="button" class="btn btn-transparent ml-2" (click)="enviar()">
          <i class="fas fa-check"></i>
          ENVIAR
        </button>
      </div>

    </div>
  </div>
</div>
