import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends MainService {

  isLogin = false;
  roleAs: string | null;

  constructor(
    private http: HttpClient) {
    super();
    this.roleAs = null;

    // auto update actions in 30s
    setInterval(() => {
      if (this.isLoggedIn()) {
        this.getActions().subscribe(acoes => {
          if (this.isLoggedIn()) {
            if (acoes && Array.isArray(acoes)) {
              const user = this.getUser();
              user.acoes = acoes;
              this.setUser(user);
            }
          }
        });
      }
    }, 30000);
  }

  // login(value: string) {
  //   this.isLogin = true;
  //   this.roleAs = value;
  //   localStorage.setItem('STATE', 'true');
  //   localStorage.setItem('ROLE', this.roleAs);
  //   return of({ success: this.isLogin, role: this.roleAs });
  // }

  setUser(user: any) {
    // user.templateFoto = user.foto ? `${this.getUrl()}images/${user.foto}` : "assets/images/user.svg";
    user.templateFoto = user.foto || "assets/images/user.svg";
    localStorage.setItem('device-id', JSON.stringify(user));
  }

  getUser() {
    const user = JSON.parse(localStorage.getItem('device-id') || 'false')
    if (user) return user;
    return null;
  }

  isLoggedIn() {
    return this.getUser() != null;
  }

  updateUser(foto: string, nome: string) {
    const user = this.getUser();
    user.foto = foto;
    user.nome = nome;
    this.setUser(user);
  }

  login(login: any): Observable<any> {
    const url = `${super.getUrl()}login`;
    return this.http.post(url, login);
  }

  getActions(): Observable<any> {
    const url = `${super.getUrl()}adm/get-actions`;
    return this.http.post(url, {}, this.getOptions());
  }

  logout() {
    // this.isLogin = false;
    // this.roleAs = '';
    // localStorage.setItem('STATE', 'false');
    // localStorage.setItem('ROLE', '');
    // return of({ success: this.isLogin, role: '' });
    localStorage.removeItem('device-id');
  }

  getRole() {
    const device = JSON.parse(localStorage.getItem("device-id") || "")
    if (device) {
      this.roleAs = device.nome_perfil;
      return this.roleAs;
    }
    return "";
    //this.roleAs = localStorage.getItem('ROLE');
  }

  changeLanguage(language: string) {
    localStorage.setItem('language', JSON.stringify(language));
  }

  getLanguage() {
    const language = JSON.parse(localStorage.getItem('language') || 'false')
    if (language)
      return language
    this.changeLanguage('pt-br')
    return 'pt-br';
  }
}
