import { Component, Input, OnInit } from '@angular/core';
import { AvaliarService } from '../service/avaliar.service';

@Component({
  selector: 'app-avaliacao-estrela',
  template: `
   <div class="rank">
    <span class="star {{avaliacaoMedia.resultado >= 1 ? 'active' : ''}}">
        <i class="fas fa-star"></i>
    </span>
    <span class="star {{avaliacaoMedia.resultado >= 2 ? 'active' : ''}}">
        <i class="fas fa-star"></i>
    </span>
    <span class="star {{avaliacaoMedia.resultado >= 3 ? 'active' : ''}}">
        <i class="fas fa-star"></i>
    </span>
    <span class="star {{avaliacaoMedia.resultado >= 4 ? 'active' : ''}}">
        <i class="fas fa-star"></i>
    </span>
    <span class="star {{avaliacaoMedia.resultado >= 5 ? 'active' : ''}}">
        <i class="fas fa-star"></i>
    </span>
    <small> ({{avaliacaoMedia.total}}) {{complemento}}</small>
</div>
  `
})
export class AvaliacaoEstrelaComponent implements OnInit {
  @Input() pesId: any;
  @Input() complemento: string = "";

  avaliacaoMedia = {
    resultado: 0,
    total: 0
  }
  constructor(
    private avaliarService: AvaliarService
  ) { }

  ngOnInit(): void {
    this.getAvaliacao();

    if (this.complemento) {
      this.complemento = "comentário"
      if (this.avaliacaoMedia.total > 0) {
        this.complemento = "comentários"
      }
    }
  }

  getAvaliacao() {
    let filter = {
      id_pessoa: this.pesId
    }
    this.avaliarService.getMedia(filter).subscribe(next => {
      this.avaliacaoMedia = next[0];
    })
  }
}
