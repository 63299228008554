<!-- ======================conteudo===================== -->
<main>

  <div class="conteudo">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item redirect" routerLink="/home">Página Inicial</li>
        <li class="breadcrumb-item active" aria-current="page">Gerenciar Contas</li>
      </ol>
    </nav>

    <h3>Gerenciar Contas</h3>
    <!-- =============LISTA PRODUTOS=================== -->
    <div class="lista-produtos pagina">

      <div class=" filtro form-row align-items-center">
        <label for="">
          Filtros
        </label>

        <div class="col">
          <div class="form-group">
            <input type="text" [(ngModel)]="filtro.nome" class="form-control" placeholder="Nome">
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <input type="text" [(ngModel)]="filtro.email" class="form-control" placeholder="Email">
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <input type="text" [(ngModel)]="filtro.cpf" class="form-control" placeholder="CPF/CNPJ" mask="CPF_CNPJ">
          </div>
        </div>
        <button class="btn btn-transparent" (click)="filtrar()">
          <i class="fas fa-filter"></i>
          <span>filtrar</span>
        </button>
      </div>

      <button type="submit" *permission="['add-user']" class="btn btn-padrao btn-sm mb-5" (click)="criarUsuario()">
        <i class="fas fa-user-friends"></i>
        cadastrar conta administrativa
      </button>

      <div class="d-flex justify-content-center" *ngIf="usuarios.rows.length <= 0">
        <span class="mt-5">Sem registros</span>
      </div>

      <div *ngIf="usuarios.rows.length > 0">
        <h4> Lista de contas administrativas</h4>

        <ul class="list">

          <!-- ==============ITEM LISTA=============== -->
          <li *ngFor="let usuario of usuarios.rows; let i = index">
            <div class="image">
              <!-- <img src="{{usuario.templateFoto ? usuario.templateFoto : 'assets/images/user.svg'}}" alt=""> -->
              <img src="{{usuario.templateFoto || 'assets/images/user.svg'}}" alt="">
            </div>
            <div class="texto">
              <h5>{{usuario.nome}}</h5>
              <ul>
                <li>
                  <strong>E-mail: </strong>
                  {{usuario.email}}
                </li>
                <li *ngIf="usuario.cpf">
                  <strong>CPF/CNPJ: </strong>
                  {{usuario.cpf | mask: 'CPF_CNPJ'}}
                </li>
              </ul>

            </div>
            <div class="botoes">
              <button type="submit" *permission="['edit-user']" class="btn btn-transparent" title="Editar"
                (click)="editarUsuario(usuario)">
                <i class="fas fa-edit"></i>
              </button>
              <div *ngIf="!usuario.ativo">
                <button *permission="['active-user']" type="submit" class="btn btn-transparent" title="Ativar"
                  (click)="mudarStatusUsuario(usuario, 'Ativar')">
                  <i class="fas fa-check"></i>
                </button>
              </div>
              <div *ngIf="usuario.ativo">
                <button *permission="['inactive-user']" type="submit" class="btn btn-transparent" title="Inativar"
                  (click)="mudarStatusUsuario(usuario, 'Inativar')">
                  <i class="fas fa-ban"></i>
                </button>
              </div>

            </div>

          </li>
          <!-- ==============FIM ITEM LISTA=============== -->
        </ul>
        <div class="d-flex justify-content-center">
          <nav>
            <ul class="pagination">
              <li class="page-item {{ paginaAtual == 1 ? 'disabled' : ''}}" *ngIf="usuarios.rows.length > 0">
                <a class="page-link" *ngIf="paginaAtual == 0"><i class="fas fa-chevron-left"
                    style="cursor: pointer;"></i></a>
                <button class="page-link" (click)="getUsuariosList(paginaAtual - 1)" [disabled]="paginaAtual == 1">
                  <i class="fas fa-chevron-left"></i>
                  <div *ngIf="qtdPaginas[0] != 1">...</div>
                </button>
              </li>
              <li class="page-item " *ngFor="let opt of qtdPaginas;">
                <a class="page-link active" *ngIf="paginaAtual == opt" style="cursor: pointer;">{{ opt }}</a>
                <button class="page-link" (click)="getUsuariosList(opt)" *ngIf="paginaAtual != opt">
                  {{ opt }}
                </button>
              </li>
              <li class="page-item {{ paginaAtual == usuarios.qtdTotalPaginas ? 'disabled' : ''}}"
                *ngIf="usuarios.rows.length > 0">
                <a class="page-link" *ngIf="paginaAtual == (qtdPaginas-1)" style="cursor: pointer;"><i
                    class="fas fa-chevron-right"></i></a>
                <button class="page-link" (click)="getUsuariosList(paginaAtual + 1)"
                  [disabled]="paginaAtual == usuarios.qtdTotalPaginas">
                  <div *ngIf="qtdPaginas[qtdPaginas.length - 1] != usuarios.qtdTotalPaginas">...</div>
                  <i class="fas fa-chevron-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <!-- =============FIM LISTA PRODUTOS=================== -->

  </div>
  <!-- =====================FIM =conteudo===================== -->