import { Directive, TemplateRef, Input, ViewContainerRef, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';


@Directive({
  selector: '[permission]'
})
export class PermissionDirective implements OnInit {

  @Input() permission!: [string];

  private allowedActions = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.load();
  }

  load() {
    const user = this.authService.getUser();
    if (user) {
      this.allowedActions = user.acoes || [];
      const verification = this.allowedActions.some((value) => this.permission.includes(value));
      if (verification) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        return;
      }
    }
    this.viewContainer.clear();
  }
}
