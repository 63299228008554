<!-- ======================conteudo===================== -->
<main>
  <div class="conteudo">

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item redirect" routerLink="/home">
          {{menuService.RetornarDestaqueGerenciador('pessoas').Titulo}}</li>
        <li class="breadcrumb-item redirect" routerLink="/lista-noticia">
          {{menuService.RetornarDestaqueGerenciador('noticias').Titulo}}</li>
      </ol>
    </nav>

    <!-- =============LISTA PRODUTOS=================== -->
    <div class="detalhe-noticia ">
      <h3>{{menuService.RetornarDestaqueGerenciador('noticias').Titulo}}</h3>
      <div class="title-header">
        <h2>
          {{noticia.Titulo}}
        </h2>
        <div class="data">
          <span>{{noticia.DataPublicacao}}</span>
          <div class="aumentar-fonte">
            <a [href]="" class="btn" (click)="sizeUpFont()" style="cursor: pointer;">
              A
              <i class="fas fa-plus"></i>
            </a>
            <a [href]="" class="btn" (click)="sizeDownFont()" style="cursor: pointer;">
              A
              <i class="fas fa-minus"></i>
            </a>
          </div>
        </div>
        <div *ngIf="noticia.Fonte">
          <strong>Fonte:</strong>
          <a href="{{noticia.URLFonte}}" target="_blank">{{noticia.Fonte}}</a>
        </div>
      </div>
    </div>
    <div [class]="acessibilidade" [innerHtml]="textHtml"></div>
  </div>
</main>
