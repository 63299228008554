<div class="modal-content modal-mapa">
  <div class="modal-header">
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <section class="bloco-mapa">
    <div class="modal-header">
      <button type="button" class="close" (click)="activeModal.dismiss('Cross click')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="center">


      <div class="map-container">
        <input type="checkbox" class="" id="check-mapa">
        <div class="pesquisa">
          <label class="bt-check-mapa" for="check-mapa">
            <i class="fas fa-chevron-left"></i>
          </label>
          <div class="bloco">
            <div class="head">
              <h3 *ngIf="pt">Mapa de Afroempreendedores</h3>
              <div *ngIf="pt" style="padding-bottom: 10px">Pesquise aqui o Afroempreendedor pelo nome.</div>
              <h3 *ngIf="!pt">Afroempreendedores Map</h3>
              <div *ngIf="!pt" style="padding-bottom: 10px">Search for the Afroempreendedor by name.</div>
              <div class="w-100 form-group d-flex">
                <input type="text" class="form-control" placeholder="{{placeHolder}}" name="term"
                  (keypress)="filter($event)" [(ngModel)]="term">
                <button class="btn" for="search" (click)="filter($event)">
                  <i class="fas fa-search"></i>
                </button>
              </div>

            </div>
            <div *ngIf="pt">
              <h6 *ngIf="resultados >= 0"><strong>{{resultados}} </strong>resultados</h6>
              <h6 *ngIf="resultados == 0">Nenhum resultado encontrado</h6>
            </div>
            <div *ngIf="!pt">
              <h6 *ngIf="resultados >= 0"><strong>{{resultados}} </strong>results</h6>
              <h6 *ngIf="resultados == 0">no results found</h6>
            </div>

            <div class="scrollbar-inner">
              <div class="list-group">
                <a class="list-group-item" style="cursor: pointer;" *ngFor="let item of pessoas"
                  (click)="irParaPessoa(item.id)">
                  <div class="user">
                    <img src="{{item.foto}}" alt="">
                  </div>
                  <div class="texto">
                    <h5 class="mb-1">{{item.nome_fantasia}}</h5>
                    <p>{{item.nome}}</p>
                    <small class="mb-1">{{item.endereco}}</small>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="map-frame">
          <div id="map"></div>
        </div>
      </div>
    </div>
  </section>
</div>
