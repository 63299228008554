import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class FavoritoService extends MainService {

  constructor(private http: HttpClient) { super(); }

  add(favorito: any) {
    const url = `${super.getUrl()}favorito/add`;
    return this.http.post<any[]>(url, favorito, this.getOptions());
  }

  delete(favorito: any) {
    const url = `${super.getUrl()}favorito/delete`;
    return this.http.post<any>(url, favorito, this.getOptions());
  }

  list(filter: any) {
    const url = `${super.getUrl()}favorito/list`;
    return this.http.post<any>(url, filter, this.getOptions());
  }

  listByPessoa(filter: any) {
    const url = `${super.getUrl()}favorito/listByPessoa`;
    return this.http.post<any>(url, filter, this.getOptions());
  }
  // delete(idFavorito: string) {
  //   const url = `${super.getUrl()}favorito/delete/${idFavorito}`;
  //   return this.http.delete<any>(url, this.getOptions());
  // }
}
