import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListaServicoComponent } from "./lista-servico.component";
import { FormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from "ngx-mask";


@NgModule({
  declarations: [ListaServicoComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    RouterModule
  ],
  exports:[
    ListaServicoComponent
  ]
})
export class ListaServicoModule { }
