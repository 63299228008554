import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LoginComponent } from '../login/login.component';
import { PessoaService } from '../service/pessoa.service';

@Component({
  selector: 'app-precadastro',
  template: `
  <div class="modal-content">
  <div class="modal-header">
      <h3>Cadastro de usuário</h3>

      <button type="button" class="close" (click)="activeModal.dismiss('Cross click')">
          <i class="fas fa-times"></i>
      </button>
  </div>

  <div class="modal-body">
      <p>Por favor, insira seu <strong>e-mail</strong> ou seu <strong>CPF/CNPJ</strong> para iniciar o cadastro no AFROBIZ Salvador</p>
          <div class="row">
              <div class="col-sm-12">
                  <div class="form-group ">
                      <label for="">
                        E-mail:
                        <a aria-label="Digite seu E-mail" data-balloon-pos="up"
                            class="" style="cursor: pointer;">
                            <i class="fas fa-question-circle"></i>
                        </a>
                      </label>
                      <input [(ngModel)]="pessoa.email" type="text" class="form-control" placeholder="" maxlength="150">
                  </div>
              </div>
              <div class="col-sm-12">
                  <div class="form-group ">
                      <label for="">
                        CPF/CNPJ:
                        <a aria-label="Digite o CPF ou CNPJ" data-balloon-pos="up"
                            class="" style="cursor: pointer;">
                            <i class="fas fa-question-circle"></i>
                        </a>
                      </label>
                      <input [(ngModel)]="pessoa.cpf" type="text" class="form-control" placeholder="" mask="CPF_CNPJ"
                          (keydown.enter)="IniciarCadastro()">

                  </div>
              </div>
           </div>
           <strong class="msg" *ngIf="existeCadastro">Já existe um registro com o(s) dado(s) inserido(s) acima. <a [routerLink]="" (click)="IrParaLogin()">Clique aqui</a> para continuar.</strong>

      <div class="d-flex justify-content-center">
          <button type="button" class="btn btn-padrao" (click)="IniciarCadastro()">
              <i class="fas fa-sign-in-alt"></i>
              INICIAR CADASTRO
          </button>
      </div>
  </div>
  <div class="modal-footer justify-content-center">
      <p>Já possui cadastro na plataforma?<a [routerLink]="" (click)="IrParaLogin()" style="cursor: pointer;"><strong>Acesse aqui!</strong></a></p>
  </div>

</div>
  `
})
export class PrecadastroComponent {

  pessoa: any;
  existeCadastro = false;

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private pessoaService: PessoaService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.pessoa = {
      email: "",
      cpf: "",
      nome: "",
      celular: "",
      importacao: false
    };
  }

  IniciarCadastro() {
    if (!this.pessoa.email && !this.pessoa.cpf) {
      this.toastr.error("Por favor, informe o email ou o CPF/CNPJ", "Erro");
      return;
    }

    if (this.pessoa.cpf) {
      if (!this.pessoaService.validateCpfCnpj(this.pessoa.cpf)) {
        this.toastr.error("Por favor, informe o CPF/CNPJ válido", "Erro");
        return;
      }
    }

    this.pessoaService.searchImportacao(this.pessoa).subscribe(
      (response: any) => {

        if (Array.isArray(response) && response.length > 0) {
          if (response[0].Usuario?.id) {
            this.existeCadastro = true;
            this.toastr.error("Já existe um registro completo no sistema. Entre com seu e-mail e senha em ACESSE", "Erro");
            this.IrParaLogin();
            return;
          }
          if (response[0].nome) {
            this.activeModal.close()
            if (response[0].telefone && response[0].telefone.length > 0 && response[0].telefone.length < 11) {
              response[0].telefone = `71${response[0].telefone}`;
            }
            this.pessoaService.pessoa = response[0];
            this.pessoaService.pessoa.importacao = true;
            this.router.navigate(['pessoa']);
            return;
          }
        }
        else {
          this.IrParaCadastro();
        }
      },
      error => {
        this.toastr.error(error, "Erro");
      })
  }

  IrParaLogin() {
    this.activeModal.close();
    this.modalService.open(LoginComponent);
  }

  IrParaCadastro() {
    this.activeModal.close();
    this.pessoaService.pessoa = this.pessoa;
    this.router.navigate(['pessoa']);

  }
}
