import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from '../layout/main-layout/main-layout.component';
import { PessoaExternoListaComponent } from './pessoa-externo-lista/pessoa-externo-lista.component';
import { PessoaExternoDetalheComponent } from './pessoa-externo-detalhe/pessoa-externo-detalhe.component';
import { PessoaExternoPesquisaComponent } from './pessoa-externo-pesquisa/pessoa-externo-pesquisa.component';
import { LayoutInternoComponent } from '../layout/layout-interno/layout-interno.component';

const routes: Routes = [
  {
    path: 'resultado-da-busca',
    component: LayoutInternoComponent, children: [{ path: '', component: PessoaExternoListaComponent }]
  },
  {
    path: 'detalhe-pessoa',
    component: LayoutInternoComponent, children: [{ path: '', component: PessoaExternoDetalheComponent }]
  },
  {
    path: 'resultado-da-busca2',
    component: MainLayoutComponent, children: [{ path: '', component: PessoaExternoPesquisaComponent }]
  }
]

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PessoaExternoRoutingModule { }
