import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { PessoaService } from '../service/pessoa.service';
import { UsuarioService } from '../service/usuario.service';
import { ToastrService } from 'ngx-toastr';
import { SharedDataService } from '../service/shared-data.service';
import { ServicoService } from '../service/servico.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pessoa-lista',
  templateUrl: './pessoa-lista.component.html',
  styleUrls: ['./pessoa-lista.component.css']
})
export class PessoaListaComponent implements OnInit {

  tipo: any;
  pessoas: any;
  filtro: any;
  paginaAtual: number;
  qtdPaginas: any;
  qtdItensPorPagina: any;
  categoriaList: any;
  subcategoriaList: any;
  subcategoriaDisabledToggle: boolean;
  tipoCadastroList = [{ id: 'S', nome: 'Serviço' }, { id: 'P', nome: 'Produto' }];

  constructor(
    private usuarioService: UsuarioService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private pessoaService: PessoaService,
    private toastr: ToastrService,
    private sharedDataService: SharedDataService,
    private servicoService: ServicoService
  ) {
    this.subcategoriaDisabledToggle = true;
    this.paginaAtual = 1;
    this.qtdPaginas = [];
    this.qtdItensPorPagina = 15;
    this.pessoas = { rows: [] };
    this.filtro = {
      tipo: '',
      //existeProdutoServico: false,
      semProdutoServico: false,
      id_categoria: '',
      id_subcategoria: '',
      nome: '',
      cidade: '',
      cpf: ''
    };
  }

  ngOnInit(): void {
    this.usuarioService.usuario = "";
    window.scroll({ top: 0, left: 0 });
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(["home"]);
      return;
    } else {
      this.tipo = this.route.snapshot.data['tipo'] || "";
      this.getPessoasList(this.paginaAtual);
      this.getCategoriaList();
    }
  }

  filtrar() {
    this.getPessoasList(1);
  }

  getPessoasList(pagina: number) {

    this.paginaAtual = pagina;

    this.filtro.offset = (pagina - 1) * this.qtdItensPorPagina;
    this.filtro.limit = this.qtdItensPorPagina;
    this.filtro.usuarioLogado = this.authService.getUser().id;

    this.pessoaService.getPessoas(this.filtro).subscribe((list: any) => {
      list.rows = list.rows.map((pes: any) => {
        //pes.templateFoto = pes.foto ? `${this.pessoaService.getUrl()}images/${pes.foto}` : "./../../assets/images/user.jpg";
        pes.templateFoto = pes.foto || "./../../assets/images/user.jpg";
        return pes;
      });
      this.pessoas = list;
      const qtdTotalPaginas = Math.ceil(this.pessoas.count / this.qtdItensPorPagina);
      this.pessoas = { ...this.pessoas, qtdTotalPaginas };
      this.qtdPaginas = [];
      this.qtdPaginas.push(pagina);
      let lastcount = 0;
      while (this.qtdPaginas.length != lastcount && this.qtdPaginas.length < 5) {
        lastcount = this.qtdPaginas.length;
        this.qtdPaginas.unshift(this.qtdPaginas[0] - 1);
        this.qtdPaginas.push(this.qtdPaginas[this.qtdPaginas.length - 1] + 1);
        this.qtdPaginas = this.qtdPaginas.filter(function (el: any) {
          return el > 0;
        })
        this.qtdPaginas = this.qtdPaginas.filter(function (el: any) {
          return el <= qtdTotalPaginas;
        })
      }
    });
  }

  mudarStatusUsuario(pessoa: any, status: string) {
    const frase = `Tem certeza que deseja ${status.toLowerCase()} a pessoa/entidade ${pessoa.nome}?`;
    Swal.fire({
      title: "",
      text: frase,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        this.usuarioService.mudarStatusUsuario({ id: pessoa.Usuario.id }).subscribe((usuarios: any) => {
          this.getPessoasList(this.paginaAtual);
          this.toastr.success("Status atualizado com sucesso", "Sucesso");
        });
      }
    });
  }

  criarPessoa() {
    this.pessoaService.pessoa = '';
    this.pessoaService.isFromRoute = 'adm-listagem-pessoas';
    this.router.navigate(["adm-cadastrar-dados-pessoais"]);
  }

  editarPessoa(pessoa: any) {
    this.pessoaService.pessoa = '';
    this.usuarioService.usuario = pessoa.Usuario;
    this.pessoaService.isFromRoute = 'adm-listagem-pessoas';
    this.router.navigate(["adm-cadastrar-dados-pessoais"]);
  }

  listagemProdutosServicos(pessoa: any) {
    this.servicoService.usuario = pessoa.Usuario;
    this.sharedDataService.setData({ tipoTela: 'lista' })
    this.router.navigate(['adm-servico-lista']);
  }

  getSubcategoriaList() {
    this.subcategoriaList = [];
    this.subcategoriaDisabledToggle = true;
    this.filtro.id_subcategoria = "";
    if (this.filtro && this.filtro.id_categoria) {
      this.servicoService.getSubcategoriaList(this.filtro.id_categoria).subscribe(list => {
        this.subcategoriaList = list;
        if (this.subcategoriaList.length > 0) {
          this.subcategoriaDisabledToggle = false;
        }
      });

    }
  }

  getCategoriaList() {
    this.servicoService.getCategoriaList().subscribe(list => this.categoriaList = list);
  }
}
