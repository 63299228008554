import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UsuarioService } from '../service/usuario.service';
import { LoginComponent } from 'src/app/login/login.component';

@Component({
  selector: 'app-recupera-senha',
  template: `
<div class="modal-content">
  <div class="modal-login">
    <div class="modal-header">

      <button type="button" class="close" (click)="activeModal.dismiss('Cross click')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="modal-body">
      <div class="text-center">
        <h4>Recuperação de senha:</h4>
        <div class="row">
        </div>
      </div>

      <div class="col-sm-12">
      <p>
        identifique-se para receber um e-mail para receber a senha cadastrada no <strong>Afrobiz Salvador</strong>.
      </p>
        <div class="input-group mb-3">
          <label for="" class="w-100">
            E-mail:
            <a aria-label="Por favor, insira o e-mail cadastrado na plataforma"
              data-balloon-pos="up" class="" style="cursor: pointer;">
              <i class="fas fa-question-circle"></i>
            </a>
          </label>
          <input [(ngModel)]="email" type="text" class="form-control" placeholder="" (keydown.enter)="EnviaEmail()">
        </div>
      </div>
      <div class="botoes text-center">
       
          <button type="button" class="btn btn-danger btn-lg" (click)="activeModal.dismiss('Cancelar click')">
            <i class="fas fa-check"></i>
            cancelar
          </button>

          <button type="button" class="btn btn-padrao btn-lg ml-2" (click)="EnviaEmail()">
             <i class="fas fa-envelope"></i>
            enviar senha
          </button>


      </div>

    </div>
    <div class="modal-footer">
      <p>Ainda não faz parte? <a style="cursor: pointer;" href=""><strong>Cadastre-se! </strong></a></p>
    </div>
  </div>
</div>
  `
})
export class RecuperaSenhaComponent implements OnInit {
  email = ""
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit(): void {
  }

  cancelar() {
    this.activeModal.close();
    this.modalService.open(LoginComponent);
  }

  EnviaEmail() {
    if (!this.email) {
      this.toastr.error("Por favor informe um e-mail", "Erro");
      return
    }

    this.usuarioService.recuperaSenha(this.email).subscribe(next => {
      this.toastr.success("Uma nova senha foi enviada para seu e-mail", "Sucesso");
      this.activeModal.close();
    },
      erro => {
        this.toastr.error(erro.error, "Erro");
      })
  }

}
