import { UpperCasePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { LoginComponent } from 'src/app/login/login.component';
import { AuthService } from 'src/app/service/auth.service';
import { MenuService } from 'src/app/service/menu.service';
import { PessoaService } from 'src/app/service/pessoa.service';
import { ServicoService } from 'src/app/service/servico.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { GerenciadorConteudoService } from 'src/app/service/gerenciador-conteudo.service';
import { PrecadastroComponent } from 'src/app/precadastro/precadastro.component';
import { VersionamentoService } from 'src/app/service/versionamento.service';
import { MapaComponent } from 'src/app/home/mapa/mapa.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-headerr',
  templateUrl: './headerr.component.html',
  styleUrls: ['./headerr.component.css'],
})
export class HeaderrComponent implements OnInit {
  logado = false;
  pessoa: any = {};
  menu: any = [];
  infosLogado: any = [];
  infosDeslogado: any = [];
  options: any = [];
  login: any;

  filter = {
    pessoa: '',
    servico: '',
  };

  idioma = {
    imagem: 'assets/images/icon-br.svg',
    texto: 'BR',
  };

  servicos: any;
  pt: any = true;
  versao: any = '';

  arrTerms: any = [];

  private searchTermsServico = new Subject<string>();

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private router: Router,
    private pessoaService: PessoaService,
    private usuarioService: UsuarioService,
    private menuService: MenuService,
    private gerenciadorConteudo: GerenciadorConteudoService,
    private servicoService: ServicoService,
    private versionamento: VersionamentoService
  ) {}

  ngOnInit(): void {
    var idioma = this.authService.getLanguage();
    this.trocaBandeira(idioma);

    this.carregaMenu();
    this.version();

    if (this.authService.getLanguage() === 'us-en') {
      this.pt = false;
    }

    this.gerenciadorConteudo
      .getHighlights('home_prodetur', this.authService.getLanguage())
      .subscribe((result: any) => {
        this.menuService.destaquesGerenciador = result;
      });

    this.gerenciadorConteudo
      .getMenu('topo_prodetur', this.authService.getLanguage())
      .subscribe((result: any) => {
        if (result.error) {
          console.log('topo-prodetur ', result.error);
          this.options = [];
          return;
        }
        this.options = result.Itens;
      });

    this.gerenciadorConteudo
      .getMenu('info_logado_prodetur', this.authService.getLanguage())
      .subscribe((result: any) => {
        if (result.error) {
          console.log('info-prodetur logado ', result.error);
          this.infosLogado = [];
          return;
        }
        this.infosLogado = result.Itens;
      });

    this.gerenciadorConteudo
      .getMenu('info_deslogado_prodetur', this.authService.getLanguage())
      .subscribe((result: any) => {
        if (result.error) {
          console.log('info-prodetur deslogado ', result.error);
          this.infosDeslogado = [];
          return;
        }
        this.infosDeslogado = result.Itens;
      });

    this.gerenciadorConteudo
      .getMenu('login_prodetur', this.authService.getLanguage())
      .subscribe((result: any) => {
        if (result.error) {
          console.log('login-prodetur ', result.error);
          this.login = [];
          return;
        }
        if (result && result.Itens) {
          this.login = result.Itens[0];
        }
      });

    if (this.authService.isLoggedIn()) {
      this.logado = true;
      this.pessoa = this.authService.getUser();
    }

    this.servicos = this.searchTermsServico.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => this.servicoService.searchServico(term))
    );
  }

  Sair() {
    this.authService.logout();

    window.location.href = window.location.href;
    //this.reload();
    //this.router.navigate(["home"]);
  }

  reload() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  criarPessoa() {
    this.pessoaService.isFromRoute = 'home';
    this.usuarioService.usuario = '';
    this.router.navigate(['adm-cadastrar-dados-pessoais']);
  }
  resultadoBusca(subid: any, catNome: any, subNome: any) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    //this.router.navigate(["sp"], { queryParams: { subId: subid, subTitulo: subNome, catTitulo: catNome } });
    this.router.navigate(['resultado-da-busca'], {
      queryParams: { sub: subid },
    });
  }

  acessarAdministracao() {
    const userId = this.authService.getUser().id;
    this.gerenciadorConteudo
      .loginManager(userId)
      .subscribe((result: { toString: () => any }) => {
        const link = result.toString();
        window.open(link, '_blank');
      });
  }

  clickLogin() {
    this.modalService.open(LoginComponent);
  }

  removeDuplicates(arr: any) {
    const arrNew: any = [];
    let termAux = '';

    arr.forEach((item: any) => {
      const itemNoAccent = item.nome
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      const itemActual = itemNoAccent.trim();

      if (itemActual != termAux) {
        arrNew.push(item);
      }

      termAux = itemActual;
    });

    return arrNew;
  }

  searchServico(term: string) {
    if (term.length > 2 || term.length == 0) {
      this.searchTermsServico.next(term);
      this.servicoService.searchServico(term).subscribe((el) => {
        const arrNoDuplicate = this.removeDuplicates(el);

        if (arrNoDuplicate.length > 10) {
          while (arrNoDuplicate.length > 10) {
            arrNoDuplicate.pop();
          }
        }

        this.arrTerms = arrNoDuplicate;
      });
    }
  }

  selecionaServico(term: string) {
    this.searchTermsServico.next('');
    this.filter.servico = term;
  }

  Filtrar(event: any) {
    if (event.key && event.key === 'Enter') {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.navigate(['sp'], {
        queryParams: { search: this.filter.servico },
      });
    } else {
      if (event.pointerType === 'mouse') {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this.router.navigate(["sp"], { queryParams: { search: this.filter.servico } });
        this.router.navigate(['sp'], {
          queryParams: { search: this.filter.servico },
        });
        //this.router.navigate(["resultado-da-busca2"], { queryParams: { pes: this.filter.pessoa, serv: this.filter.servico } });
      }
    }
  }

  carregaMenu() {
    let _idioma = this.authService.getLanguage();
    this.menuService.Menu(_idioma).subscribe((next) => {
      this.menu = next;
    });
  }

  openLink(option: any): void {
    if (option.Titulo == 'Mapa') {
      const modalRef = this.modalService.open(MapaComponent);
    } else if (option && option.URL == 'cadastro') {
      const content = 'precadastro';
      this.open(content);
    } else if (option && option.Titulo == 'O que é') {
      let el = document.getElementsByTagName('html')[0];
      if (el.classList.value.includes('dark')) {
        el.classList.value = '';
        return;
      }
      el.setAttribute('class', 'dark');
    } else if (option && option.Titulo == 'Crie sua vitrine') {
      if (this.authService.isLoggedIn()) {
        this.router.navigate(['servico']);
      } else {
        this.modalService.open(PrecadastroComponent);
      }
    } else {
      this.router.navigate([option.URL]);
    }
  }

  open(content: any): void {
    if (content == 'precadastro') {
      this.modalService.open(PrecadastroComponent);
    }
  }

  trocaIdioma(idioma: string) {
    if (idioma != this.authService.getLanguage()) {
      if (idioma == 'us-en' && this.authService.isLoggedIn()) {
        Swal.fire({
          title: '',
          text: `Ao mudar o idioma o usuário logado será deslogado e direcionado para página inicial. Deseja continuar?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.value) {
            this.authService.logout();
            this.trocaBandeira(idioma);
            window.location.href = '/home';
          }
        });
      } else {
        this.trocaBandeira(idioma);
        window.location.href = '/home';
      }
    }
  }

  trocaBandeira(idioma: string) {
    this.authService.changeLanguage(idioma);
    var _idioma = idioma.slice(3).toUpperCase();
    this.idioma = {
      imagem: `assets/images/icon-${_idioma.toLocaleLowerCase()}.svg`,
      texto: _idioma,
    };
  }

  verificarPermissaoClasse(info: any) {
    if (
      info &&
      info.Titulo === 'Crie sua vitrine' &&
      this.pessoa.nome_perfil != 'Pessoa'
    ) {
      return false;
    } else if (info) {
      return true;
    } else {
      return false;
    }
  }

  verificarPermissaoTitulo(info: any) {
    if (
      info &&
      info.Titulo === 'Crie sua vitrine' &&
      this.pessoa.nome_perfil != 'Pessoa'
    ) {
      return false;
    } else if (info.Titulo != 'O que é') {
      return true;
    } else {
      return false;
    }
  }

  version() {
    this.versionamento.getVersion().subscribe((result: any) => {
      this.versao = result.valor;
    });
  }
}
