import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class GerenciadorConteudoService extends MainService {

  evento: any;
  noticia: any;

  constructor(private http: HttpClient) { super(); }

  getNewsList(currentPage: number, idioma: string) {
    const url = `${super.getUrl()}gerenciador_conteudo/getNewsList`;
    return this.http.post<any[]>(url, { page: currentPage, idioma }, this.getOptions())
  }

  getEventsList(currentPage: number, idioma: string) {
    const url = `${super.getUrl()}gerenciador_conteudo/getEventsList`;
    return this.http.post<any[]>(url, { page: currentPage, idioma }, this.getOptions())
  }

  getNews(id: Number, idioma: String) {
    const url = `${super.getUrl()}gerenciador_conteudo/getNews`;
    return this.http.post<any[]>(url, { id, idioma }, this.getOptions());
  }

  getNewsSpotlight(idioma: string) {
    const url = `${super.getUrl()}gerenciador_conteudo/getNewsSpotlight`;
    return this.http.post<any[]>(url, { idioma }, this.getOptions())
  }

  getEventsSpotlight(idioma: string) {
    const url = `${super.getUrl()}gerenciador_conteudo/getEventsSpotlight`;
    return this.http.post<any[]>(url, { idioma }, this.getOptions())
  }

  loginManager(user_id: string) {
    const url = `${super.getUrl()}gerenciador_conteudo/loginManager`;
    return this.http.post<any>(url, { user_id }, this.getOptions())
  }

  getSurvey(idioma: string) {
    const url = `${super.getUrl()}gerenciador_conteudo/getSurvey`;
    return this.http.post<any[]>(url, { idioma }, this.getOptions())
  }

  voteResult(idioma: string) {
    const url = `${super.getUrl()}gerenciador_conteudo/voteResult`;
    return this.http.post<any>(url, { idioma }, this.getOptions())
  }

  vote(resposta: any) {
    const url = `${super.getUrl()}gerenciador_conteudo/vote`;
    return this.http.post<any[]>(url, { resposta }, this.getOptions())
  }

  getIp() {
    const ipLink = 'http://ip-api.com/json';
    return this.http.get<ApiIpReturn>(ipLink, this.getOptions())
  }

  getMenu(alias: any, idioma: string) {
    const url = `${super.getUrl()}gerenciador_conteudo/getMenu`;
    return this.http.post<any[]>(url, { alias, idioma }, this.getOptions());
  }

  getHighlights(alias: any, idioma: string) {
    const url = `${super.getUrl()}gerenciador_conteudo/getHighlightsSpotligth`;
    return this.http.post<any[]>(url, { alias, idioma }, this.getOptions());
  }
}

export interface ApiIpReturn {
  as: string
  city: string
  country: string
  countryCode: string
  isp: string
  lat: number
  lon: number
  org: string
  query: string
  region: string
  regionName: string
  status: string
  timezone: string
  zip: string
}


