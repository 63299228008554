import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Dimensions, ImageCroppedEvent, ImageTransform } from '../interfaces';
import { base64ToFile } from '../utils/blob.utils';

@Component({
  template: `
<div class="modal-crop">
 <div class="modal-content">
    <div class="modal-header">
        <h3>Inserir Foto</h3>
        <button type="button" class="close" (click)="activeModal.dismiss('Cross click')">
            <i class="fas fa-times"></i>
        </button>
    </div>

    <div class="modal-body">
        
        <div class="steps">
        <div class="col">
            <strong>
            Dicas:
            </strong>   
            <p>                
            Para uma melhor visualização do seu perfil, seu produto ou serviço opte por tirar e fotos no seu celular ou câmera sempre no formato retrato (celular “em pé” - fig.1) para a foto de cadastro.
            Para a foto de seu produto ou serviço, escolha imagens em formato retrato ou quadrada (com celular “deitado” - <strong>fig.2 </strong>)
            </p>
        
        </div>
            <img src="assets/images/crop-steps.svg" alt="">
           
        </div>
        <div *ngIf="showControlPanel" style="display: none">
            <input type="file" (change)="fileChangeEvent($event)" />
            <br />
            <br />
            <button (click)="rotateLeft()">Rotate left</button>
            <button (click)="rotateRight()">Rotate right</button>
            <button (click)="flipHorizontal()">Flip horizontal</button>
            <button (click)="flipVertical()">Flip vertical</button>
            <br />
            <br />
            <button (click)="toggleContainWithinAspectRatio()">{{containWithinAspectRatio?'Fill Aspect Ratio':'Contain
                Within Aspect Ratio'}}</button>
            <button (click)="resetImage()">Reset image</button>
            <br />
            <br />
            <input [(ngModel)]="rotation" placeholder="Rotation" type="number" (keyup)="updateRotation()" /> <button
                (click)="zoomOut()">Zoom -</button> <button (click)="zoomIn()">Zoom +</button>
        </div>
        <div class="crop-area row">
          <div class="image">
              <h4>Imagem original</h4>
              <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                  [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="aspectRatio" [resizeToWidth]="256"
                  [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
                  [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="png"
                  (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                  (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>
                  
                  <strong>
                    Caso precise melhorar a visualização da sua foto com ajustes rápidos, você pode usar os controles abaixo antes de salvar. 
                  </strong> 
                  <div class="controles">
                    <button type="button" class="btn btn-padrao only-icon btn-sm">
                      <input type="file" (change)="fileChangeEvent($event)" />
                      <i class="fas fa-camera"></i>
                    </button>
                    <button (click)="rotateLeft()" type="button" class="btn btn-padrao only-icon btn-sm ">
                       <i class="fas fa-undo"></i>
                    </button>
                    <button  (click)="rotateRight()" style="transform: scaleX(-1);" type="button" class="btn btn-padrao only-icon btn-sm ">
                       <i class="fas fa-undo"></i>
                    </button>
                    <button (click)="flipVertical()" type="button" class="btn btn-padrao only-icon btn-sm ">
                      <i class="fas fa-arrows-alt-v"></i>
                    </button>
                     <button (click)="flipHorizontal()" type="button" class="btn btn-padrao only-icon btn-sm ">
                      <i class="fas fa-arrows-alt-h"></i>                         
                    </button>
                    <button (click)="zoomIn()" type="button" class="btn btn-padrao only-icon btn-sm ">
                      <i class="fas fa-plus"></i>
                    </button>
                    <button (click)="zoomOut()" type="button" class="btn btn-padrao only-icon btn-sm ">
                      <i class="fas fa-minus"></i>
                    </button>
                  </div>
                  <div class="legenda">
                    <ul>
                      <li>
                        <i class="fas fa-camera"></i>
                        tirar foto (celulares)
                      </li>
                    </ul>
                    <ul>
                       <li>
                       <i  class="fas fa-undo"></i>
                        girar imagem anti horario
                      </li>
                      <li>
                        <i class="fas fa-undo"></i>
                        girar imagem horario
                      </li>
                    </ul>
                    <ul>
                       <li>
                        <i class="fas fa-arrows-alt-v"></i>
                        virar imagem vertical
                      </li>
                       <li>
                       <i  class="fas fa-arrows-alt-h"></i>
                        virar imagem horizontal
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <i class="fas fa-plus"></i>
                        aumentar imagem
                      </li>
                      <li>
                        <i  class="fas fa-minus"></i>
                        diminuir imagem
                      </li>
                    </ul>
                    
                  </div>
                  
          </div>
          <div class="preview">
              <h4>Prévia</h4>
              <div>
                  <img [src]="croppedImage"  />
              </div>
              <div class="botoes">
                  <button type="button" class="btn btn-padrao btn-sm " (click)="confirm()">
                              <i class="fas fa-check"></i> Salvar</button>
                  <button type="button" class="btn btn-danger btn-sm " (click)="activeModal.dismiss('Fecha click')">
                    <i class="fas fa-times"></i> Cancelar</button>
              </div>
          </div>
        </div>
    </div>
    
</div>
</div>
  `,
  styleUrls: ['./modal-cropper.component.css']
})
export class ModalCropperComponent implements OnInit {

  @Input() imageChangedEvent: any = '';
  @Input() aspectRatio: number = 1;
  @Output() cleanFile: any = '';
  @Output() croppedImage: any = '';
  @Input() showControlPanel: boolean = false
  @Output() clickevent = new EventEmitter<string>();

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  //croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  fileChangeEvent(event: any): void {
    console.log('event', event)
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event, base64ToFile(event.base64 || ""));
  }

  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  confirm() {
    this.activeModal.close()
    this.clickevent.emit(this.croppedImage)
  }

}
