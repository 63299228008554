<!-- ======================conteudo===================== -->
<main>
    <div class="conteudo">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
              <li class="breadcrumb-item redirect" routerLink="/home">{{menuService.RetornarDestaqueGerenciador('pessoas').Titulo}}</li>
              <li class="breadcrumb-item active" aria-current="page">{{menuService.RetornarDestaqueGerenciador('noticias').Titulo}}</li>
          </ol>
        </nav>

        <h3>{{menuService.RetornarDestaqueGerenciador('noticias').Titulo}}</h3>
        <!-- =============LISTA PRODUTOS=================== -->
        <div class="lista-noticias ">
            <div style="height: 293px; width: 100%; position: relative; z-index: 1; align-content: center;" *ngIf="carregandoListaNoticias">
              <div style="height: 100%; width: 100%; position: relative; align-content: center;"  *ngIf="noticiasEmpty && carregandoListaNoticias">
                <div style="text-align: center; vertical-align: middle; line-height: 100%; margin-top: 10%;">{{menuService.RetornarDestaqueGerenciador('sem_registros').Titulo}}</div>
              </div>
              <ngx-spinner name="sp3" bdColor="#fff" size="medium" color="#CCCCCC" type="timer" [fullScreen]="false"></ngx-spinner>
            </div>
            <ul class="list row">
              <!-- ==============ITEM LISTA=============== -->
              <li *ngFor="let noticia of noticias.rows; let i = index">
                  <a (click)="redirectToDetail(noticia)" style="cursor: pointer;">
                      <span class="image">
                          <span class="bg-amarelo">
                              {{noticia.DataPublicacao.substring(0, 5)}}
                          </span>
                          <img src="{{noticia.Imagem}}" alt="">
                      </span>
                      <span class="texto">
                          <h5>{{noticia.Titulo}}</h5>
                      </span>
                  </a>
              </li>
            </ul>
            <div class="d-flex justify-content-center">
              <nav>
                <ul class="pagination">
                  <li class="page-item {{ paginaAtual == 1 ? 'disabled' : ''}}" *ngIf="noticias.rows.length > 0">
                    <a class="page-link" *ngIf="paginaAtual == 0" style="cursor: pointer;"><i class="fas fa-chevron-left"></i></a>
                    <button class="page-link" (click)="getNewsList(paginaAtual - 1)" [disabled]="paginaAtual == 1">
                      <i class="fas fa-chevron-left"></i>
                      <div *ngIf="qtdPaginas[0] != 1">...</div>
                    </button>
                  </li>
                  <li class="page-item " *ngFor="let opt of qtdPaginas;">
                    <a class="page-link active" *ngIf="paginaAtual == opt" style="cursor: pointer;">{{ opt }}</a>
                    <button class="page-link" (click)="getNewsList(opt)" *ngIf="paginaAtual != opt">
                      {{ opt }}
                    </button>
                  </li>
                  <li class="page-item {{ paginaAtual == noticias.qtdTotalPaginas ? 'disabled' : ''}}"
                    *ngIf="noticias.rows.length > 0">
                    <a class="page-link" *ngIf="paginaAtual == (qtdPaginas-1)" style="cursor: pointer;"><i class="fas fa-chevron-right"></i></a>
                    <button class="page-link" (click)="getNewsList(paginaAtual + 1)"
                      [disabled]="paginaAtual == noticias.qtdTotalPaginas">
                      <div *ngIf="qtdPaginas[qtdPaginas.length - 1] != noticias.qtdTotalPaginas">...</div>
                      <i class="fas fa-chevron-right"></i>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
    <!-- =============FIM LISTA PRODUTOS=================== -->
    </div>
<!-- =====================FIM =conteudo===================== -->
</main>
