import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { DashboardService } from '../service/dashboard.service';
import { ChartType } from 'angular-google-charts';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DatePipe,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})
export class DashboardComponent implements OnInit {

  data = new Date();

  optionsAdesao = {
    colors: ['#1D8A64'],
    legend: { position: 'none' },
    chartArea: { top: 20, width: '80%', height: '60%' },
    hAxis: {
      slantedText: true,
      slantedTextAngle: -90
    },
    pointSize: 10,
    height: 300
  }

  currentDate = {
    date: new Date().getDate(),
    month: new Date().getMonth(),
    year: new Date().getFullYear()
  }

  filtro = {
    dataInicial: '',
    dataFinal: '',
    dataInicialTela: '',
    dataFinalTela: '',
  }

  indicadores = {
    totalCandidatos: 0,
    quantidadeProdutos: 0,
    quantidadeServicos: 0,
    totalComentarios: 0
  }

  adesaoDado = false;

  graficos = {
    adesao: {
      type: ChartType.LineChart,
      data: [['Nenhum dado', 0]],
      // columns: []
    },
    quantidadeProdutos: {
      type: ChartType.PieChart,
      data: [],
      columns: ['Categoria', 'Quantidade']
    },
    quantidadeServicos: {
      type: ChartType.PieChart,
      data: [],
      columns: ['Categoria', 'Quantidade']
    }
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private dashboardService: DashboardService) { }

  ngOnInit(): void {
    // Só entra na tela se for perfil Administrador
    if (!this.authService.isLoggedIn() || this.authService.getUser().nome_perfil.toUpperCase() != 'ADMINISTRADOR') {
      this.router.navigate(['home'])
      return
    }

    this.obterInformacoes()
  }

  obterInformacoes() {
    if (!this.filtro.dataInicialTela && !this.filtro.dataFinalTela) {
      this.filtro.dataFinalTela = `${this.data.getDate()}-${this.data.getMonth() + 1}-${this.data.getFullYear()}`
      this.data.setDate(this.data.getDate() - 31);
      this.filtro.dataInicialTela = `${this.data.getDate()}-${this.data.getMonth() + 1}-${this.data.getFullYear()}`
    }

    this.filtro.dataInicial = ''
    if (this.filtro.dataInicialTela) {
      this.filtro.dataInicial = (new Date(this.filtro.dataInicialTela.split('-').reverse().join('/'))).toJSON()
    }

    this.filtro.dataFinal = ''
    if (this.filtro.dataFinalTela) {
      this.filtro.dataFinal = (new Date(`${this.filtro.dataFinalTela.split('-').reverse().join('/')} 23:59`)).toJSON()
    }

    let { dataFinalTela, dataInicialTela, ...filtroEnxuto } = this.filtro

    this.dashboardService.dashboard(filtroEnxuto).subscribe(next => {
      this.indicadores = next.indicadores
      this.graficos.quantidadeProdutos.data = next.graficos.quantidadeProdutos
      this.graficos.quantidadeServicos.data = next.graficos.quantidadeServicos

      if (next.graficos.adesao.length > 0) {
        this.graficos.adesao.data = next.graficos.adesao;
        this.adesaoDado = true;
      } else {
        this.graficos.adesao.data = [['Nenhum dado', 0]];
        this.adesaoDado = false;
      }
    })
  }

  filtrar() {
    this.obterInformacoes()
  }

}
