<main>
  <div class="conteudo">

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item redirect" routerLink="/home">Página inicial </li>
        <li class="breadcrumb-item active" aria-current="page">Cadastro</li>
      </ol>
    </nav>

    <h3>Cadastro de usuário</h3>
    <div class="descricao" *ngIf="tipo == 'externo'">
      <p>Preencha com cuidado e atenção. As informações fornecidas irão apoiar a criação de políticas públicas
        para o desenvolvimento do turismo étnico-afro de Salvador.</p>
      <p><strong><a [routerLink]="" (click)="showVideoModal()"
            alt="Video de demonstração de cadastro na plataforma Afrobiz Salvador.">Clique aqui</a></strong> e veja como
        é facil se cadastrar na plataforma <strong>Afrobiz Salvador</strong>!</p>
    </div>
    <div class="header-title">
      <h2 *ngIf="tipo == 'interno'">
        <span>Pessoas/Entidades</span>
        {{pessoa.nome}}
      </h2>
    </div>
    <h4 class="mb-5">Dados pessoais
      <!-- <button type="submit" class="btn btn-padrao btn-sm" *ngIf="tipo == 'interno'">
                    <i class="fas fa-user-friends"></i>
                    editar cadastro
                </button> -->
    </h4>



    <!-- =========== DADOS PESSOAIS================= -->
    <div class="blocos-cadastro">

      <div class="form-check form-check-inline mb-4 w-100" *ngIf="edicaoTipoPessoa">
        <p>Cadastro de: *</p>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" value="PF" id="radioPF" name="tipoPessoa" class="custom-control-input"
            [(ngModel)]="tipoPessoa" (ngModelChange)="mudaTipoPessoa()">
          <label class="custom-control-label" for="radioPF">Pessoa física </label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" value="PJ" id="radioPJ" name="tipoPessoa" class="custom-control-input"
            [(ngModel)]="tipoPessoa" (ngModelChange)="mudaTipoPessoa()">
          <label class="custom-control-label" for="radioPJ">Empresa</label>
        </div>
      </div>

      <div class="flex">
        <div class="bloco-avatar display-none-sm">
          <div class="avatar-upload">
            <label for="">
              Inserir foto:
              <a aria-label="Insira uma imagem da empresa ou pessoa" data-balloon-pos="up" class=""
                style="cursor: pointer;">
                <i class="fas fa-question-circle"></i>
              </a>
            </label>
            <!-- <input type='file' class="upimage" accept=".png, .jpg, .jpeg"
                                (change)="handleFileInput($event.target)" /> -->
            <input type='file' class="upimage" accept=".png, .jpg, .jpeg" (change)="handleFileInput($event)" />
            <div class="avatar-edit">
            </div>
            <div class="avatar-preview">
              <div class="load" *ngIf="spinFoto">
                <i class="fas fa-circle-notch fa-spin"></i>
              </div>
              <img id="imagePreview" class="image" src='{{ templateFoto }}' />
            </div>
            <div class="botoes" *ngIf="templateFoto != 'assets/images/user.svg'">
              <button type="button" class="btn btn-padrao btn-sm" (click)="AlterarFoto()">
                <i class="fas fa-check"></i>
                alterar foto
              </button>
              <button type="button" class="btn btn-danger btn-sm" (click)="ExcluirFoto()">
                <i class="fas fa-times"></i>
                excluir foto
              </button>
            </div>
          </div>
          <div class="text-center">Tamanho máximo permitido de {{tamanhoMaximoImagem}}Mb</div>

          <div class="" [ngClass]="displayFieldCss(pessoa.autobiografia)">
            <label for="" class="form-label">
              Autobiografia: <span>*</span>
              <a aria-label="Digite um texto sobre a pessoa ou empresa" data-balloon-pos="up" class=""
                style="cursor: pointer;">
                <i class="fas fa-question-circle"></i>
              </a>
            </label>
            <textarea [(ngModel)]="pessoa.autobiografia" class="form-control" id="" maxlength="150" rows="3"
              placeholder="Sua Autobiografia"></textarea>
            <div class="count-textarea">
              <!-- <span id="chars">150 </span> caracteres restantes -->
              <span>{{ 150 - pessoa.autobiografia.length }}</span> caracteres restantes
            </div>
            <app-field-error-display [displayError]="isFieldValid(pessoa.autobiografia)"
              errorMsg="Por favor, informe a Autobiografia">
            </app-field-error-display>
          </div>
        </div>
        <div class="col">

          <!-- ================AREA DE RODADA DE NEGOCIOS=================== -->
          <div class="rodada" *ngIf="exibePainelRodada">
            <h4>{{rodadaNegocio.titulo}}</h4>
            <div class="col-lg-12 col-sm-12 col-md-12">
              <div class="input-group mb-12">
                <!-- <input type="checkbox"  class="form-check-input" > -->
                <input type="checkbox" class="form-check-input" id="rodada-check" [(ngModel)]="pessoa.interesseRodada">
                <label for="rodada-check" class="form-check-label">{{rodadaNegocio.descricao_checkbox}}</label>
                <label for=""><a aria-label='Marque esta opção se deseja participar da Rodada de Negócio'
                    data-balloon-pos="up" class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a></label>
              </div>
            </div>
            <p>
              {{rodadaNegocio.descricao}}
            </p>
          </div>
          <!-- ================FIM AREA DE RODADA DE NEGOCIOS=================== -->

          <div class="row">
            <div class="'col-md-6 col-sm-6">
              <div class="form-group" [ngClass]="displayFieldCss(pessoa.nome)">
                <label for="">
                  <span *ngIf="tipoPessoa == 'PF'">Nome Completo: *</span>
                  <span *ngIf="tipoPessoa == 'PJ'">Razão Social: *</span>
                  <a aria-label="Insira o seu nome completo" data-balloon-pos="up" class="" style="cursor: pointer;"
                    *ngIf="tipoPessoa == 'PF'">
                    <i class="fas fa-question-circle"></i>
                  </a>
                  <a aria-label="Insira a razão social da sua empresa" data-balloon-pos="up" class=""
                    style="cursor: pointer;" *ngIf="tipoPessoa == 'PJ'">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <input [(ngModel)]="pessoa.nome" type="text" class="form-control" placeholder="Nome Completo"
                  maxlength="150">
                <app-field-error-display [displayError]="isFieldValid(pessoa.nome)"
                  errorMsg="Por favor, informe o Nome Completo">
                </app-field-error-display>
              </div>
            </div>
            <div class="'col-md-6 col-sm-6 item-required">
              <div class="form-group" [ngClass]="tipoPessoa == 'PF' ? displayFieldCss(pessoa.nome_social) : ''">
                <label for="">
                  Nome Social: <span *ngIf="tipoPessoa == 'PF'">*</span>
                  <a aria-label="Escreva aqui o nome que você se identifica, como prefere ser chamado(a)"
                    data-balloon-pos="up" class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <input [(ngModel)]="pessoa.nome_social" type="text" class="form-control" placeholder="Nome Social"
                  maxlength="150" [disabled]="tipoPessoa == 'PJ'">
                <app-field-error-display [displayError]="isFieldValid(pessoa.nome_social) && tipoPessoa == 'PF'"
                  errorMsg="Por favor, informe o Nome Social">
                </app-field-error-display>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-12">
              <div class="form-group group-select">
                <label for="">
                  Gênero
                  <a aria-label="Escolha abaixo o seu gênero" data-balloon-pos="up" class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <select class="form-control " [(ngModel)]="pessoa.id_genero" [disabled]="tipoPessoa == 'PJ'">
                  <option value="">Selecione</option>
                  <option *ngFor="let opt of generoList" [value]="opt.id">
                    {{ opt.nome }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group group-select">
                <label for="">
                  Raça
                  <a aria-label="Escolha qual a etnia você pertence, respeitando o critério de autodeclaração"
                    data-balloon-pos="up" class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <select class="form-control " [(ngModel)]="pessoa.id_raca" [disabled]="tipoPessoa == 'PJ'">
                  <option value="">Selecione</option>
                  <option *ngFor="let opt of racaList" [value]="opt.id">
                    {{ opt.nome }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group" [ngClass]="displayFieldCss(pessoa.email)">
                <label for="">
                  E-mail: <span *ngIf="!pessoaPersisted">*</span>
                  <a aria-label="Escreva seu melhor e-mail. Escolha um e-mail que você use com maior frequência"
                    data-balloon-pos="up" class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <input [(ngModel)]="pessoa.email" type="text" class="form-control" placeholder="Endereço de E-mail"
                  maxlength="150" [readOnly]="pessoaPersisted">
                <app-field-error-display [displayError]="isFieldValid(pessoa.email)"
                  errorMsg="Por favor, informe o E-mail">
                </app-field-error-display>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5 col-sm-12 item-required">
              <div class="form-group item-required" [ngClass]="displayFieldCss(pessoa.cpf)">
                <label for="" class="item-required">
                  <span *ngIf="tipoPessoa == 'PF'">CPF: *</span>
                  <span *ngIf="tipoPessoa == 'PJ'">CNPJ: *</span>
                  <a aria-label="Digite seu número de CPF" data-balloon-pos="up" class="" style="cursor: pointer;"
                    *ngIf="tipoPessoa == 'PF'">
                    <i class="fas fa-question-circle"></i>
                  </a>
                  <a aria-label="Digite seu número de CNPJ" data-balloon-pos="up" class="" style="cursor: pointer;"
                    *ngIf="tipoPessoa == 'PJ'">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <input [(ngModel)]="pessoa.cpf" type="text" class="form-control" placeholder="CPF ou CNPJ"
                  [readOnly]="pessoaPersisted" mask="CPF_CNPJ" [maxlength]="tipoPessoa == 'PF' ? 14 : 18">
                <app-field-error-display [displayError]="isFieldValid(pessoa.cpf)"
                  errorMsg="Por favor, informe o CPF ou CNPJ">
                </app-field-error-display>
              </div>
            </div>
            <div class="col-md-7 col-sm-6 item-required" [ngClass]="displayFieldCss(pessoa.nome_fantasia)">
              <div class="form-group">
                <label for="">
                  <span *ngIf="tipoPessoa == 'PF'">Nome Artístico: *</span>
                  <span *ngIf="tipoPessoa == 'PJ'">Nome Fantasia: *</span>
                  <a aria-label="Escreva aqui o seu nome Artístico. Este é o nome que aparecerá na Plataforma"
                    data-balloon-pos="up" class="" style="cursor: pointer;" *ngIf="tipoPessoa == 'PF'">
                    <i class="fas fa-question-circle"></i>
                  </a>
                  <a aria-label="Escreva aqui o nome que o identifica melhor na sua área de atuação. Este é o nome que aparecerá na Plataforma"
                    data-balloon-pos="up" class="" style="cursor: pointer;" *ngIf="tipoPessoa == 'PJ'">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <input [(ngModel)]="pessoa.nome_fantasia" type="text" class="form-control"
                  placeholder="Este é o nome que aparecerá na Plataforma" maxlength="150">
                <app-field-error-display [displayError]="isFieldValid(pessoa.nome_fantasia)"
                  errorMsg="Por favor, informe a Nome Fantasia">
                </app-field-error-display>
              </div>
            </div>
          </div>

          <!-- ==================UPLOAD DE FOTO NO MODO MOBILE=================== -->
          <div class="bloco-avatar mobile">
            <div class="avatar-upload">
              <label for="">
                Inserir foto:
                <a aria-label="Escolha uma foto ou imagem que represente você ou sua empresa" data-balloon-pos="up"
                  class="" style="cursor: pointer;">
                  <i class="fas fa-question-circle"></i>
                </a>
              </label>
              <!-- <input type='file' class="upimage" accept=".png, .jpg, .jpeg"
                                    (change)="handleFileInput($event.target)" /> -->
              <input type='file' class="upimage" accept=".png, .jpg, .jpeg" (change)="handleFileInput($event)" />
              <div class="avatar-edit">
              </div>
              <div class="avatar-preview">
                <div class="load" *ngIf="spinFoto">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </div>
                <img id="imagePreview" class="image" src='{{ templateFoto }}' />
              </div>
              <div class="botoes" *ngIf="templateFoto != 'assets/images/user.svg'">
                <button type="button" class="btn btn-padrao btn-sm" (click)="AlterarFoto()">
                  <i class="fas fa-check"></i>
                  alterar foto
                </button>
                <button type="button" class="btn btn-danger btn-sm" (click)="ExcluirFoto()">
                  <i class="fas fa-times"></i>
                  excluir foto
                </button>
              </div>
            </div>
            <div class="text-center">
              <small>Tamanho máximo permitido de {{tamanhoMaximoImagem}}Mb</small>
            </div>

            <div class="" [ngClass]="displayFieldCss(pessoa.autobiografia)">
              <label for="" class="form-label">
                Auto Biografia: <span>*</span>
                <a aria-label="Digite um texto sobre a pessoa ou empresa" data-balloon-pos="up" class=""
                  style="cursor: pointer;">
                  <i class="fas fa-question-circle"></i>
                </a>
              </label>
              <textarea [(ngModel)]="pessoa.autobiografia" class="form-control" id="" maxlength="150" rows="3"
                placeholder="Sua Autobiografia"></textarea>
              <div class="count-textarea">
                <!-- <span id="chars">150 </span> caracteres restantes -->
                <span>{{ 150 - pessoa.autobiografia.length }}</span> caracteres restantes
              </div>
              <app-field-error-display [displayError]="isFieldValid(pessoa.autobiografia)"
                errorMsg="Por favor, informe a Autobiografia">
              </app-field-error-display>
            </div>
          </div>
          <!-- =================FIM=UPLOAD DE FOTO NO MODO MOBILE=================== -->



          <div class="row">
            <!-- <div class="col-md-4 col-sm-12">
                                <div class="form-group ico-date" [ngClass]="displayFieldCss(pessoa.nascimento)">
                                    <label for="">
                                        Data de Nascimento: <span>*</span>
                                        <a aria-label="Digite a Data de Nascimento" data-balloon-pos="up" class="">
                                            <i class="fas fa-question-circle"></i>
                                        </a>
                                    </label>
                                    <input [(ngModel)]="pessoa.nascimento" class="form-control" ngbDatepicker
                                        #d="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}"
                                        [maxDate]="{year: currentDate.year - 1, month: 12, day: 31}" (click)="d.toggle()">
                                    <app-field-error-display [displayError]="isFieldValid(pessoa.nascimento)"
                                        errorMsg="Por favor, informe a Data de Nascimento">
                                    </app-field-error-display>
                                </div>
                            </div> -->
            <div class="col-md-4 col-sm-12">
              <div class="form-group group-select">
                <label for="">
                  Faixa etária:
                  <a aria-label="Escolha a faixa etária a qual você pertença" data-balloon-pos="up" class=""
                    style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <select class="form-control " [(ngModel)]="pessoa.id_faixa_etaria" [disabled]="tipoPessoa == 'PJ'">
                  <option value="">Selecione</option>
                  <option *ngFor="let opt of faixaEtariaList" [value]="opt.id">
                    {{ opt.nome }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">
                  Telefone:
                  <a aria-label="Digite seu número de telefone fixo para contato" data-balloon-pos="up" class=""
                    style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <input [(ngModel)]="pessoa.telefone" type="text" class="form-control"
                  placeholder="Telefone para contato" mask="(00) 0000-0000">
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group" [ngClass]="displayFieldCss(pessoa.celular)">
                <label for="">
                  Celular/WhatsApp: <span>*</span>
                  <a aria-label="Digite seu número de celular preferencialmente com WhatsApp para contatos futuros"
                    data-balloon-pos="up" class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <input [(ngModel)]="pessoa.celular" type="text" class="form-control" placeholder="Telefone Celular"
                  mask="(00) 0 0000-0000">
                <app-field-error-display [displayError]="isFieldValid(pessoa.celular)"
                  errorMsg="Por favor, informe um Número de Celular">
                </app-field-error-display>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="form-group group-select">
                <label for="">
                  Estado Civil:
                  <a aria-label="Escolha seu estado civil" data-balloon-pos="up" class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <select class="form-control " [(ngModel)]="pessoa.id_estado_civil" [disabled]="tipoPessoa == 'PJ'">
                  <option value="">Selecione</option>
                  <option *ngFor="let opt of estadoCivilList" [value]="opt.id">
                    {{ opt.descricao }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group group-select">
                <label for="">
                  Filho(s):
                  <a aria-label="Escolha abaixo uma das opções para quantidade de filhos você tem" data-balloon-pos="up"
                    class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <select class="form-control" [(ngModel)]="pessoa.id_qtd_filhos" [disabled]="tipoPessoa == 'PJ'">
                  <option value="">Selecione</option>
                  <option *ngFor="let opt of quantidadeFilhosList" [value]="opt.id">
                    {{ opt.descricao }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-5 col-sm-12">
              <div class="form-group group-select">
                <label for="">
                  Renda familiar:
                  <a aria-label="Escolha aqui sua faixa familiar de renda" data-balloon-pos="up" class=""
                    style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <select class="form-control" [(ngModel)]="pessoa.id_renda" [disabled]="tipoPessoa == 'PJ'">
                  <option value="">Selecione</option>
                  <option *ngFor="let opt of rendaFamiliaList" [value]="opt.id">
                    {{ opt.descricao }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div class="form-group group-select">
                <label for="">
                  Número de funcionários:
                  <a aria-label="Escolha, se houver, a quantidade de pessoas que trabalham no seu negócio"
                    data-balloon-pos="up" class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <select class="form-control " [(ngModel)]="pessoa.id_numero_funcionario"
                  [disabled]="tipoPessoa == 'PF'">
                  <option value="">Selecione</option>
                  <option *ngFor="let opt of numeroFuncionarioList" [value]="opt.id">
                    {{ opt.nome }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="col-lg-12 col-sm-12 col-md-12">
              <div class="input-group mb-12">
                <input type="checkbox" class="form-check-input" [(ngModel)]="pessoa.afroestima">
                <label for="" class="form-check-label">
                  Desejo participar do programa <a href="https://www.afroestimasalvador.com.br/login/index.php"
                    target="_blank"><strong>AFROESTIMA
                      SALVADOR</strong></a>
                  <a aria-label="Marque esta opção se você deseja partira do Programa AFROESTIMA SALVADOR"
                    data-balloon-pos="up" class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <div class="text">
                  <p>
                    O <strong>AFROESTIMA SALVADOR</strong> é um programa de capacitação e mentoria que oferece uma série
                    de cursos e
                    ferramentas de aplicação prática voltada para empoderar afroempreendedores que querem fortalecer
                    seus negócios e vender mais. A inscrição é gratuita. Participe!
                    <a href="https://www.afroestimasalvador.com.br/login/index.php"
                      target="_blank"><strong>www.afroestima.com.br</strong></a>.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3">
              <div class="form-group" [ngClass]="displayFieldCss(pessoa.cep)">
                <label for="">
                  CEP: <span>*</span>
                  <a aria-label="Digite aqui o seu CEP" data-balloon-pos="up" class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <input type="text" class="form-control" placeholder="" mask="00.000-000" [ngModel]="pessoa.cep"
                  (ngModelChange)=getEndereco($event) placeholder="CEP">
                <app-field-error-display [displayError]="isFieldValid(pessoa.cep)" errorMsg="Por favor, informe o CEP">
                </app-field-error-display>
              </div>
            </div>
            <div class="col-sm-7">
              <div class="form-group" [ngClass]="displayFieldCss(pessoa.endereco)">
                <label for="">
                  Endereço: <span>*</span>
                  <a aria-label="Digite seu endereço (Rua, Avenida…)" data-balloon-pos="up" class=""
                    style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <input [(ngModel)]="pessoa.endereco" type="text" class="form-control" placeholder="Endereço"
                  maxlength="150" (change)="obterCoordenadas()">
                <app-field-error-display [displayError]="isFieldValid(pessoa.endereco)"
                  errorMsg="Por favor, informe o Endereço">
                </app-field-error-display>
              </div>
            </div>
            <div class="col-md-2 col-sm-12">
              <div class="form-group">
                <label for="">
                  Número:
                  <a aria-label="Digite o numero de sua casa ou condominio, bloco, etc." data-balloon-pos="up" class="">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <input [(ngModel)]="pessoa.numero" type="text" class="form-control" placeholder="Número"
                  (change)="obterCoordenadas()">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <label for="">
                  Complemento:
                  <a aria-label="Complemente seu endereço, com apartamento, bloco ou ponto de referencia por exemplo."
                    data-balloon-pos="up" class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <input [(ngModel)]="pessoa.complemento" type="text" class="form-control" placeholder="Complemento"
                  maxlength="150">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group {{painelCEP}}" [ngClass]="displayFieldCss(pessoa.bairro)">
                <label for="">
                  Bairro: <span>*</span>
                  <a aria-label="Escreva aqui o seu bairro" data-balloon-pos="up" class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <input [(ngModel)]="pessoa.bairro" type="text" class="form-control" placeholder="Bairro" maxlength="150"
                  (change)="obterCoordenadas()">
                <app-field-error-display [displayError]="isFieldValid(pessoa.bairro)"
                  errorMsg="Por favor, informe o Bairro">
                </app-field-error-display>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group {{painelCEP}}" [ngClass]="displayFieldCss(pessoa.id_uf)">
                <label for="">
                  UF: <span>*</span>
                  <a aria-label="Escolha a UF do seu estado" data-balloon-pos="up" class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <select class="form-control" [(ngModel)]="pessoa.id_uf" (ngModelChange)="getCidadeList()">
                  <option value="">Selecione</option>
                  <option *ngFor="let opt of ufList" [value]="opt.nome">
                    {{ opt.nome }}
                  </option>
                </select>
                <app-field-error-display [displayError]="isFieldValid(pessoa.id_uf)" errorMsg="Por favor, informe o UF">
                </app-field-error-display>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group {{painelCEP}}" [ngClass]="displayFieldCss(pessoa.id_cidade)">
                <label for="">
                  Cidade: <span>*</span>
                  <a aria-label="Escolha a sua cidade" data-balloon-pos="up" class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <select class="form-control" [(ngModel)]="pessoa.id_cidade" (change)="obterCoordenadas()">
                  <option value="">Selecione</option>
                  <option *ngFor="let opt of cidadeList" [value]="opt.id">
                    {{ opt.nome }}
                  </option>
                </select>
                <app-field-error-display [displayError]="isFieldValid(pessoa.id_cidade)"
                  errorMsg="Por favor, informe a Cidade">
                </app-field-error-display>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="">
                  <i class="fab fa-facebook-f"></i>Facebook:
                  <a aria-label="Copie e cole aqui o endereço de seu Facebook. Ex: https://www.facebook.com/prefeituradesalvador"
                    data-balloon-pos="up" class="tootip" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <input [(ngModel)]="pessoa.facebook" type="text" class="form-control"
                  placeholder="cole aqui: https://facebook.com/seuperfil">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="">
                  <i class="fab fa-instagram"></i>
                  Instagram:
                  <a aria-label="Copie e cole aqui o endereço de seu Instagram. Ex: https://www.instagram.com/prodetursalvador"
                    data-balloon-pos="up" class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <input [(ngModel)]="pessoa.instagram" type="text" class="form-control"
                  placeholder="cole aqui: https://instagram.com/seuperfil">
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label for="">
                  <i class="fas fa-globe"></i>
                  Link do site:
                  <a aria-label="Se você tem um página de seu negócio na internet, insira nesta área"
                    data-balloon-pos="up" class="" style="cursor: pointer;">
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label>
                <input [(ngModel)]="pessoa.website" type="text" class="form-control" placeholder="http://"
                  autocomplete="off">
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- ====================CADASTRO COMPLEMENTAR COLAPSE================= -->
      <!-- <div class="w-100">
                    <div>
                        <a class="btn btn-collapse" [routerLink]="" role="button"
                            aria-expanded="false" aria-controls="collapseExample" (click)="mostraRodada = !mostraRodada">
                            Cadastro Complementar para Rodada de Negócios
                            <i class="fas fa-plus"></i>
                        </a>
                        <p>Some placeholder content for the collapse component. This panel is hidden by default but revealed
                            when the user activates the relevant trigger.</p>
                    </div>
                    <div *ngIf="mostraRodada">
                        <div class="form-group">
                            <label for="">
                                Certificações: <span>*</span>
                                <a aria-label="Loren ipso" data-balloon-pos="up" class="">
                                    <i class="fas fa-question-circle"></i>
                                </a>
                            </label>
                            <input type="text" class="form-control" placeholder="">
                        </div>
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn btn-transparent">
                                <i class="fas fa-plus"></i>
                                adicionar certificação
                            </button>
                        </div>
                        <div class="form-group">
                            <label for="">
                                Capacidade de atendimento: <span>*</span>
                                <a aria-label="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae nisl justo. Suspendisse eu turpis purus. Mauris vehicula sagittis qua"
                                    data-balloon-pos="up" class="">
                                    <i class="fas fa-question-circle"></i>
                                </a>
                            </label>
                            <input [(ngModel)]="pessoa.rodada_capacidade_atendimento" type="text" class="form-control" placeholder="">
                        </div>
                        <div class="">
                            <label for="" class="form-label">
                                Caracteristicas técnicas:
                                <a aria-label="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae nisl justo. Suspendisse eu turpis purus. Mauris vehicula sagittis qua"
                                    data-balloon-pos="up" class="">
                                    <i class="fas fa-question-circle"></i>
                                </a>
                            </label>
                            <textarea [(ngModel)]="pessoa.rodada_caracteristica_tecnica" class="form-control" id="" maxlength="100" rows="3"></textarea>
                            <div class="count-textarea">
                                <span id="chars">100 </span> caracteres restantes
                            </div>

                        </div>
                    </div>
                </div> -->
      <!-- ====================FIM CADASTRO COMPLEMENTAR COLAPSE================= -->
      <div class="row"
        *ngIf="(!authService.getUser() || authService.getUser().nome_perfil === 'Pessoa') || (authService.getUser() && authService.getUser().nome_perfil !== 'Pessoa' && !pessoa.id)">
        <div class="col-lg-4 col-sm-12 col-md-6">
          <div class="input-group mb-3" [ngClass]="displayFieldCss(pessoa.senha)">
            <label for="" class="w-100">
              Senha: <span>*</span>
              <a aria-label="Digite abaixo sua senha" data-balloon-pos="up" class="" style="cursor: pointer;">
                <i class="fas fa-question-circle"></i>
              </a>
            </label>
            <i class="toggle-password fa fa-fw {{ inputPassword == 'password' ? 'fa-eye' : 'fa-eye-slash' }}"
              (click)="togglePassword()"></i>
            <input [(ngModel)]="pessoa.senha" [type]="inputPassword" class="form-control"
              placeholder="Digite a sua senha" maxlength="150" autocomplete="off">
            <app-field-error-display [displayError]="isFieldValid(pessoa.senha)" errorMsg="Por favor, informe a Senha">
            </app-field-error-display>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12 col-md-6">
          <div class="input-group mb-3" [ngClass]="comparaSenhadisplayFieldCss()">
            <label for="" class="w-100">
              Confirmar senha: <span>*</span>
              <a aria-label="Confirme a senha escolhida" data-balloon-pos="up" class="" style="cursor: pointer;">
                <i class="fas fa-question-circle"></i>
              </a>
            </label>
            <i class="toggle-password fa fa-fw {{ inputPasswordCheck == 'password' ? 'fa-eye' : 'fa-eye-slash' }}"
              (click)="togglePasswordCheck()"></i>
            <input [(ngModel)]="pessoa.senhaConfirma" [type]="inputPasswordCheck" class="form-control"
              placeholder="Digite novamente a senha" maxlength="150" autocomplete="off">
            <app-field-error-display [displayError]="senhaDiferente()" errorMsg="Senha diferente">
            </app-field-error-display>
          </div>
        </div>
      </div>

      <div class="mb-3"
        *ngIf="(!authService.getUser() || authService.getUser().nome_perfil === 'Pessoa') || (authService.getUser() && authService.getUser().nome_perfil !== 'Pessoa' && !pessoa.id)">
        <div class="col-lg-12 col-sm-12 col-md-12">
          <div class="input-group mb-12">
            <input type="checkbox" [(ngModel)]="pessoa.deAcordo" class="form-check-input">
            <label for="" class="form-check-label">Li e estou de acordo com os <a [routerLink]=""
                (click)="mostrarTermos()" style="cursor: pointer;">Termos de
                uso</a> do Portal </label>
          </div>
        </div>
      </div>


      <div class="flex justify-content-end" *ngIf="tipo == 'interno' || pessoa.id">

        <button type="button" class="btn btn-danger btn-sm " (click)="Cancelar()">
          <i class="fas fa-times"></i>
          CANCELAR
        </button>
        <button type="button" class="btn btn-success btn-sm" (click)="Salvar()" [disabled]="SalvarClicado">
          <i class="fas fa-check"></i>
          SALVAR
        </button>

      </div>





      <div class="form-footer justify-content-end" *ngIf="cadastrarProdutosServicos && !pessoa.id">
        <!-- <button type="button" class="btn btn-transparent" (click)="Salvar()">
                        <i class="fas fa-check"></i>
                        SALVAR DADOS GERAIS
                    </button> -->

        <button type="button" id="Tag_Inscricao" class="btn btn-transparent" (click)="Salvar(true)"
          [disabled]="SalvarClicado">
          <i class="fas fa-arrow-right"></i>
          CADASTRAR PRODUTOS OU SERVIÇOS
        </button>
      </div>
    </div>
  </div>

  <!-- =====================FIM =conteudo===================== -->
</main>
