import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GerenciadorConteudoService } from 'src/app/service/gerenciador-conteudo.service';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from 'src/app/service/auth.service';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-noticia-lista',
  templateUrl: './noticia-lista.component.html',
  styleUrls: ['./noticia-lista.component.css']
})
export class NoticiaListaComponent implements OnInit {

  noticias: any;
  paginaAtual: number;
  qtdPaginas: any;
  carregandoListaNoticias: boolean;
  noticiasEmpty: boolean;

  constructor(
    private gerenciadorConteudo: GerenciadorConteudoService,
    private router: Router,
    private spinnerNoticiaLista: NgxSpinnerService,
    private authService: AuthService,
    public menuService: MenuService
  ) {
    this.paginaAtual = 1;
    this.noticias = { rows: [], qtdTotalPaginas: 0 };
    this.carregandoListaNoticias = true;
    this.noticiasEmpty = false;
  }

  ngOnInit(): void {
    this.gerenciadorConteudo.noticia = '';
    this.getNewsList(this.paginaAtual);
  }

  getNewsList(pagina: number) {
    window.scroll({ top: 0, left: 0 });
    this.noticias = { rows: [], qtdTotalPaginas: 0 };
    this.carregandoListaNoticias = true;
    this.spinnerNoticiaLista.show('sp3');
    this.paginaAtual = pagina;
    this.gerenciadorConteudo.getNewsList(this.paginaAtual, this.authService.getLanguage()).subscribe((result: any) => {
      if (result.error) {
        console.log('noticias-lista ', result.error);
        this.noticiasEmpty = true;
        this.spinnerNoticiaLista.hide('sp3');
        return;
      }
      if (result.list.length == 0) {
        this.noticiasEmpty = true;
        this.spinnerNoticiaLista.hide('sp3');
        return;
      }
      this.noticias = { rows: result.list, qtdTotalPaginas: result.pages.length };
      this.qtdPaginas = result.pages;
      this.carregandoListaNoticias = false;
      this.spinnerNoticiaLista.hide('sp3');
    });
  }

  redirectToDetail(noticia: any) {
    this.gerenciadorConteudo.noticia = noticia;
    this.router.navigate(["detalhe-noticia"], { queryParams: { noticiaId: noticia.Id } });
  }

}
