import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoticiaRoutingModule } from './noticia-routing.module';

@NgModule({
  declarations: [ ],
  imports: [
    CommonModule,
    NoticiaRoutingModule
  ]
})
export class NoticiaModule { }
