import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class CidadeService extends MainService {

  constructor(private http: HttpClient) { super() }

  getList(_uf: string) {
    const url = `${super.getUrl()}cidade/list`;
    return this.http.post<any[]>(url, { uf: _uf }, this.getOptions());
  }

  getUfList() {
    const url = `${super.getUrl()}cidade/listUf`;
    return this.http.post<any[]>(url, {}, this.getOptions());
  }

  getCidade(codigo: string) {
    const url = `${super.getUrl()}cidade/get`;
    return this.http.post<any>(url, { codigo: codigo }, this.getOptions());
  }
}
