import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { ServicoModule } from "./servico/servico.module";
import { LayoutModule } from "./layout/layout.module";
import { PessoaModule } from './pessoa/pessoa.module';
import { ContaModule } from './conta/conta.module';
import { EventoModule } from './evento/evento.module';
import { NoticiaModule } from './noticia/noticia.module';
import { FieldErrorDisplayModule } from "./field-error-display/field-error-display.module";
import { FavoritosModule } from "./favoritos/favoritos.module";
import { GoogleChartsModule } from 'angular-google-charts';

import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from "@angular/forms";
import { HomeComponent } from './home/home.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DestaqueComponent } from "./home/destaque/destaque.component";
import { SlideTopoComponent } from "./home/slide-topo/slide-topo.component";
import { SwiperModule } from 'swiper/angular';
import { MapaComponent } from './home/mapa/mapa.component';
import { EventoComponent } from './home/evento/evento.component';
import { EnqueteComponent } from './home/enquete/enquete.component';
import { NoticiaComponent } from './home/noticia/noticia.component';
import { ContaCadastroComponent } from './conta/conta-cadastro/conta-cadastro.component';
import { ContaListaComponent } from './conta/conta-lista/conta-lista.component';
import { NgxMaskModule } from "ngx-mask";
import { PessoaListaComponent } from './pessoa-lista/pessoa-lista.component';
import { PermissionModule } from './directives/permission.module';
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { PessoaExternoModule } from './pessoa-externo/pessoa-externo.module';
import { NoticiaListaComponent } from './noticia/noticia-lista/noticia-lista.component';
import { NoticiaDetalheComponent } from './noticia/noticia-detalhe/noticia-detalhe.component';
import { EventoListaComponent } from './evento/evento-lista/evento-lista.component';
import { ModalEventoDetalheComponent } from './evento/modal-evento-detalhe/modal-evento-detalhe.component';
import { LoginComponent } from './login/login.component';
import { PrecadastroComponent } from './precadastro/precadastro.component';
import { AvaliacaoEstrelaModule } from './avaliacao-estrela/avaliacao-estrela.module'
import { AvisoDeslogadoComponent } from './aviso-deslogado/aviso-deslogado.component';
import { RecuperaSenhaComponent } from './recupera-senha/recupera-senha.component';
import { SuporteComponent } from './suporte/suporte.component';
import { ImageCropperModule } from './image-cropper/image-cropper.module';
import { ModalCropperComponent } from './image-cropper/modal/modal-cropper.component';
import { DestaqueServicosComponent } from './home/destaque-servicos/destaque-servicos.component';
import { ServicoExternoModule } from './servico-externo/servico-externo.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { ModalTermosComponent } from './modal-termos/modal-termos.component';
import { ModalOqueComponent } from './modal-oque/modal-oque.component';
import { RelatorioModule } from './relatorio/relatorio.module';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BannerRodadaComponent } from './home/banner-rodada/banner-rodada.component';
import { RodadaNegocioComponent } from './rodada-negocio/rodada-negocio.component';
import { RodadaNegocioCadastroComponent } from './rodada-negocio/rodada-negocio-cadastro/rodada-negocio-cadastro.component';
import { RodadaNegocioListaComponent } from './rodada-negocio/rodada-negocio-lista/rodada-negocio-lista.component';
import { ModalNewsletterComponent } from './modal-newsletter/modal-newsletter.component';
import { ModalVideoComponent } from './modal-video/modal-video.component';

registerLocaleData(localePt);

const routes: Routes = [
  {
    path: 'home',
    component: MainLayoutComponent, children: [{ path: '', component: HomeComponent }]
  },
  {
    path: 'detalhe-noticia', component: MainLayoutComponent,
    children: [{
      path: '', component: NoticiaDetalheComponent
    }]
  },
  {
    path: 'pessoa',
    redirectTo: '/cadastrar-dados-pessoais',
    pathMatch: 'full'
  },
  {
    path: 'conta',
    redirectTo: '/lista-gerenciar-contas'
  },
  {
    path: 'conta-cadastro',
    redirectTo: '/conta-cadastro'
  },
  {
    path: 'detalhe-noticia',
    redirectTo: '/detalhe-noticia'
  },
  {
    path: 'suporte',
    component: MainLayoutComponent, children: [{ path: '', component: SuporteComponent }]
  },
  {
    path: 'dashboard',
    component: MainLayoutComponent, children: [{ path: '', component: DashboardComponent }]
  },
  {
    path: 'rodada-de-negocio',
    component: MainLayoutComponent, children: [{ path: '', component: RodadaNegocioListaComponent }]
  },
  {
    path: 'rodada-de-negocio/cadastro',
    component: MainLayoutComponent, children: [{ path: '', component: RodadaNegocioCadastroComponent }]
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [
    AppComponent,
    DestaqueComponent,
    EventoListaComponent,
    SlideTopoComponent,
    HomeComponent,
    MapaComponent,
    EventoComponent,
    EnqueteComponent,
    NoticiaComponent,
    NoticiaDetalheComponent,
    ContaCadastroComponent,
    ContaListaComponent,
    PessoaListaComponent,
    LoginComponent,
    PrecadastroComponent,
    AvisoDeslogadoComponent,
    RecuperaSenhaComponent,
    SuporteComponent,
    ModalCropperComponent,
    ModalEventoDetalheComponent,
    DestaqueServicosComponent,
    NoticiaListaComponent,
    ModalTermosComponent,
    ModalOqueComponent,
    DashboardComponent,
    BannerRodadaComponent,
    RodadaNegocioComponent,
    RodadaNegocioCadastroComponent,
    RodadaNegocioListaComponent,
    ModalNewsletterComponent,
    ModalVideoComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    ServicoModule,
    LayoutModule,
    PessoaModule,
    ContaModule,
    EventoModule,
    NoticiaModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    NgxMaskModule.forRoot(),
    FormsModule, NgbModule, // por causa do login
    SwiperModule,
    FieldErrorDisplayModule,
    PermissionModule,
    PessoaExternoModule,
    AvaliacaoEstrelaModule,
    ImageCropperModule,
    NgxSpinnerModule,
    FavoritosModule,
    ServicoExternoModule,
    RelatorioModule,
    SocialLoginModule,
    GoogleChartsModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: "pt-BR"
    }, {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '309470664370-6ribkar901mbe3cpcqm5lhd2mo7fv14s.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('clientId')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent],
  
})
export class AppModule { }
