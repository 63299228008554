import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-termos',
  templateUrl: './modal-termos.component.html',
  styleUrls: ['./modal-termos.component.css']
})
export class ModalTermosComponent {
  @Input() modalRef: any;
  @Input() language: any;
  @Input() tela: any;
  @Input() pessoa: any;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  closeModal(aceite: any): void {
    if (this.tela === 'pessoa') {
      this.modalRef.close();
      if (aceite === 'aceita') {
        this.pessoa.deAcordo = true;
      }
    } else {
      this.modalRef.close();
    }
  }

}
