import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutRoutingModule } from './layout-routing.module';
import { FooterComponent } from './footer/footer.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { HeaderrComponent } from './headerr/headerr.component';
import { RouterModule } from "@angular/router";
import { BarraBuscaComponent } from './barra-busca/barra-busca.component';
import { LayoutLogadoComponent } from './layout-logado/layout-logado.component';
import { FormsModule } from "@angular/forms";
import { NgxMaskModule } from 'ngx-mask';
import { PermissionModule } from '../directives/permission.module';
import { SloganComponent } from './slogan/slogan.component';
import { LayoutInternoComponent } from './layout-interno/layout-interno.component';


@NgModule({
  declarations: [FooterComponent, MainLayoutComponent, HeaderrComponent, BarraBuscaComponent, 
    LayoutLogadoComponent, SloganComponent, LayoutInternoComponent],
  imports: [
    CommonModule,
    PermissionModule,
    LayoutRoutingModule,
    RouterModule.forChild([]),
    FormsModule,
    NgxMaskModule.forRoot()
  ]
})
export class LayoutModule { }
