import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicoComponent } from './servico.component';
import { ServicoRoutingModule } from './servico-routing.module';
import { FormsModule } from "@angular/forms";
import { ListaServicoModule } from "../lista-servico/lista-servico.module";
import { NgxMaskModule } from "ngx-mask";
import { FieldErrorDisplayModule } from "../field-error-display/field-error-display.module";
import { PermissionModule } from '../directives/permission.module';
import { CurrencyMaskModule } from "ng2-currency-mask";

@NgModule({
  declarations: [ServicoComponent],
  imports: [
    CommonModule,
    ServicoRoutingModule,
    FormsModule,
    ListaServicoModule,
    NgxMaskModule.forRoot(),
    FieldErrorDisplayModule,
    PermissionModule,
    CurrencyMaskModule
  ]
})
export class ServicoModule { }
