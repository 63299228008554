import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { GerenciadorConteudoService } from 'src/app/service/gerenciador-conteudo.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MenuService } from 'src/app/service/menu.service';
import { AuthService } from 'src/app/service/auth.service';
import { pipe } from 'rxjs';

@Component({
  selector: 'app-noticia-detalhe',
  templateUrl: './noticia-detalhe.component.html',
  styleUrls: ['./noticia-detalhe.component.css']
})
export class NoticiaDetalheComponent implements OnInit {

  noticia: any;
  textHtml: any;
  noticiaId: any;
  paginaAtual: any;
  Fonte: any;

  tamanhosFonts = ['f-10', 'f-12', 'f-14', 'f-16', 'f-18', 'f-20', 'f-24'];
  indicetamanhoAtual = 3;
  acessibilidade = this.tamanhosFonts[this.indicetamanhoAtual];

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private gerenciadorConteudo: GerenciadorConteudoService,
    private router: Router,
    private sanitize: DomSanitizer,
    public menuService: MenuService
  ) { }

  ngOnInit() {
    window.scroll({ top: 0, left: 0 });
    this.getNews();
  }

  getNews() {
    this.noticiaId = this.route.snapshot.queryParamMap.get('noticiaId');
    this.gerenciadorConteudo.getNews(this.noticiaId, this.authService.getLanguage()).subscribe((result: any) => {
      this.noticia = result;
      console.log(result);
      this.textHtml = this.sanitize.bypassSecurityTrustHtml(result.Conteudo);
    });
  }

  sizeUpFont() {
    this.indicetamanhoAtual = this.indicetamanhoAtual < 6 ? (++this.indicetamanhoAtual) : this.indicetamanhoAtual
    this.acessibilidade = this.tamanhosFonts[this.indicetamanhoAtual]
  }

  sizeDownFont() {
    this.indicetamanhoAtual = this.indicetamanhoAtual > 0 ? (--this.indicetamanhoAtual) : this.indicetamanhoAtual
    this.acessibilidade = this.tamanhosFonts[this.indicetamanhoAtual]
  }

}
