import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class PerfilService extends MainService {

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  getPerfil(filter: any) {
    const url = `${super.getUrl()}perfil/get/`;
    return this.http.post<any>(url, filter, this.getOptions());
  }

}
