<h2>{{menuService.RetornarDestaqueGerenciador('noticias').Titulo}}</h2>
<div class="grid">
  <div style="height: 290px; width: 601px; position: relative; z-index: 1;" *ngIf="carregandoNoticias">
    <div style="height: 100%; width: 100%; position: relative; align-content: center;"
      *ngIf="noticiasEmpty && carregandoNoticias">
      <div style="text-align: center; vertical-align: middle; line-height: 100%; margin-top: 15%;">
        {{menuService.RetornarDestaqueGerenciador('sem_registros').Titulo}}</div>
    </div>
    <ngx-spinner name="sp2" bdColor="#fff" size="medium" color="#CCCCCC" type="timer" [fullScreen]="false">
    </ngx-spinner>
  </div>
  <a *ngFor="let noticia of noticias" class="item" (click)="redirectToNewsDetail(noticia)" style="cursor: pointer;">
    <div class="image">
      <img src="{{noticia.Imagem}}" alt="">
    </div>
    <h5>{{noticia.Titulo | slice:0:135}}{{noticia.Titulo && noticia.Titulo.length > 135 ? '...':''}}</h5>
  </a>
</div>
<div class="ver-mais">
  <button type="button" class="btn btn-transparent" (click)="direcionarListagem()">
    {{menuService.RetornarDestaqueGerenciador('ver_noticias').Titulo}}
    <i class="{{menuService.RetornarDestaqueGerenciador('ver_noticias').CssClass}}"></i>
  </button>
</div>