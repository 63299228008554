import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class HistoricoService extends MainService {

  constructor(private http: HttpClient) { super(); }

  add(historico: any) {
    const url = `${super.getUrl()}historico/add`;
    return this.http.post<any[]>(url, historico, this.getOptions());
  }

  list(filter: any) {
    const url = `${super.getUrl()}historico/get`;
    return this.http.post<any[]>(url, filter, this.getOptions());
  }
}
