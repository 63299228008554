import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { AvisoDeslogadoComponent } from 'src/app/aviso-deslogado/aviso-deslogado.component';
import { InteresseComponent } from 'src/app/pessoa-externo/interesse/interesse.component';
import { AuthService } from 'src/app/service/auth.service';
import { AvaliarService } from 'src/app/service/avaliar.service';
import { FavoritoService } from 'src/app/service/favorito.service';
import { PessoaService } from 'src/app/service/pessoa.service';
import { ServicoService } from 'src/app/service/servico.service';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-servico-externo',
  templateUrl: './servico-externo.component.html',
  styleUrls: ['./servico-externo.component.css'],
})
export class ServicoExternoComponent implements OnInit {
  subcategoria: string | undefined;
  categoria: string | undefined;
  servicos: any = [];
  estaLogado = false;
  catId = '';
  subId = '';
  tp = '';
  titulo = '';
  paginaAtual = 1;
  qtdPaginas: any;
  qtdItensPorPagina = 12;
  search = '';
  resultados = 0;
  exibeFavoritos = false;

  pessoas: any = [];
  paginaAtualPes = 1;
  qtdPaginasPes: any;
  // qtdItensPorPaginaPes = 12;
  resultadosPessoas = 0;

  filtroTipoList = [
    { key: 1, value: 'Menor Preço' },
    { key: 2, value: 'Maior Preço' },
    { key: 3, value: 'Mais Relevantes' },
    { key: 4, value: 'Menos Relevantes' },
  ];

  filtroTipo = 1;

  filter = {
    offset: 0,
    limit: 0,
    id_categoria: '',
    id_subcategoria: '',
    tipo: '',
    search: '',
    nome_fantasia: '',
    order: '',
    direction: '',
  };

  constructor(
    private authService: AuthService,
    private servicoService: ServicoService,
    private route: ActivatedRoute,
    private router: Router,
    private favoritoService: FavoritoService,
    private pessoaService: PessoaService,
    private modalService: NgbModal,
    private avaliarService: AvaliarService,
    public menuService: MenuService
  ) {}

  ngOnInit(): void {
    window.scroll({ top: 0, left: 0 });
    this.estaLogado = this.authService.isLoggedIn();

    this.catId = this.route.snapshot.queryParamMap.get('catId') || '';

    this.subId = this.route.snapshot.queryParamMap.get('subId') || '';

    this.tp = this.route.snapshot.queryParamMap.get('tp') || '';

    this.search = this.route.snapshot.queryParamMap.get('search') || '';

    this.subcategoria =
      this.route.snapshot.queryParamMap.get('subTitulo') || '';

    this.categoria = this.route.snapshot.queryParamMap.get('catTitulo') || '';

    this.titulo = this.route.snapshot.queryParamMap.get('titulo') || '';

    // volta para home se vier sem nenhum filtro
    if (!this.subId && !this.catId && !this.tp && !this.search) {
      this.router.navigate(['home']);
    }

    this.getList(1);

    if (this.estaLogado) {
      this.exibeFavoritos = this.authService.getRole() == 'Pessoa';
      if (this.exibeFavoritos) {
        this.getFavoritosUsuario();
      }
    }
  }

  addOrder() {
    this.filter.order = 'valor';
    this.filter.direction = 'ASC';

    if (this.filtroTipo == 2) {
      this.filter.order = 'valor';
      this.filter.direction = 'DESC';
    }
    if (this.filtroTipo == 3) {
      this.filter.order = 'relevancia';
      this.filter.direction = 'DESC';
    }
    if (this.filtroTipo == 4) {
      this.filter.order = 'relevancia';
      this.filter.direction = 'ASC';
    }
  }

  getList(pagina: number) {
    this.paginaAtual = pagina;

    this.filter.offset = (pagina - 1) * this.qtdItensPorPagina;
    this.filter.limit = this.qtdItensPorPagina;

    if (this.catId) {
      this.filter.id_categoria = this.catId;
    }

    if (this.subId) {
      this.filter.id_subcategoria = this.subId;
    }

    if (this.tp) {
      this.filter.tipo = this.tp;
    }

    if (this.search) {
      this.filter.search = this.search;
      this.filter.nome_fantasia = this.search;
    }

    this.addOrder();

    this.servicoService.search(this.filter).subscribe((next) => {
      next.rows = next.rows.map((pes: any) => {
        //pes.templateFoto = pes.foto ? `${this.servicoService.getUrl()}images/${pes.foto}` : "assets/images/user.svg";

        let fotoCat;
        if (pes.Subcategorium.Categorium.nome == 'Afroempreendedores') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-afroempreendedores.svg';
        } else if (pes.Subcategorium.Categorium.nome == 'Baiana') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-baiana.svg';
        } else if (pes.Subcategorium.Categorium.nome == 'Capoeira') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-capoeira.svg';
        } else if (pes.Subcategorium.Categorium.nome == 'Arte & Cultura') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-cultura.svg';
        } else if (pes.Subcategorium.Categorium.nome == 'Flora') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-flora.svg';
        } else if (pes.Subcategorium.Categorium.nome == 'Gastronomia') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-gastronomia.svg';
        } else if (pes.Subcategorium.Categorium.nome == 'Moda & Beleza') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-moda_beleza.svg';
        } else if (pes.Subcategorium.Categorium.nome == 'Religião') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-religiao.svg';
        } else if (pes.Subcategorium.Categorium.nome == 'Turismo') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-turismo.svg';
        } else {
          fotoCat = 'assets/images/user.svg';
        }

        pes.templateFoto = pes.foto || fotoCat;
        pes.foto = pes.templateFoto;
        pes.youtube = pes.youtube
          ? `https://www.youtube.com/embed/${pes.youtube}`
          : '';
        pes.favorito = false;
        //pes.foto_pessoa = pes.Pessoa.foto ? `${this.servicoService.getUrl()}images/${pes.Pessoa.foto}` : "assets/images/user.svg";
        pes.foto_pessoa = pes.Pessoa.foto || 'assets/images/user.svg';

        return pes;
      });

      this.servicos = next;
      const qtdTotalPaginas = Math.ceil(
        this.servicos.count / this.qtdItensPorPagina
      );
      this.servicos = { ...this.servicos, qtdTotalPaginas };
      this.qtdPaginas = [];
      this.qtdPaginas.push(pagina);
      let lastcount = 0;
      while (
        this.qtdPaginas.length != lastcount &&
        this.qtdPaginas.length < 5
      ) {
        lastcount = this.qtdPaginas.length;
        this.qtdPaginas.unshift(this.qtdPaginas[0] - 1);
        this.qtdPaginas.push(this.qtdPaginas[this.qtdPaginas.length - 1] + 1);
        this.qtdPaginas = this.qtdPaginas.filter(function (el: any) {
          return el > 0;
        });
        this.qtdPaginas = this.qtdPaginas.filter(function (el: any) {
          return el <= qtdTotalPaginas;
        });
      }

      this.resultados = next.count;
    });

    this.pessoaService.getPessoas(this.filter).subscribe((next) => {
      next.rows = next.rows.map((pes: any) => {
        pes.foto = pes.foto || 'assets/images/user.svg';
        pes.favorito = false;
        return pes;
      });

      this.pessoas = next;
      const qtdTotalPaginasPes = Math.ceil(
        this.pessoas.count / this.qtdItensPorPagina
      );
      this.pessoas = { ...this.pessoas, qtdTotalPaginasPes };
      this.qtdPaginasPes = [];
      this.qtdPaginasPes.push(pagina);
      let lastCountPes = 0;
      while (
        this.qtdPaginasPes.length != lastCountPes &&
        this.qtdPaginasPes.length < 5
      ) {
        lastCountPes = this.qtdPaginasPes.length;
        this.qtdPaginasPes.unshift(this.qtdPaginas[0] - 1);
        this.qtdPaginasPes.push(
          this.qtdPaginasPes[this.qtdPaginasPes.length - 1] + 1
        );
        this.qtdPaginasPes = this.qtdPaginasPes.filter(function (el: any) {
          return el > 0;
        });
        this.qtdPaginasPes = this.qtdPaginasPes.filter(function (el: any) {
          return el <= qtdTotalPaginasPes;
        });
      }

      this.resultadosPessoas = next.count;
    });
  }

  acessarPerfilPessoa(pessoa: any) {
    this.router.navigate(['detalhe-pessoa'], {
      queryParams: { pes: pessoa.id },
    });
  }

  clickInteresse(servico: any, event: any) {
    forkJoin(
      this.pessoaService.getPessoa(servico.id_pessoa),
      this.avaliarService.getMedia({ id_pessoa: servico.id_pessoa })
    ).subscribe((res) => {
      const modalRef = this.modalService.open(InteresseComponent);
      modalRef.componentInstance.servicoModal = servico;
      let pessoa = res[0];
      //pessoa.templateFoto = pessoa.foto ? `${this.pessoaService.getUrl()}images/${pessoa.foto}` : "assets/images/produto.svg"
      pessoa.templateFoto = pessoa.foto || 'assets/images/produto.svg';
      modalRef.componentInstance.pessoa = pessoa;
      modalRef.componentInstance.id_pessoa = servico.id_pessoa;
      modalRef.componentInstance.avaliacaoMedia = res[1][0];
    });
  }

  irParaPessoa(idPessoa: any) {
    this.router.navigate(['detalhe-pessoa'], {
      queryParams: { pes: idPessoa },
    });
  }

  AddFavoritePessoa(id: any, event: any) {
    event.preventDefault();
    if (this.authService.isLoggedIn()) {
      this.pessoas.rows.map((pes: any) => {
        if (pes.id == id) {
          pes.favorito = !pes.favorito;
          if (pes.favorito) {
            this.favoritoService
              .add({
                id_usuario: this.authService.getUser().id,
                id_pessoa: id,
              })
              .subscribe();
          } else {
            this.favoritoService
              .delete({
                id_usuario: this.authService.getUser().id,
                id_pessoa: id,
              })
              .subscribe();
          }
        }
      });
    } else {
      this.clickAvisoDeslogado();
    }
  }

  AddFavorite(id: any, event: any) {
    event.preventDefault();
    if (this.authService.isLoggedIn()) {
      this.servicos.rows.map((ser: any) => {
        if (ser.id == id) {
          ser.favorito = !ser.favorito;
          if (ser.favorito) {
            this.favoritoService
              .add({
                id_usuario: this.authService.getUser().id,
                id_servico: id,
              })
              .subscribe();
          } else {
            this.favoritoService
              .delete({
                id_usuario: this.authService.getUser().id,
                id_servico: id,
              })
              .subscribe();
          }
        }
      });
    } else {
      this.clickAvisoDeslogado();
    }
  }

  clickAvisoDeslogado() {
    this.modalService.open(AvisoDeslogadoComponent);
  }

  getFavoritosUsuario() {
    let filter = {
      id_usuario: this.authService.getUser().id,
    };

    this.favoritoService.list(filter).subscribe((next) => {
      if (this.servicos.rows) {
        this.servicos.rows.map((destaque: any) => {
          next.map((favorito: any) => {
            if (favorito.id_servico == destaque.id) {
              destaque.favorito = true;
            }
          });
        });
      }

      if (this.pessoas.rows) {
        this.pessoas.rows.map((destaque: any) => {
          next.map((favorito: any) => {
            if (favorito.id_pessoa == destaque.id) {
              destaque.favorito = true;
            }
          });
        });
      }
    });
  }

  // getTitulo() {
  //   if (this.catId) {
  //     this.servicoService.getCategoriaCompleta({ id: this.catId }).subscribe(next => this.categoria = next.nome)
  //   }
  //   if (this.subId) {
  //     this.servicoService.getSubcategoriaCompleta({ id: this.subId }).subscribe(next => {
  //       this.subcategoria = next.nome
  //       this.categoria = next.Categorium.nome
  //     })
  //   }
  //   if (this.tp) {
  //     if (this.tp == "S") {
  //       this.titulo = 'Serviços';
  //     }
  //     if (this.tp == "P") {
  //       this.titulo = 'Produtos';
  //     }
  //   }
  // }
}
