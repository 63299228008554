import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PessoaExternoListaComponent } from './pessoa-externo-lista/pessoa-externo-lista.component';
import { PessoaExternoDetalheComponent } from './pessoa-externo-detalhe/pessoa-externo-detalhe.component';
import { PessoaExternoRoutingModule } from './pessoa-externo-routing.module';
import { NgxMaskModule } from 'ngx-mask';
import { SafePipe } from '../helper/safe-pipe';
import { PessoaExternoPesquisaComponent } from './pessoa-externo-pesquisa/pessoa-externo-pesquisa.component';
import { FormsModule } from '@angular/forms';
import { InteresseComponent } from './interesse/interesse.component';
import { AvaliacaoComponent } from './avaliacao/avaliacao.component';
import { AvaliacaoEstrelaModule } from '../avaliacao-estrela/avaliacao-estrela.module';
import { ComentarioComponent }from './comentario/comentario.component';
import { DetalheComponent } from './detalhe/detalhe.component';

@NgModule({
  declarations: [PessoaExternoListaComponent, PessoaExternoDetalheComponent, SafePipe, PessoaExternoPesquisaComponent,
    InteresseComponent,
    AvaliacaoComponent,
    ComentarioComponent,
    DetalheComponent
    ],
  imports: [
    CommonModule,
    PessoaExternoRoutingModule,
    NgxMaskModule.forChild(),
    FormsModule,
    AvaliacaoEstrelaModule
  ],
  exports: [
    FormsModule
  ]
})
export class PessoaExternoModule { }
