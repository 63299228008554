import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

declare var RDStationForms: any;

@Component({
  selector: 'app-modal-newsletter',
  templateUrl: './modal-newsletter.component.html',
  styleUrls: ['./modal-newsletter.component.css']
})
export class ModalNewsletterComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    new RDStationForms('newsletter-afrobiz-f563abe16f2226d0637f', 'UA-200179375-1').createForm()
  }

  closeModal(): void {
    this.activeModal.close();
  }

}
