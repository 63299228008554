<div class="modal-content">
    <div class="modal-header">
        <div class="hd-md">
            <h3>{{pessoa.nome}}</h3>
            <small>{{pessoa.qtdProduto}} Produtos</small>
            <app-avaliacao-estrela pesId={{pessoa.id}} complemento="comentário(s)"></app-avaliacao-estrela>
           
        </div>
        <button type="button" class="btn-close close" (click)="activeModal.dismiss('Cross click')">
            <i class="fas fa-times"></i>
        </button>
    </div>

    <div class="modal-body">
        <div class="d-flex flex-wrap justify-content-between mb-5">

            <button type="button" class="btn btn-padrao btn-sm " (click)="avaliarClick()">
                <i class="far fa-comment-alt"></i>
                fazer comentário
            </button>
            <div class="aumentar-fonte">
                <a [href]="" class="btn" (click)="sizeUpFont()" style="cursor: pointer;">
                    A
                    <i class="fas fa-plus"></i>
                </a>
                <a [href]="" class="btn" (click)="sizeDownFont()" style="cursor: pointer;">
                    A
                    <i class="fas fa-minus"></i>
                </a>
            </div>
        </div>

        <ul class="lista-comentarios list">
            <!-- =============ITEM LISTA================= -->
            <li *ngFor="let item of list">
                <div class="bloco-user">
                    <span class="user maior">
                        <img src="{{item.templateFoto}}" alt="">
                    </span>
                    <span class="texto">

                        <strong>{{item.Usuario.nome}}</strong>
                        <div class="rank">
                            <span class="star {{item.rating > 0 ? 'active' : ''}}">
                                <i class="fas fa-star"></i>
                            </span>
                            <span class="star {{item.rating > 1 ? 'active' : ''}}">
                                <i class="fas fa-star"></i>
                            </span>
                            <span class="star {{item.rating > 2 ? 'active' : ''}}">
                                <i class="fas fa-star"></i>
                            </span>
                            <span class="star {{item.rating > 3 ? 'active' : ''}}">
                                <i class="fas fa-star"></i>
                            </span>
                            <span class="star {{item.rating > 4 ? 'active' : ''}}">
                                <i class="fas fa-star"></i>
                            </span>
                            <small>{{item.qtdDias}} dia{{item.qtdDias > 1 ? 's': ''}} atrás</small>
                        </div>
                    </span>
                </div>
                <p [class]="acessibilidade">
                    {{item.comentario}}
                </p>
            </li>
            <!-- =============FIM ITEM LISTA================= -->
        </ul>
    </div>
</div>
