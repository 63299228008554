import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PessoaService } from 'src/app/service/pessoa.service';
import { AuthService } from 'src/app/service/auth.service';
import * as L from 'leaflet';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css'],
})
export class MapaComponent implements OnInit, AfterViewInit {
  private map: any;

  markers: any = [];

  placeHolder: any;

  entidadesCordinates: any = [];

  pessoas: any = [];

  resultados: any = -1;

  public term: any;

  pt: any = true;

  pinEtity: any = L.icon({
    iconUrl: '../../../assets/images/pin-mapa.png',
    iconSize: [38, 48],
  });

  private initMap(): any {
    this.map = L.map('map', {
      center: [-12.9229, -38.5332],
      zoom: 12,
    });

    const tiles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 19,
        minZoom: 3,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    tiles.addTo(this.map);
  }

  constructor(
    private authService: AuthService,
    public activeModal: NgbActiveModal,
    private router: Router,
    private pessoaService: PessoaService
  ) {}

  ngOnInit(): void {
    if (this.authService.getLanguage() === 'us-en') {
      this.pt = false;
    }
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  filter(event: any) {
    if (event.key && event.key === 'Enter') {
      this.search();
    } else {
      if (event.pointerType === 'mouse') {
        this.search();
      }
    }
  }

  search() {
    this.entidadesCordinates = [];
    this.pessoas = [];

    this.markers.map((marker: any) => {
      this.map.removeLayer(marker);
    });
    this.markers = [];

    this.pessoaService.getPessoaEServicos(this.term).subscribe((res: any) => {
      this.resultados = res.count;
      res.rows.map((element: any) => {
        if (element.tipo) {
          const pessoa = {
            id: element.Pessoa.id,
            foto:
              element.Pessoa.foto != null
                ? element.Pessoa.foto
                : 'assets/images/produto.svg',
            nome: element.Pessoa.nome,
            nome_fantasia: element.Pessoa.nome_fantasia,
            endereco: `${element.bairro}, ${element.Cidade.nome}`,
          };

          this.pessoas.push(pessoa);

          const marker = L.marker([element.latitude, element.longitude], {
            icon: this.pinEtity,
          })
            .on('click', () => {
              this.irParaPessoa(pessoa.id);
            })
            .addTo(this.map)
            .bindTooltip(pessoa.nome_fantasia);

          this.markers.push(marker);
        } else {
          const pessoa = {
            id: element.id,
            foto:
              element.foto != null ? element.foto : 'assets/images/produto.svg',
            nome: element.nome,
            nome_fantasia: element.nome_fantasia,
            endereco: `${element.bairro}, ${element.Cidade.nome}`,
          };

          this.pessoas.push(pessoa);

          const marker = L.marker([element.latitude, element.longitude], {
            icon: this.pinEtity,
          })
            .on('click', () => {
              this.irParaPessoa(pessoa.id);
            })
            .addTo(this.map)
            .bindTooltip(pessoa.nome_fantasia);

          this.markers.push(marker);
        }
      });
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  irParaPessoa(idPessoa: any) {
    this.closeModal();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['detalhe-pessoa'], {
      queryParams: { pes: idPessoa },
    });
  }

  inputSearchKey(e: any) {
    if (this.term && e.code.toLowerCase() == 'enter') {
      this.search();
    }
  }
}
