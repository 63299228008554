import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GerenciadorConteudoService } from 'src/app/service/gerenciador-conteudo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalEventoDetalheComponent } from 'src/app/evento/modal-evento-detalhe/modal-evento-detalhe.component';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from 'src/app/service/auth.service';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-evento-lista',
  templateUrl: './evento-lista.component.html',
  styleUrls: ['./evento-lista.component.css']
})
export class EventoListaComponent implements OnInit {

  eventos: any;
  paginaAtual: number;
  qtdPaginas: any;
  modalRef: any;
  carregandoListaEventos: boolean;
  eventosEmpty: boolean;
  language: any;

  constructor(
    private gerenciadorConteudo: GerenciadorConteudoService,
    private router: Router,
    private modalService: NgbModal,
    private sanitize: DomSanitizer,
    private spinnerEventoLista: NgxSpinnerService,
    private authService: AuthService,
    public menuService: MenuService
  ) {
    this.paginaAtual = 1;
    this.eventos = { rows: [], qtdTotalPaginas: 0 };
    this.carregandoListaEventos = true;
    this.eventosEmpty = false;
   }

  ngOnInit(): void {
    this.language = this.authService.getLanguage();
    this.gerenciadorConteudo.evento = '';
    this.getEventsList(this.paginaAtual);
  }

  getEventsList(pagina: number) {
    this.eventos = { rows: [], qtdTotalPaginas: 0 };
    window.scroll({top: 0, left: 0});
    this.carregandoListaEventos = true;
    this.spinnerEventoLista.show('sp4');
    this.paginaAtual = pagina;
    this.gerenciadorConteudo.getEventsList(this.paginaAtual, this.authService.getLanguage()).subscribe((result: any) => {
      if (result.error) {
        console.log('eventos-lista ', result.error);
        this.eventos = { rows: [], qtdTotalPaginas: 0 };
        this.eventosEmpty = true;
        this.spinnerEventoLista.hide('sp4');
        return;
      }
      if (result.list.length == 0) {
        this.eventosEmpty = true;
        this.spinnerEventoLista.hide('sp4');
        return;
      }
      this.eventos = { rows: result.list, qtdTotalPaginas: result.pages.length };
      this.qtdPaginas = result.pages;
      this.carregandoListaEventos = false;
      this.spinnerEventoLista.hide('sp4');
    });
  }

  redirectToDetail(evento: any) {
    this.gerenciadorConteudo.evento = evento;
    this.router.navigate(["detalhe-evento"]);
  }

  open(selectedEvent: any) {
    this.modalRef = this.modalService.open(ModalEventoDetalheComponent, {
      windowClass: 'modal-dialog-centered modal-eventos',
      backdrop: 'static',
    });
    this.modalRef.componentInstance.evento = selectedEvent;
    this.modalRef.componentInstance.conteudo = this.sanitize.bypassSecurityTrustHtml(selectedEvent.Conteudo);;
    this.modalRef.componentInstance.modalRef = this.modalRef;
    this.modalRef.componentInstance.language = this.authService.getLanguage();
  }
}
