import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { PessoaService } from 'src/app/service/pessoa.service';
import { RodadaNegocioService } from 'src/app/service/rodada-negocio.service';

@Component({
  selector: 'app-banner-rodada',
  templateUrl: './banner-rodada.component.html',
  styleUrls: ['./banner-rodada.component.css']
})
export class BannerRodadaComponent implements OnInit {

  exibePainelRodada = false
  rodadaNegocio = {
    imagem_banner: "assets/images/banner-rodadas.jpg",
    id: ''
  }

  constructor(
    private rodadaNegocioService: RodadaNegocioService,
    private router: Router,
    private pessoaService: PessoaService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    if (this.authService.getUser()?.nome_perfil == 'Pessoa') {
      this.rodadaNegocioService.disponivel().subscribe(next => {
        if (next) {
          this.rodadaNegocio = next
          this.exibePainelRodada = true
        }
      })
    }
  }

  irParaCadastro() {
    this.pessoaService.pessoa = { interesseRodada: true, id_rodada: this.rodadaNegocio.id }
    this.router.navigate(['pessoa'])
  }

}
