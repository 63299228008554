<main>
  <!-- ======================conteudo===================== -->
  <div class="conteudo">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item redirect" routerLink="/home">Página Inicial</li>
        <li class="breadcrumb-item active" aria-current="page">Pessoas</li>
      </ol>
    </nav>
    <div class="title-header">
      <div class="card-pessoa">
        <div class="bloco-user">
          <span class="user maior">
            <img src="{{pessoa.templateFoto}}" alt="">
          </span>
          <span class="texto">
            <h2>
              {{pessoa.nome_fantasia}}
              <a (click)="detalheClick()" style="cursor: pointer;">
                <i class="fas fa-info-circle"></i>
              </a>
            </h2>
            <div class="rank">
              <app-avaliacao-estrela pesId={{pesId}} (click)="comentarioClick()">
              </app-avaliacao-estrela>

              <a class="btn btn-transparent" (click)="avaliarClick()" style="cursor: pointer;">
                <i class="far fa-comment-alt"></i>
                Comentar
              </a>
            </div>
            <small>{{qtdProdutos}} Produtos | {{pessoa.bairro}}, Salvador</small>
          </span>
        </div>
      </div>
      <!-- <div class="social">
                <a href="">
                    <i class="fas fa-phone"></i>
                </a>
                <a href="">
                    <i class="fab fa-whatsapp"></i>
                </a>
                <a href="">
                    <i class="fas fa-envelope"></i>
                </a>
                <button type="submit" class="btn btn-padrao btn-sm">
                    <i class="fab fa-whatsapp"></i>
                    <span>Fale por WhatsApp</span>
                </button>
            </div> -->
      <div class="social">
        <a href="tel:+55{{pessoa.telefone}}" *ngIf="pessoa.telefone" style="cursor: pointer;">
          <i class="fas fa-phone"></i>
        </a>
        <a [href]="pessoa.facebook" *ngIf="pessoa.facebook" target="_blank" style="cursor: pointer;">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="mailto:{{pessoa.email}}" target="_blank" style="cursor: pointer;">
          <i class="fas fa-envelope"></i>
        </a>
        <a href="{{pessoa.instagram}}" target="_blank" *ngIf="pessoa.instagram" style="cursor: pointer;">
          <i class="fab fa-instagram"></i>
        </a>
        <a [href]="pessoa.website" *ngIf="pessoa.website" target="_blank" style="cursor: pointer;">
          <i class="fas fa-globe"></i>
        </a>
        <button type="button" id="Tag_FaleWhatsApp" class="btn btn-padrao btn-sm" (click)="clickWhatsapp(pessoa.celular)">
          <i class="fab fa-whatsapp"></i>
          <span>Fale por WhatsApp</span>
        </button>
      </div>
    </div>
    <div class="title-header" *ngIf="!listaDividida">
      <h3>Produtos/serviços</h3>
      <div>
        <select class="form-control" [(ngModel)]="filtroTipo" (ngModelChange)="getFiltro()">
          <option [value]="0">Menor preço</option>
          <option [value]="1">Maior preço</option>
        </select>
      </div>
    </div>

    <div class="title-header" *ngIf="listaDividida">
      <h3>{{nomeCategoria}}</h3>
    </div>
    <ul class="listagem" *ngIf="listaDividida && servicosSelecionados.length > 0">

      <div class="d-flex justify-content-center" *ngIf="servicos.rows <= 0">
        <span class="mt-5">Sem registros</span>
      </div>
      <!-- ================ITEM CARD===========   -->
      <li class="card" *ngFor="let servico of servicosSelecionados">
        <div class="bloco">
          <div class="like" *ngIf="exibeFavoritos">
            <input type="checkbox" class="custom-control-input" [checked]='servico.favorito' id="{{'chk'+servico.id}}"
              (click)="AddFavorite(servico.id, $event)">
            <label class="custom-control-label" for="{{'chk'+servico.id}}">
              <i class="far fa-heart"></i>
            </label>
          </div>
          <a (click)="clickInteresse(servico)" style="cursor: pointer;">
            <img src="{{servico.templateFoto}}" alt="">
            <div class="card-body">
              <div class="texto">
                <h3>Tenho interesse!</h3>
              </div>
              <div class="info">
                <span>{{servico.valor | currency:'BRL'}}</span>
              </div>
            </div>
          </a>
        </div>
        <div class="card-footer">
          <h5>{{servico.nome}}</h5>
        </div>
      </li>
      <!-- ================FIM ITEM CARD===========   -->
    </ul>


    <!-- =======================PRODUTOS RECOMENDADOS==================== -->
    <div class="area-recomendados">
      <h3 class="mt-5" *ngIf="listaDividida && servicos.result.rows.length > 0">Mais produtos e serviços de {{pessoa.nome_fantasia}}</h3>

      <ul class="listagem recomendados">

        <div class="d-flex justify-content-center" *ngIf="servicos.rows <= 0">
          <span class="mt-5">Sem registros</span>
        </div>
        <!-- ================ITEM CARD===========   -->
        <li class="card" *ngFor="let servico of servicos.result.rows">
          <div class="bloco">
            <div class="like" *ngIf="exibeFavoritos">
              <input type="checkbox" class="custom-control-input" [checked]='servico.favorito' id="{{'chk'+servico.id}}"
                (click)="AddFavorite(servico.id, $event)">
              <label class="custom-control-label" for="{{'chk'+servico.id}}">
                <i class="far fa-heart"></i>
              </label>
            </div>
            <a (click)="clickInteresse(servico)" style="cursor: pointer;">
              <img src="{{servico.templateFoto}}" alt="">
              <div class="card-body">
                <div class="texto">
                  <h3>Tenho interesse!</h3>
                </div>
                <div class="info">
                  <span>{{servico.valor | currency:'BRL'}}</span>
                </div>
              </div>
            </a>
          </div>
          <div class="card-footer">
            <h5>{{servico.nome}}</h5>
          </div>
        </li>
        <!-- ================FIM ITEM CARD===========   -->
      </ul>
      <div class="ver-mais d-flex justify-content-end" *ngIf="listaDividida && servicos.result.rows.length > 5">
        <button type="button" class="btn btn-transparent" (click)="irParaPessoa()">
          ver tudo de {{pessoa.nome_fantasia}}
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
    </div>
    <!-- =======================PRODUTOS RECOMENDADOS==================== -->


    <div class="d-flex justify-content-center" *ngIf="!listaDividida && this.servicos.qtdTotalPaginas > 1">
      <nav>
        <ul class="pagination">
          <li class="page-item {{ paginaAtual == 1 ? 'disabled' : ''}}" *ngIf="servicos.count > 0">
            <a class="page-link" *ngIf="paginaAtual == 0" style="cursor: pointer;"><i
                class="fas fa-chevron-left"></i></a>
            <button class="page-link" (click)="getServicos(paginaAtual - 1)" [disabled]="paginaAtual == 1">
              <i class="fas fa-chevron-left"></i>
              <div *ngIf="qtdPaginas[0] != 1">...</div>
            </button>
          </li>
          <li class="page-item " *ngFor="let opt of qtdPaginas;">
            <a class="page-link active" *ngIf="paginaAtual == opt" style="cursor: pointer;">{{ opt }}</a>
            <button class="page-link" (click)="getServicos(opt)" *ngIf="paginaAtual != opt">
              {{ opt }}
            </button>
          </li>
          <li class="page-item {{ paginaAtual == servicos.qtdTotalPaginas ? 'disabled' : ''}}"
            *ngIf="servicos.length > 0">
            <a class="page-link" *ngIf="paginaAtual == (qtdPaginas-1)" style="cursor: pointer;"><i
                class="fas fa-chevron-right"></i></a>
            <button class="page-link" (click)="getServicos(paginaAtual + 1)"
              [disabled]="paginaAtual == servicos.qtdTotalPaginas">
              <div *ngIf="qtdPaginas[qtdPaginas.length - 1] != servicos.qtdTotalPaginas">...</div>
              <i class="fas fa-chevron-right"></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <!-- =====================FIM =conteudo===================== -->

</main>
