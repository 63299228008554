import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CepService {

  endereco: any;
  constructor(private http: HttpClient) { }

  // {
  //   "cep": "41745-715",
  //   "logradouro": "Rua Mundo",
  //   "complemento": "",
  //   "bairro": "Trobogy",
  //   "localidade": "Salvador",
  //   "uf": "BA",
  //   "ibge": "2927408",
  //   "gia": "",
  //   "ddd": "71",
  //   "siafi": "3849"
  // }

  getEndereco(cep: string): any {
    return this.http.get(`https://viacep.com.br/ws/${cep}/json/`);
  }
}
