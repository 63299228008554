import { Component, OnInit } from '@angular/core';
import { GerenciadorConteudoService } from 'src/app/service/gerenciador-conteudo.service';
import { AuthService } from 'src/app/service/auth.service';
import { MenuService } from 'src/app/service/menu.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RodadaNegocioComponent } from '../rodada-negocio/rodada-negocio.component';
import { RodadaNegocioService } from '../service/rodada-negocio.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private gerenciadorConteudo: GerenciadorConteudoService,
    private authService: AuthService,
    private menuService: MenuService,
    private modalService: NgbModal,
    private rodadaNegocioService: RodadaNegocioService,
  ) { }

  ngOnInit(): void {

    setTimeout(() => {
      if (this.authService.isLoggedIn() && this.authService.getUser().nome_perfil === 'Pessoa') {
        this.rodadaNegocioService.disponivelPessoa(this.authService.getUser().id).subscribe(next => {
          if (next) {
            this.modalService.open(RodadaNegocioComponent)
            //this.rodadaNegocio = next
            //this.exibePainelRodada = true
          }
        })
      }
    }, 1000)

  }
}
