import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PessoaComponent } from './pessoa.component';
import { PessoaRoutingModule } from './pessoa-routing.module';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { NgxMaskModule } from "ngx-mask";

import { ListaServicoModule } from "../lista-servico/lista-servico.module";
import { FieldErrorDisplayModule } from "../field-error-display/field-error-display.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [PessoaComponent],
  imports: [
    CommonModule,
    PessoaRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    ListaServicoModule,
    FieldErrorDisplayModule,
    NgbModule,
    BrowserModule
  ]
})
export class PessoaModule { }
