import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QuantidadeFilhosService extends MainService {

  constructor(private http: HttpClient) {
    super()
  }

  getList() {
    const url = `${super.getUrl()}quantidade-filhos/list`;
    return this.http.post<any[]>(url, {}, this.getOptions())
  }

}
